import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useAxios from '../../hooks/useAxios';
import useForm from '../../hooks/useForm';

import { GET_MEMBERS, GET_GROUPS, EDIT_GROUP, DELETE_GROUP } from '../../Api';

import BackLink from '../../utils/BackLink/BackLink';
import PageTitle from '../../utils/PageTitle/PageTitle';
import Input from '../../components/Forms/Input';
import TextArea from '../../components/Forms/TextArea';
import FileInput from '../../components/Forms/FileInput';
import FormButton from '../../components/Buttons/FormButton';
import RedButton from '../../components/Buttons/RedButton';
import Loader from '../../utils/Loader/Loader';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';
import SuccessNotification from '../../utils/SuccessNotification/SuccessNotification';
import MemberSelect from '../../components/Forms/MemberSelect';
import Menu from '../../components/Menu/Menu';
import WarningModal from '../../utils/WarningModal/WarningModal';

import { ReactComponent as DefaultProfile } from '../../assets/group.svg';

import GlobalStyles from '../../GlobalStyles';
import ChatStyles from './ChatStyles';

const GroupEdit = () => {
  const token = useSelector((state) => state.auth.token);
  const { cim } = useSelector((state) => state.auth.user);
  const { groupId } = useParams();
  const navigate = useNavigate();

  const title = useForm();
  const description = useForm();
  const members = useForm('array');
  const [photo, setPhoto] = useState('');

  const { data, error, loading, execute } = useAxios(EDIT_GROUP);
  const {
    error: errorDelete,
    loading: loadingDelete,
    execute: executeDelete,
  } = useAxios(DELETE_GROUP);

  const {
    data: groupData,
    error: groupError,
    loading: groupLoading,
    execute: fetchGroup,
  } = useAxios(GET_GROUPS);

  const group = groupData?.length > 0 ? groupData[0] : null;
  const user = useSelector((state) => state.auth.user);

  const {
    data: membersData,
    error: membersError,
    loading: membersLoading,
    execute: fetchMembers,
  } = useAxios(GET_MEMBERS);

  useEffect(() => {
    fetchGroup(token, `memberCim=${cim}&_id=${groupId}`);
    fetchMembers(token, ``);
  }, [token, groupId, fetchGroup, fetchMembers]);

  useEffect(() => {
    if (group) {
      title.setValue(group.title || '');
      description.setValue(group.description || '');
      members.setValue(group.members || []);
      setPhoto(group.photo || '');
    }
  }, [group]);

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    setPhoto(base64);
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      if (file) {
        fileReader.readAsDataURL(file);
      }
      fileReader.onload = () => resolve(fileReader.result);
      fileReader.onerror = (error) => reject(error);
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await execute(token, groupId, {
      title: title.value,
      description: description.value,
      members: members.value,
      photo: photo,
    });

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleDelete = () => {
    executeDelete(token, groupId);
    setModalIsOpen(false);
    navigate(`/chat/grupos`);
  };

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page={`/chat/grupo/${groupId}`} />
      <PageTitle>Editar Grupo</PageTitle>
      {(membersLoading || groupLoading) && <Loader />}
      {membersError && <ErrorNotification>{membersError}</ErrorNotification>}
      {groupError && <ErrorNotification>{groupError}</ErrorNotification>}

      {data && (
        <SuccessNotification>Grupo Editado com Sucesso</SuccessNotification>
      )}
      {error && <ErrorNotification>{error}</ErrorNotification>}
      {errorDelete && <ErrorNotification>{errorDelete}</ErrorNotification>}
      <form onSubmit={handleSubmit}>
        {photo ? (
          <img
            className={`${ChatStyles.memberImage}`}
            src={photo || DefaultProfile}
            alt="Foto do Grupo"
          />
        ) : (
          <DefaultProfile className={`${ChatStyles.memberImage}`} />
        )}
        <FileInput
          label="Foto do Grupo"
          name="foto"
          onChange={handleFileUpload}
          accept="image/jpeg, image/png, image/jpg"
        />
        <Input
          type="text"
          label="Título do Grupo"
          name="titulo"
          placeholder="Insira o título do grupo"
          {...title}
        />
        <TextArea
          label="Descrição do Grupo"
          name="descricao"
          placeholder="Insira a descrição do grupo"
          maxLength={160}
          {...description}
        />
        <MemberSelect
          label="Membros"
          name="membros"
          options={membersData || []}
          value={members.value}
          onChange={(newValue) => members.setValue(newValue)}
          required={true}
        />
        <div className={ChatStyles.buttonContainerForm}>
          {' '}
          <FormButton disabled={loading}>
            {loading ? 'Editando...' : 'Editar'}
          </FormButton>
          {(user.cargo === 'Escrivão' || user.cargo === 'Venerável') && (
            <RedButton onClick={handleOpenModal} disabled={loadingDelete}>
              {loadingDelete ? 'Deletando...' : 'Deletar'}
            </RedButton>
          )}
        </div>
      </form>
      {modalIsOpen && (
        <WarningModal
          title="Deletar grupo"
          text="Deseja deletar permanentemente esse grupo?"
          actionName="Deletar"
          modalAction={handleDelete}
          closeModal={handleCloseModal}
        />
      )}
      <Menu />
    </section>
  );
};

export default GroupEdit;
