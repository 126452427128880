import { Route } from 'react-router-dom';

import Permissions from '../views/Permissions/Permissions';
import RoleCreate from '../views/Permissions/RoleCreate';
import RoleSearch from '../views/Permissions/RoleSearch';
import RoleSingle from '../views/Permissions/RoleSingle';
import RoleEdit from '../views/Permissions/RoleEdit';
import MembersRole from '../views/Permissions/MembersRole';
import MemberRoleEdit from '../views/Permissions/MemberRoleEdit';

const PermissionsRoutes = () => {
  return [
    <Route key="permissoes" path="/permissoes" element={<Permissions />} />,
    <Route
      key="criar-cargo"
      path="/permissoes/criar-cargo"
      element={<RoleCreate />}
    />,
    <Route
      key="consultar-cargo"
      path="/permissoes/consultar-cargos"
      element={<RoleSearch />}
    />,
    <Route key="cargo" path="/cargo/:roleId" element={<RoleSingle />} />,
    <Route
      key="editar-cargo"
      path="/cargo/editar/:roleId"
      element={<RoleEdit />}
    />,
    <Route
      key="cargos-membros"
      path="/permissoes/consultar-cargos/membros"
      element={<MembersRole />}
    />,
    <Route
      key="cargo-membro-editar"
      path="/permissoes/cargo/:cim/editar"
      element={<MemberRoleEdit />}
    />,
  ];
};

export default PermissionsRoutes;
