import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useAxios from '../../hooks/useAxios';
import useForm from '../../hooks/useForm';
import { GET_VOTINGS, INSERT_VOTING } from '../../Api';
import BackLink from '../../utils/BackLink/BackLink';
import PageTitle from '../../utils/PageTitle/PageTitle';
import Loader from '../../utils/Loader/Loader';
import SelectInput from '../../components/Forms/SelectInput';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';
import FormButton from '../../components/Buttons/FormButton';
import Menu from '../../components/Menu/Menu';
import SuccessNotification from '../../utils/SuccessNotification/SuccessNotification';
import { ReactComponent as VotingIcon } from '../../assets/votes-dark.svg';
import GlobalStyles from '../../GlobalStyles';
import VotingStyles from './VotingStyles';

const Vote = () => {
  const { votingId } = useParams();
  const token = useSelector((state) => state.auth.token);
  const { cim } = useSelector((state) => state.auth.user);
  const vote = useForm();

  const {
    data: dataGet,
    error: errorGet,
    loading: loadingGet,
    execute: executeGet,
  } = useAxios(GET_VOTINGS);

  const {
    data: dataVote,
    error: errorVote,
    loading: loadingVote,
    execute: executeVote,
  } = useAxios(INSERT_VOTING);

  useEffect(() => {
    executeGet(token, `_id=${votingId}`);
  }, [token, votingId, executeGet]);

  const handleSubmitVote = (event) => {
    event.preventDefault();
    executeVote(token, votingId, { cim: cim, voteData: vote.value });
  };

  const votingData = dataGet?.length > 0 ? dataGet[0] : null;
  const hasVoted = votingData?.membrosVotantes.includes(cim);

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page={`/votacao/consultar-votacao/${votingId}`} />
      <PageTitle>Votar</PageTitle>
      {loadingGet && <Loader />}
      {errorGet && <ErrorNotification>{errorGet}</ErrorNotification>}
      {votingData && (
        <>
          <div className={VotingStyles.detailsContainer}>
            <h2 className={VotingStyles.title}>
              Título:{' '}
              <span className={VotingStyles.titleNormal}>
                {votingData.titulo}
              </span>
            </h2>
            <h2 className={VotingStyles.descriptionTitle}>
              Descrição da Votação:
            </h2>
            <p className={VotingStyles.description}>{votingData.descricao}</p>
          </div>
          {hasVoted ? (
            <h2 className={VotingStyles.votedMessage}>
              <VotingIcon className={VotingStyles.votingIcon} /> Você Já Votou
              Nessa Pauta
            </h2>
          ) : (
            <form onSubmit={handleSubmitVote}>
              <h2 className={VotingStyles.voteFormTitle}>
                Defina seu voto nessa pauta:
              </h2>
              <SelectInput
                name="voto"
                label="Voto"
                options={['Sim', 'Não', 'Abster-se']}
                required
                {...vote}
              />
              <FormButton disabled={loadingVote}>
                {loadingVote ? 'Votando...' : 'Votar'}
              </FormButton>
            </form>
          )}
          {errorVote && <ErrorNotification>{errorVote}</ErrorNotification>}
          {dataVote && (
            <SuccessNotification>{dataVote.message}</SuccessNotification>
          )}
        </>
      )}
      <Menu />
    </section>
  );
};

export default Vote;
