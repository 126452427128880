import ErrorNotificationStyles from './ErrorNotificationStyles';

const ErrorNotification = ({ children }) => {
  return (
    <div className={ErrorNotificationStyles.wrapper}>
      <p className={ErrorNotificationStyles.text}>
        <span className={ErrorNotificationStyles.icon}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="10" cy="10" r="10" fill="#8B0000" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6 6L14 14M14 6L6 14"
              stroke="white"
              strokeWidth="2"
            />
          </svg>
        </span>
        {children}
      </p>
    </div>
  );
};

export default ErrorNotification;
