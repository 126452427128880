const RecordsBookStyles = {
  buttonsGrid: 'grid grid-cols-1 gap-5 pb-10',
  subTitle: 'text-lg mb-5 font-bold',

  cellContainer: 'w-72 flex flex-row gap-2 flex-wrap mt-3',
  cell: 'inline-block w-9 h-9 text-center rounded bg-blue-500 cursor-pointer text-white',
  dayText: 'text-sm mb-[-7px]',
  weekDayText: 'text-xs m-0',
  selected: 'relative',
  checkedIcon: 'absolute bottom-0 right-0',

  detailsContainer: 'w-72',
  title: 'text-xl font-bold mb-3',
  titleNormal: 'font-normal text-gray-600',
  descriptionTitle: 'text-lg font-bold',
  description: 'mb-5',
  listItem: 'mb-4',
  listTitle: 'flex flex-row gap-1 items-center text-xl font-bold',
  listIcon: 'w-5 h-5',
  listText: 'text-lg text-gray-600',
  buttonContainer: 'flex flex-row justify-between gap-5',
  votedMessage: 'flex flex-row gap-1 items-center text-xl font-bold',
  votingIcon: 'w-5 h-5',
  voteFormTitle: 'text-xl font-bold mb-3',
  tagContainer: 'w-72 flex flex-row gap-2 flex-wrap mt-3',
  tag: 'flex flex-row items-center  justify-center px-2 py-1 bg-blue-500 text-white rounded-full',

  listContainer: 'flex flex-row justify-center gap-28',
  listItemCenter: 'text-center',
  listHeader: 'font-bold mb-3',
  voteCountGreen: 'mb-2 font-bold text-green-500',
  voteCountRed: 'mb-2 font-bold text-red-500',

  headerContainer: 'flex flex-row items-center text-center gap-3',
  headerTitle: 'text-2xl font-bold mb-2',
  editIcon: 'cursor-pointer mb-3 display-block w-4',

  status:
    'text-lg flex flex-row items-center gap-2 mb-8 underline hover:text-blue-500',
};

export default RecordsBookStyles;
