import { Routes, Route } from 'react-router-dom';

import ProtectedRoute from './utils/ProtectedRouter/ProtectedRouter';

import NotFound from './views/NotFound/NotFound';
import AuthRoutes from './routes/AuthRoutes';
import HomeRoutes from './routes/HomeRoutes';
import MembersRoutes from './routes/MembersRoutes';
import CalendarRoutes from './routes/CalendarRoutes';
import RecordsBookRoutes from './routes/RecordsBookRoutes';
import VotingRoutes from './routes/VotingRoutes';
import PresencesRoutes from './routes/PresencesRoutes';
import FamilyRoutes from './routes/FamilyRoutes';
import FinancesRoutes from './routes/FinancesRoutes';
import PermissionsRoutes from './routes/PermissionsRoutes';
import ChatRoutes from './routes/ChatRoutes';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      {AuthRoutes()}
      <Route element={<ProtectedRoute />}>
        {HomeRoutes()}, {MembersRoutes()}, {CalendarRoutes()},
        {RecordsBookRoutes()}, {VotingRoutes()}, {PresencesRoutes()},
        {FamilyRoutes()}, {FinancesRoutes()}, {PermissionsRoutes()},
        {ChatRoutes()}
      </Route>
    </Routes>
  );
};

export default AppRoutes;
