import { Suspense, lazy, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAxios from '../../hooks/useAxios';
import { useSelector } from 'react-redux';
import useForm from '../../hooks/useForm';

import { GET_GROUPS } from '../../Api';

import BackLink from '../../utils/BackLink/BackLink';
import PageTitle from '../../utils/PageTitle/PageTitle';
import Button from '../../components/Buttons/Button';
import SearchInput from '../../components/Forms/SearchInput';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';
import Loader from '../../utils/Loader/Loader';
import Menu from '../../components/Menu/Menu';
import Pagination from '../../utils/Pagination/Pagination';
import WarningNotification from '../../utils/Notification/WarningNotification';

import GlobalStyles from '../../GlobalStyles';
import ChatStyles from './ChatStyles';

const GroupCard = lazy(() => import('../../components/Cards/GroupCard'));

const GroupsList = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const { cim } = useSelector((state) => state.auth.user);

  const searchTerm = useForm();

  const { data, error, execute } = useAxios(GET_GROUPS);

  const [currentPage, setCurrentPage] = useState(1);

  const groupsPerPage = 5;

  const indexOfLastGroup = currentPage * groupsPerPage;
  const indexOfFirstGroup = indexOfLastGroup - groupsPerPage;

  const currentGroups = data
    ? data.slice(indexOfFirstGroup, indexOfLastGroup)
    : [];

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    execute(token, `memberCim=${cim}&title=${searchTerm.value}`);
  }, [token, cim, searchTerm.value]);

  const user = useSelector((state) => state.auth.user);

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page="/chat" />
      <PageTitle>Grupos</PageTitle>
      <SearchInput placeholder="buscar grupos" {...searchTerm} />
      <div className={ChatStyles.buttonContainer}>
        <Button onClick={() => navigate('/chat')}>Conversas</Button>
        {(user.cargo === 'Escrivão' || user.cargo === 'Venerável') && (
          <Button onClick={() => navigate('/chat/grupos/criar')}>
            Criar Grupo
          </Button>
        )}
      </div>
      <div className={ChatStyles.membersGrid}>
        {currentGroups.length === 0 && (
          <WarningNotification>Nenhum grupo encontrado</WarningNotification>
        )}
        <Suspense fallback={<Loader />}>
          {!error &&
            currentGroups?.map((group) => (
              <GroupCard
                key={group._id}
                title={group.title}
                photo={group.photo}
                page={`/chat/grupo/${group._id}`}
              />
            ))}
        </Suspense>

        <Pagination
          itemsPerPage={groupsPerPage}
          totalItems={data?.length}
          paginate={paginate}
          currentPage={currentPage}
        />
        {error && <ErrorNotification>{error}</ErrorNotification>}
      </div>
      <Menu />
    </section>
  );
};

export default GroupsList;
