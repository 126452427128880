import { Route } from 'react-router-dom';
import Members from '../views/Members/Members';
import MemberCreate from '../views/Members/MemberCreate';
import MemberSingle from '../views/Members/MemberSingle';
import MemberEdit from '../views/Members/MemberEdit';

const MembersRoutes = () => {
  return [
    <Route key="membros" path="/membros" element={<Members />} />,
    <Route
      key="criar-membro"
      path="/membros/criar-membro"
      element={<MemberCreate />}
    />,
    <Route key="membro" path="membros/:cim" element={<MemberSingle />} />,
    <Route
      key="editar-membro"
      path="membros/editar/:cim"
      element={<MemberEdit />}
    />,
  ];
};

export default MembersRoutes;
