import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import useForm from '../../hooks/useForm';
import moment from 'moment';
import 'moment/locale/pt-br';
import useAxios from '../../hooks/useAxios';

import { GET_EVENTS, CREATE_RECORD } from '../../Api';

import BackLink from '../../utils/BackLink/BackLink';
import PageTitle from '../../utils/PageTitle/PageTitle';
import Loader from '../../utils/Loader/Loader';
import DateInput from '../../components/Forms/DateInput';
import TextArea from '../../components/Forms/TextArea';
import FormButton from '../../components/Buttons/FormButton';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';
import Menu from '../../components/Menu/Menu';
import SuccessNotification from '../../utils/SuccessNotification/SuccessNotification';

import { ReactComponent as CheckIcon } from '../../assets/check.svg';

import GlobalStyles from '../../GlobalStyles';
import RecordsBookStyles from './RecordsBookStyles';

import ReactQuill from 'react-quill';
import useRichText from '../../hooks/useRichText';
import RichTextArea from '../../components/Forms/RichTextArea';

moment.locale('pt-br');

const RecordCreate = () => {
  const token = useSelector((state) => state.auth.token);

  const {
    data: dataGet,
    error: errorGet,
    loading: loadingGet,
    execute: executeGet,
  } = useAxios(GET_EVENTS);

  const {
    data: dataCreate,
    error: errorCreate,
    loading: loadingCreate,
    execute: executeCreate,
  } = useAxios(CREATE_RECORD);

  const month = useForm();
  const description = useForm();
  const startDate = useForm();
  const finalDate = useForm();
  const formattedMonth = month.value ? moment(month.value).format('MMMM') : '';

  useEffect(() => {
    if (month.value) {
      const date = moment(month.value);

      date.add(1, 'months');

      const nextMonth = date.format('YYYY-MM');
      executeGet(token, `de=${month.value}&ate=${nextMonth}`);
    }
  }, [month.value, token]);

  const [selectedEventId, setSelectedEventId] = useState(null);

  const handleEventClick = (eventId) => {
    setSelectedEventId(eventId);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await executeCreate(token, {
      evento: selectedEventId,
      descricao: richText,
      dataInicio: startDate.value,
      dataFinal: finalDate.value,
    });
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const { richText, handleRichTextChange } = useRichText();
  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page="/livro-de-atas" />
      <PageTitle>Criar Ata</PageTitle>
      <DateInput label="Filtrar por Mês" name="data" type="month" {...month} />
      <h2 className="text-md font-bold">{formattedMonth}</h2>
      {dataCreate && (
        <SuccessNotification>{dataCreate.message}</SuccessNotification>
      )}
      {errorCreate && <ErrorNotification>{errorCreate}</ErrorNotification>}
      <div className={RecordsBookStyles.cellContainer}>
        {loadingGet && <Loader />}
        {!errorGet &&
          dataGet?.length > 0 &&
          dataGet.map((event, index) => (
            <span
              key={index}
              className={`${RecordsBookStyles.cell} ${
                event._id === selectedEventId ? RecordsBookStyles.selected : ''
              }`}
              onClick={() => handleEventClick(event._id)}
            >
              <p className={RecordsBookStyles.dayText}>
                {moment(event.data).format('DD')}
              </p>
              <p className={RecordsBookStyles.weekDayText}>
                {moment(event.data).format('ddd')}
              </p>
              {event._id === selectedEventId && (
                <CheckIcon className={RecordsBookStyles.checkedIcon} />
              )}
            </span>
          ))}
        {selectedEventId && (
          <form onSubmit={handleSubmit}>
            <RichTextArea
              label="Descrição"
              name="descricao"
              value={richText}
              onChange={handleRichTextChange}
            />
            <DateInput
              label="Data de Início da Votação da Ata"
              name="dataInicio"
              required
              {...startDate}
            />
            <DateInput
              label="Data de Encerramento da Votação da Ata"
              name="dataFinal"
              required
              {...finalDate}
            />
            <FormButton disabled={loadingCreate}>
              {loadingCreate ? 'Criando...' : 'Criar'}
            </FormButton>
          </form>
        )}
        {errorGet && <ErrorNotification>{errorGet}</ErrorNotification>}
      </div>
      <Menu />
    </section>
  );
};

export default RecordCreate;
