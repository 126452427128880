import { Suspense, lazy, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useAxios from '../../hooks/useAxios';

import { GET_RECORDS } from '../../Api';

import BackLink from '../../utils/BackLink/BackLink';
import Loader from '../../utils/Loader/Loader';
import PageTitle from '../../utils/PageTitle/PageTitle';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';
import Menu from '../../components/Menu/Menu';
import Pagination from '../../utils/Pagination/Pagination';

import GlobalStyles from '../../GlobalStyles';

const RecordVoteCard = lazy(() =>
  import('../../components/Cards/RecordVoteCard'),
);

const RecordVotesDetails = () => {
  const { recordId } = useParams();

  const token = useSelector((state) => state.auth.token);

  const {
    data: data,
    error: error,
    loading: loading,
    execute: execute,
  } = useAxios(GET_RECORDS);

  useEffect(() => {
    execute(token, `_id=${recordId}`);
  }, [token, recordId, execute]);

  const recordData = data?.length > 0 ? data[0].votos : null;

  const [currentPage, setCurrentPage] = useState(1);

  const recordsPerPage = 4;

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  const currentRecords = recordData
    ? recordData.slice(indexOfFirstRecord, indexOfLastRecord)
    : [];

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page={`/livro-de-atas/status/${recordId}`} />
      <PageTitle>Detalhes da Votação</PageTitle>
      {error && <ErrorNotification>{error}</ErrorNotification>}
      {recordData?.length === 0 && (
        <ErrorNotification>Ninguém Votou Ainda</ErrorNotification>
      )}
      <Suspense fallback={<Loader />}>
        {!error &&
          currentRecords?.map((vote, index) => (
            <RecordVoteCard
              key={index}
              cim={vote.membro}
              vote={vote.tipo}
              comment={vote.comentario}
            />
          ))}
      </Suspense>
      <Pagination
        itemsPerPage={recordsPerPage}
        totalItems={recordData?.length}
        paginate={paginate}
        currentPage={currentPage}
      />
      <Menu />
    </section>
  );
};

export default RecordVotesDetails;
