import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useAxios from '../../hooks/useAxios';
import moment from 'moment';

import { GET_MEMBERS } from '../../Api';

import PageTitle from '../../utils/PageTitle/PageTitle';
import BackLink from '../../utils/BackLink/BackLink';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';
import Loader from '../../utils/Loader/Loader';
import Button from '../../components/Buttons/Button';
import Menu from '../../components/Menu/Menu';

import { ReactComponent as DefaultProfile } from '../../assets/user-profile.svg';
import { ReactComponent as PencilIcon } from '../../assets/pencil.svg';
import { ReactComponent as EmailIcon } from '../../assets/mail.svg';
import { ReactComponent as AddressIcon } from '../../assets/address.svg';
import { ReactComponent as BirthdayIcon } from '../../assets/birthday.svg';
import { ReactComponent as CpfIcon } from '../../assets/cpf.svg';
import { ReactComponent as PhoneIcon } from '../../assets/phone.svg';

import GlobalStyles from '../../GlobalStyles';
import MembersStyles from './MembersStyles';

const MemberSingle = () => {
  const token = useSelector((state) => state.auth.token);
  const { data, error, loading, execute } = useAxios(GET_MEMBERS);
  const navigate = useNavigate();

  const { cim } = useParams();
  useEffect(() => {
    execute(token, `cim=${cim}`);
  }, [token, execute, cim]);

  const memberData = data?.length > 0 ? data[0] : null;

  const formatDate = (date) =>
    moment.utc(date).local('pt-br').format('DD/MM/YYYY');

  const user = useSelector((state) => state.auth.user);

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page="/membros" />
      <PageTitle>Membro</PageTitle>
      {loading && <Loader />}
      {error && <ErrorNotification>{error}</ErrorNotification>}
      {memberData && (
        <div>
          <div className={MembersStyles.memberMainInfo}>
            {memberData.foto ? (
              <img
                className={MembersStyles.memberImage}
                src={memberData.foto}
              />
            ) : (
              <DefaultProfile className={MembersStyles.memberImage} />
            )}
            <div>
              <h2 className={MembersStyles.memberInfo}>{memberData.nome}</h2>
              <p className="text-gray-600">CIM (Cadastro): {memberData.cim}</p>
              <p className="text-gray-600">{memberData.grauMaconico}</p>
            </div>
          </div>
          <ul>
            <div className={MembersStyles.headerContainer}>
              <h2 className={MembersStyles.headerTitle}>Informações</h2>
              {(user.cim === parseInt(cim) || user.cargo === 'Venerável') && (
                <PencilIcon
                  className={MembersStyles.editIcon}
                  onClick={() => navigate(`/membros/editar/${cim}`)}
                />
              )}
            </div>
            <li className={MembersStyles.listItem}>
              <p className={MembersStyles.listItemContent}>
                Profissão: {memberData.profissao || 'Não Informado'}
              </p>
            </li>
            {(user.cim === parseInt(cim) || user.cargo === 'Venerável') && (
              <>
                <li className={MembersStyles.listItem}>
                  <h2 className={MembersStyles.listItemTitle}>
                    <EmailIcon className={MembersStyles.icon} /> Email:
                  </h2>
                  <p className={MembersStyles.listItemContent}>
                    {memberData.email}
                  </p>
                </li>
                <li className={MembersStyles.listItem}>
                  <h2 className={MembersStyles.listItemTitle}>
                    <AddressIcon className={MembersStyles.icon} /> Endereço:
                  </h2>
                  <p className={MembersStyles.listItemContent}>
                    {memberData.estado}
                  </p>
                  <p className={MembersStyles.listItemContent}>
                    {memberData.endereco.logradouro}
                  </p>
                  <p className={MembersStyles.listItemContent}>
                    CEP: {memberData.endereco.cep}
                  </p>
                </li>
                <li className={MembersStyles.listItem}>
                  <h2 className={MembersStyles.listItemTitle}>
                    <PhoneIcon className={MembersStyles.icon} /> Telefone:
                  </h2>
                  <p className={MembersStyles.listItemContent}>
                    {memberData.telefone}
                  </p>
                </li>
                <li className={MembersStyles.listItem}>
                  <h2 className={MembersStyles.listItemTitle}>
                    <CpfIcon className={MembersStyles.icon} /> CPF:
                  </h2>
                  <p className={MembersStyles.listItemContent}>
                    {memberData.cpf.replace(
                      /(\d{3})(\d{3})(\d{3})(\d{2})/,
                      '$1.$2.$3-$4',
                    )}
                  </p>
                </li>
              </>
            )}

            <li className={MembersStyles.listItem}>
              <h2 className={MembersStyles.listItemTitle}>
                <BirthdayIcon className={MembersStyles.icon} /> Aniversário:
              </h2>
              <p className={MembersStyles.listItemContent}>
                {formatDate(memberData.dataNascimento)}
              </p>
            </li>
          </ul>
          <div className={MembersStyles.buttonContainer}>
            <Button onClick={() => navigate(`/frequencia/membro/${cim}`)}>
              Frequência
            </Button>
            <Button onClick={() => navigate(`/familiares/${cim}`)}>
              Familiares
            </Button>
          </div>
        </div>
      )}
      <Menu />
    </section>
  );
};

export default MemberSingle;
