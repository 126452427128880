import { useSelector } from 'react-redux';
import useForm from '../../hooks/useForm';
import useAxios from '../../hooks/useAxios';

import { CREATE_VOTING } from '../../Api';

import BackLink from '../../utils/BackLink/BackLink';
import PageTitle from '../../utils/PageTitle/PageTitle';
import Input from '../../components/Forms/Input';
import TextArea from '../../components/Forms/TextArea';
import DateInput from '../../components/Forms/DateInput';
import TagsInput from '../../components/Forms/TagsInput';
import SelectInput from '../../components/Forms/SelectInput';
import MultipleSelectInput from '../../components/Forms/MultipleSelectInput';
import FormButton from '../../components/Buttons/FormButton';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';
import SuccessNotification from '../../utils/SuccessNotification/SuccessNotification';
import Menu from '../../components/Menu/Menu';

import GlobalStyles from '../../GlobalStyles';

const Voting = () => {
  const token = useSelector((state) => state.auth.token);

  const { data, error, loading, execute } = useAxios(CREATE_VOTING);

  const title = useForm();
  const startDate = useForm();
  const finalDate = useForm();
  const masonicDegree = useForm('array');
  const votationType = useForm();
  const tags = useForm('array');
  const description = useForm();

  const options = ['Aprendiz', 'Companheiro', 'Mestre'];

  const handleSubmit = async (event) => {
    event.preventDefault();
    await execute(token, {
      titulo: title.value,
      dataInicio: startDate.value,
      dataFinal: finalDate.value,
      grauMaconico: masonicDegree.value,
      tipoVotacao: votationType.value,
      descricao: description.value,
      tags: tags.value,
    });

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page="/votacao" />
      <PageTitle>Criar Votação</PageTitle>{' '}
      {data && <SuccessNotification>{data.message}</SuccessNotification>}
      {error && <ErrorNotification>{error}</ErrorNotification>}
      <form onSubmit={handleSubmit}>
        <Input
          label="Título da Votação"
          type="text"
          placeholder="insira o título da votação"
          name="titulo"
          required={true}
          {...title}
        />
        <DateInput label="Data de Início" name="dataInicio" {...startDate} />
        <DateInput
          label="Data de Encerramento"
          name="dataFinal"
          {...finalDate}
        />
        <MultipleSelectInput
          label="Grau Maçônico"
          name="grauMaconico"
          options={options}
          required={true}
          {...masonicDegree}
        />
        <SelectInput
          name="tipoVotacao"
          label="Tipo da Votação"
          options={['Pública', 'Privada']}
          required={true}
          {...votationType}
        />
        <TagsInput
          label="Tags"
          name="tags"
          placeholder="tags separadas por vírgula"
          {...tags}
        />
        <TextArea
          label="Descrição"
          name="descricao"
          placeholder="insira a descrição da votação"
          maxLength={300}
          {...description}
        />
        <FormButton disabled={loading}>
          {loading ? 'Criando...' : 'Criar Votação'}
        </FormButton>
      </form>
      <Menu />
    </section>
  );
};

export default Voting;
