const MembersStyles = {
  membersGrid: 'grid grid-cols-1 gap-3 pb-10',
  buttonContainer: 'flex flex-row justify-between gap-5',
  memberMainInfo: 'flex flex-row items-center gap-5 mb-5',
  memberImage: 'w-20 h-20 block rounded-full',
  memberInfo: 'text-xl font-medium text-gray-950 uppercase',
  informationHeader: 'flex flex-row items-center text-center gap-3',
  informationTitle: 'text-2xl font-bold mb-2',
  listItem: 'mb-4',
  listItemTitle: 'flex flex-row gap-1 items-center text-xl font-bold',
  listItemContent: 'text-lg text-gray-600',
  buttonContainer: 'flex flex-row justify-between gap-5',
  icon: 'w-5 h5',
  headerContainer: 'flex flex-row items-center text-center gap-3',
  headerTitle: 'text-2xl font-bold mb-2',
  editIcon: 'cursor-pointer mb-3 display-block w-4',
};

export default MembersStyles;
