const multipleSelectInputStyles = {
  container: 'mb-4 relative',
  label: 'text-s text-slate-950 capitalize',
  select:
    'bg-gray-100 rounded w-72 min-h-12 py-3 px-3 block text-base text-slate-950 placeholder:text-gray-600 focus:outline-2 outline-blue-500 cursor-pointer',
  dropdown: 'absolute z-50 bg-white border border-gray-300 w-72',
  option: 'p-1 flex flex-row gap-1 items-center  ',
  checkboxLabel: 'flex flex-row ml-1 gap-1',
  memberPhoto: 'w-7 h-7 rounded-full',
  icon: 'absolute right-2 top-11',
};

export default multipleSelectInputStyles;
