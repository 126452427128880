const VoteCardStyles = {
  card: 'flex flex-row items-center justify-between w-72 h-15 px-5 py-3 bg-gray-200 shadow-md rounded-md mb-4',
  voteIcon: 'w-12 h-12 rounded-full',
  voteText: 'font-bold',
  voteYes: 'text-green-500',
  voteNo: 'text-red-500',
  voteAbst: 'text-yellow-500',
};

export default VoteCardStyles;
