import LoaderStyles from './LoaderStyles';

const Loader = () => {
  return (
    <>
      <div className={`${LoaderStyles.centeredContainer}`}>
        <div
          className={`${LoaderStyles.base} ${LoaderStyles.spinner}`}
          role="status"
        >
          <span className={LoaderStyles.text}>Loading...</span>
        </div>
      </div>

      <div className={`${LoaderStyles.centeredContainer}`}>
        <div
          className={`${LoaderStyles.base} ${LoaderStyles.grow}`}
          role="status"
        >
          <span className={LoaderStyles.text}>Loading...</span>
        </div>
      </div>
    </>
  );
};

export default Loader;
