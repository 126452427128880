import PaginationStyles from './PaginationStyles';

const Pagination = ({ itemsPerPage, totalItems, paginate, currentPage }) => {
  const pageNumbers = [];
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const handleClick = (event, number) => {
    event.preventDefault();
    paginate(number);
  };

  let middlePages = [];

  if (currentPage && totalPages) {
    const start = Math.max(currentPage - 1, 1);
    const end = Math.min(start + 2, totalPages);
    middlePages = pageNumbers.slice(start - 1, end);
  }

  let startPage, endPage;
  if (totalPages <= 5) {
    startPage = 1;
    endPage = totalPages;
  } else {
    if (currentPage <= 3) {
      startPage = 1;
      endPage = 5;
    } else if (currentPage + 2 >= totalPages) {
      startPage = totalPages - 4;
      endPage = totalPages;
    } else {
      startPage = currentPage - 2;
      endPage = currentPage + 2;
    }
  }

  middlePages = pageNumbers.slice(startPage - 1, endPage);

  return (
    <nav className={PaginationStyles.wrapper}>
      <ul className={PaginationStyles.pageList}>
        {currentPage > 1 && (
          <li className={PaginationStyles.buttonPrev}>
            <button onClick={(e) => handleClick(e, currentPage - 1)}>
              &lt;
            </button>
          </li>
        )}

        {middlePages.map((number) => (
          <li key={number} className={PaginationStyles.pagination}>
            <button
              onClick={(e) => handleClick(e, number)}
              style={{ fontWeight: currentPage === number ? 'bold' : 'normal' }}
              className={PaginationStyles.paginationContent}
            >
              {number}
            </button>
          </li>
        ))}

        {currentPage < totalPages && (
          <li className={PaginationStyles.buttonNext}>
            <button onClick={(e) => handleClick(e, currentPage + 1)}>
              &gt;
            </button>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Pagination;
