import { useState } from 'react';
import { ReactComponent as DropdownIcon } from '../../assets/dropdown.svg';
import DefaultProfile from '../../assets/user-profile.svg';

import MemberSelectStyles from './MemberSelectStyles';

const MemberSelect = ({
  name,
  label,
  value,
  onChange,
  required,
  options,
  oneMember = false,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleChange = (memberCim) => {
    if (oneMember) {
      onChange([memberCim]);
    } else {
      if (value.includes(memberCim)) {
        onChange(value.filter((cim) => cim !== memberCim));
      } else {
        onChange([...value, memberCim]);
      }
    }
  };

  return (
    <div className={MemberSelectStyles.container}>
      <label htmlFor={name} className={MemberSelectStyles.label}>
        {label}
      </label>
      <div
        className={MemberSelectStyles.select}
        onClick={() => setDropdownOpen(!dropdownOpen)}
        style={{ cursor: 'pointer' }}
      >
        {value.join(', ') || 'Selecionar'}
        <div className={MemberSelectStyles.icon}>
          <DropdownIcon />
        </div>
      </div>
      {dropdownOpen && (
        <div className={MemberSelectStyles.dropdown}>
          {options.map((member) => (
            <div key={member.cim} className={MemberSelectStyles.option}>
              <input
                type="checkbox"
                id={`${member.cim}`}
                name={member.cim}
                checked={value.includes(member.cim)}
                onChange={() => handleChange(member.cim)}
                required={required}
                disabled={
                  (required &&
                    value.length <= 2 &&
                    value.includes(member.cim)) ||
                  (oneMember &&
                    !value.includes(member.cim) &&
                    value.length === 1)
                }
              />
              <label
                htmlFor={`${name}_${member.cim}`}
                className={MemberSelectStyles.checkboxLabel}
              >
                <img
                  src={member.foto ? member.foto : DefaultProfile}
                  alt={member.nome}
                  className={MemberSelectStyles.memberPhoto}
                />
                {member.nome}
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MemberSelect;
