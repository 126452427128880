import { createSlice } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';

const tokenFromStorage = localStorage.getItem('token');
const userFromToken = tokenFromStorage ? jwtDecode(tokenFromStorage) : null;

const initialState = {
  user: userFromToken,
  token: tokenFromStorage,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      const token = action.payload;
      const decoded = jwtDecode(token);
      state.user = decoded;
      state.token = token;
      localStorage.setItem('token', token);
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
      localStorage.removeItem('token');
    },
  },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
