import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import './tailwindImports.css';

import { Provider } from 'react-redux';
import { store } from './redux/store';

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/pwabuilder-sw.js')
      .then((reg) => console.log('Service Worker: Registered (Pages)', reg))
      .catch((err) => console.log(`Service Worker: Error: ${err}`));
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
);
