import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useAxios from '../../hooks/useAxios';
import useForm from '../../hooks/useForm';
import moment from 'moment';
import 'moment/locale/pt-br';

import { GET_VOTINGS, EDIT_VOTING, DELETE_VOTING } from '../../Api';

import BackLink from '../../utils/BackLink/BackLink';
import PageTitle from '../../utils/PageTitle/PageTitle';
import Input from '../../components/Forms/Input';
import DateInput from '../../components/Forms/DateInput';
import TagsInput from '../../components/Forms/TagsInput';
import FormButton from '../../components/Buttons/FormButton';
import RedButton from '../../components/Buttons/RedButton';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';
import SuccessNotification from '../../utils/SuccessNotification/SuccessNotification';
import WarningModal from '../../utils/WarningModal/WarningModal';
import Loader from '../../utils/Loader/Loader';
import Menu from '../../components/Menu/Menu';

import VotingStyles from './VotingStyles';
import GlobalStyles from '../../GlobalStyles';
import SelectInput from '../../components/Forms/SelectInput';

moment.locale('pt-br');

const VotingEdit = () => {
  const token = useSelector((state) => state.auth.token);
  const { votingId } = useParams();
  const navigate = useNavigate();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const {
    data: dataGet,
    error: errorGet,
    loading: loadingGet,
    execute: executeGet,
  } = useAxios(GET_VOTINGS);

  const {
    data: dataEdit,
    error: errorEdit,
    loading: loadingEdit,
    execute: executeEdit,
  } = useAxios(EDIT_VOTING);

  const {
    error: errorDelete,
    loading: loadingDelete,
    execute: executeDelete,
  } = useAxios(DELETE_VOTING);

  const title = useForm();
  const startDate = useForm();
  const finalDate = useForm();
  const tags = useForm('array');
  const status = useForm();

  const votingData = dataGet?.length > 0 ? dataGet[0] : null;

  useEffect(() => {
    executeGet(token, `_id=${votingId}`);
  }, [votingId, token, executeGet]);

  const formatDate = (date) =>
    moment.utc(date).local('pt-br').format('YYYY-MM-DD');

  useEffect(() => {
    if (votingData) {
      title.setValue(votingData.titulo);
      startDate.setValue(formatDate(votingData.dataInicio));
      finalDate.setValue(formatDate(votingData.dataFinal));
      tags.setValue(votingData.tags);
    }
  }, [votingData]);

  let votingStatus = false;
  if (status.value === 'Abrir Votação') {
    votingStatus = true;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    await executeEdit(token, votingId, {
      titulo: title.value,
      dataInicio: startDate.value,
      dataFinal: finalDate.value,
      tags: tags.value,
      status: votingStatus,
    });

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleOpenModal = () => setModalIsOpen(true);
  const handleCloseModal = () => setModalIsOpen(false);

  const handleDelete = () => {
    executeDelete(token, votingId);
    navigate(`/votacao/consultar-votacao`);
  };

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page={`/votacao/consultar-votacao/${votingId}`} />
      <PageTitle>Editar Votação</PageTitle>

      {errorGet && <ErrorNotification>{errorGet}</ErrorNotification>}
      {loadingGet && <Loader />}
      {dataEdit && (
        <SuccessNotification>{dataEdit.message}</SuccessNotification>
      )}
      {errorEdit && <ErrorNotification>{errorEdit}</ErrorNotification>}
      {errorDelete && <ErrorNotification>{errorDelete}</ErrorNotification>}

      {!loadingGet && votingData && (
        <form onSubmit={handleSubmit}>
          <Input
            label="Título da Votação"
            type="text"
            placeholder="insira o título da votação"
            name="titulo"
            required={true}
            {...title}
          />
          <DateInput label="Data de Início" name="dataInicio" {...startDate} />
          <DateInput
            label="Data de Encerramento"
            name="dataFinal"
            {...finalDate}
          />

          <TagsInput
            label="Tags"
            name="tags"
            placeholder="tags separadas por vírgula"
            {...tags}
          />
          <SelectInput
            name="status"
            label="Abrir/Fechar"
            options={['Abrir Votação', 'Fechar Votação']}
            {...status}
          />
          <div className={VotingStyles.buttonContainer}>
            <FormButton disabled={loadingEdit}>
              {loadingEdit ? 'Editando...' : 'Editar'}
            </FormButton>
            <RedButton onClick={handleOpenModal}>
              {loadingDelete ? 'Deletando...' : 'Deletar'}
            </RedButton>
          </div>
        </form>
      )}

      {modalIsOpen && (
        <WarningModal
          title="Deletar Votação"
          text="Deseja deletar permanentemente esta votação?"
          actionName="Deletar"
          modalAction={handleDelete}
          closeModal={handleCloseModal}
        />
      )}
      <Menu />
    </section>
  );
};

export default VotingEdit;
