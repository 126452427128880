import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useForm from '../../hooks/useForm';
import useAxios from '../../hooks/useAxios';

import { EDIT_MEMBER, GET_MEMBERS, GET_ROLES } from '../../Api';

import BackLink from '../../utils/BackLink/BackLink';
import PageTitle from '../../utils/PageTitle/PageTitle';
import Loader from '../../utils/Loader/Loader';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';
import SuccessNotification from '../../utils/SuccessNotification/SuccessNotification';
import SelectInput from '../../components/Forms/SelectInput';

import { ReactComponent as PermissionsIcon } from '../../assets/permissions-dark.svg';
import { ReactComponent as DefaultProfile } from '../../assets/user-profile.svg';

import GlobalStyles from '../../GlobalStyles';
import PermissionsStyles from './PermissionsStyles';
import FormButton from '../../components/Buttons/FormButton';

const MemberRoleEdit = () => {
  const token = useSelector((state) => state.auth.token);
  const { data, error, loading, execute } = useAxios(GET_MEMBERS);
  const {
    data: dataEdit,
    error: errorEdit,
    loading: loadingEdit,
    execute: executeEdit,
  } = useAxios(EDIT_MEMBER);
  const { data: dataRole, execute: executeRole } = useAxios(GET_ROLES);
  const role = useForm();

  const { cim } = useParams();

  useEffect(() => {
    execute(token, `cim=${cim}`);
    executeRole(token, ``);
  }, [token, execute, cim]);

  const memberData = data?.length > 0 ? data[0] : null;

  const options = dataRole?.map((role) => role.cargo);

  const handleSubmit = async (event) => {
    event.preventDefault();
    await executeEdit(token, cim, { ...memberData, cargo: role.value });
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page="/permissoes/consultar-cargos/membros" />
      <PageTitle>Editar Cargo do Membro</PageTitle>
      {loading && <Loader />}
      {error && <ErrorNotification>{error}</ErrorNotification>}
      {errorEdit && <ErrorNotification>{errorEdit}</ErrorNotification>}
      {dataEdit && (
        <SuccessNotification>{dataEdit.message}</SuccessNotification>
      )}
      {memberData && (
        <div>
          <div className={PermissionsStyles.memberMainInfo}>
            {memberData.foto ? (
              <img
                className={PermissionsStyles.memberImage}
                src={memberData.foto}
              />
            ) : (
              <DefaultProfile className={PermissionsStyles.memberImage} />
            )}
            <div>
              <h2 className={PermissionsStyles.memberInfo}>
                {memberData.nome}
              </h2>
              <p className="text-gray-600">CIM: {memberData.cim}</p>
              <p className="text-gray-600">{memberData.grauMaconico}</p>
            </div>
          </div>
          <div className={PermissionsStyles.listItem}>
            <h2 className={PermissionsStyles.listItemTitle}>
              <PermissionsIcon className={PermissionsStyles.icon} /> Cargo
              Atual:
            </h2>
            <p className={PermissionsStyles.listItemContent}>
              {memberData.cargo}
            </p>
          </div>
          {dataRole && (
            <form onSubmit={handleSubmit}>
              <SelectInput
                name="cargo"
                label="Editar Cargo do Membro"
                options={options}
                required={true}
                {...role}
              />
              <FormButton disabled={loadingEdit}>
                {loadingEdit ? 'Editando...' : 'Editar'}
              </FormButton>
            </form>
          )}
        </div>
      )}
    </section>
  );
};

export default MemberRoleEdit;
