import axios from 'axios';

export const SERVER_URL = 'https://areopago.na.tec.br';

export const API_URL = 'https://areopago.na.tec.br/api';

// export const SERVER_URL = 'http://localhost:3001';

// export const API_URL = 'http://localhost:3001/api';

export function MEMBER_LOGIN(body) {
  return axios.post(`${API_URL}/auth/login`, body, {
    headers: { 'Content-Type': 'application/json' },
  });
}

export function GUEST_LOGIN(body) {
  return axios.post(`${API_URL}/auth/login-visitante`, body, {
    headers: { 'Content-Type': 'application/json' },
  });
}

export function RESET_PASSWORD(body) {
  return axios.post(`${API_URL}/auth/redefinir-senha`, body, {
    headers: { 'Content-Type': 'application/json' },
  });
}

export function NEW_PASSWORD(newPasswordToken, body) {
  return axios.post(`${API_URL}/auth/nova-senha/${newPasswordToken}`, body, {
    headers: { 'Content-Type': 'application/json' },
  });
}

export function GET_MESSAGES(token, chatId) {
  return axios.get(`${API_URL}/chat/chat-membro/${chatId}/mensagens`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}

export function GET_GROUP_MESSAGES(token, chatId) {
  return axios.get(`${API_URL}/chat/chat-grupo/${chatId}/mensagens`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}

export function OPEN_CHAT(token, body) {
  return axios.post(`${API_URL}/chat/chat-membro`, body, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}

export function GET_GROUPS(token, queryParameters) {
  const queryString = new URLSearchParams(queryParameters).toString();
  return axios.get(
    `${API_URL}/chat/chat-grupos${queryString ? `?${queryString}` : ''}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export function CREATE_GROUP(token, body) {
  return axios.post(`${API_URL}/chat/criar-grupo`, body, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}

export function SEND_MESSAGE(token, chatId, message) {
  return axios.post(
    `${API_URL}/chat-membro/enviar-mensagem`,
    { chatId, content: message },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export function CREATE_MEMBER(token, body) {
  return axios.post(`${API_URL}/membros/criar`, body, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}

export function GET_MEMBERS(token, queryParameters) {
  const queryString = new URLSearchParams(queryParameters).toString();
  return axios.get(
    `${API_URL}/membros${queryString ? `?${queryString}` : ''}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export function EDIT_MEMBER(token, cim, body) {
  return axios.patch(`${API_URL}/membros/editar/${cim}`, body, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}

export function DELETE_MEMBER(token, cim) {
  return axios.delete(`${API_URL}/membros/deletar/${cim}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}

export function CREATE_FAMILY_MEMBER(token, cim, body) {
  return axios.post(`${API_URL}/familiares/criar/${cim}`, body, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}

export function GET_FAMILY_MEMBERS(token, cim, queryParameters) {
  const queryString = new URLSearchParams(queryParameters).toString();
  return axios.get(
    `${API_URL}/familiares/buscar/${cim}${
      queryString ? `?${queryString}` : ''
    }`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export function EDIT_FAMILY_MEMBER(token, cim, familyId, body) {
  return axios.patch(`${API_URL}/familiares/editar/${cim}/${familyId}`, body, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}

export function DELETE_FAMILY_MEMBER(token, cim, familyId) {
  return axios.delete(`${API_URL}/familiares/deletar/${cim}/${familyId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}

export function CREATE_EVENT(token, body) {
  return axios.post(`${API_URL}/eventos/criar`, body, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}

export function GET_EVENTS(token, queryParameters) {
  const queryString = new URLSearchParams(queryParameters).toString();
  return axios.get(
    `${API_URL}/eventos${queryString ? `?${queryString}` : ''}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export function EDIT_EVENT(token, eventId, body) {
  return axios.patch(`${API_URL}/eventos/editar/${eventId}`, body, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}

export function DELETE_EVENT(token, eventId) {
  return axios.delete(`${API_URL}/eventos/deletar/${eventId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}

export function CONFIRM_PRESENCE(token, votingId, body) {
  return axios.post(`${API_URL}/presencas/confirmar/${votingId}`, body, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}

export function GET_FREQUENCY(token, cim, queryParameters) {
  const queryString = new URLSearchParams(queryParameters).toString();
  return axios.get(
    `${API_URL}/presencas/membro/${cim}${queryString ? `?${queryString}` : ''}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export function UPDATE_EVENT_STATUS(token, eventId, body) {
  return axios.patch(`${API_URL}/presencas/evento/status/${eventId}`, body, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}

export function GET_BIRTHDAYS(token, queryParameters) {
  const queryString = new URLSearchParams(queryParameters).toString();
  return axios.get(
    `${API_URL}/eventos/aniversarios${queryString ? `?${queryString}` : ''}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export function CREATE_VOTING(token, body) {
  return axios.post(`${API_URL}/votacao/criar`, body, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}

export function GET_VOTINGS(token, queryParameters) {
  const queryString = new URLSearchParams(queryParameters).toString();
  return axios.get(
    `${API_URL}/votacao${queryString ? `?${queryString}` : ''}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export function EDIT_VOTING(token, votingId, body) {
  return axios.patch(`${API_URL}/votacao/editar/${votingId}`, body, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}

export function DELETE_VOTING(token, votingId) {
  return axios.delete(`${API_URL}/votacao/deletar/${votingId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}

export function INSERT_VOTING(token, votingId, body) {
  return axios.post(`${API_URL}/votacao/votar/${votingId}`, body, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}

export function GET_MEMBERS_VOTES(token, votingId) {
  return axios.get(`${API_URL}/votacao/votos/${votingId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}

export function CREATE_RECORD(token, body) {
  return axios.post(`${API_URL}/livro-de-atas/criar`, body, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}

export function GET_RECORDS(token, queryParameters) {
  const queryString = new URLSearchParams(queryParameters).toString();
  return axios.get(
    `${API_URL}/livro-de-atas${queryString ? `?${queryString}` : ''}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export function EDIT_RECORD(token, recordId, body) {
  return axios.patch(`${API_URL}/livro-de-atas/editar/${recordId}`, body, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}

export function DELETE_RECORD(token, recordId) {
  return axios.delete(`${API_URL}/livro-de-atas/deletar/${recordId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}

export function VOTE_IN_RECORD(token, recordId, body) {
  return axios.post(`${API_URL}/livro-de-atas/votar/${recordId}`, body, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}

export function CREATE_ROLE(token, body) {
  return axios.post(`${API_URL}/permissoes/criar`, body, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}

export function GET_ROLES(token, queryParameters) {
  const queryString = new URLSearchParams(queryParameters).toString();
  return axios.get(
    `${API_URL}/permissoes${queryString ? `?${queryString}` : ''}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export function EDIT_ROLE(token, roleId, body) {
  return axios.patch(`${API_URL}/permissoes/editar/${roleId}`, body, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}

export function DELETE_ROLE(token, roleId) {
  return axios.delete(`${API_URL}/permissoes/deletar/${roleId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}

export function EDIT_GROUP(token, groupId, body) {
  return axios.patch(`${API_URL}/chat/editar/${groupId}`, body, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}

export function DELETE_GROUP(token, groupId) {
  return axios.delete(`${API_URL}/chat/deletar/${groupId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}

export function CREATE_RECURRENT_INVOICE(token, data) {
  return axios.post(`${API_URL}/faturas-recorrentes/criar`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}

export function GET_MY_INVOICES(token, financeId) {
  return axios.get(`${API_URL}/faturas-recorrentes/minhas-faturas`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    params: {
      financeId,
    },
  });
}

export function CREATE_SINGLE_INVOICE(token, data) {
  return axios.post(`${API_URL}/faturas-recorrentes/criar-unica`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}
