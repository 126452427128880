import PageTitle from '../../utils/PageTitle/PageTitle';

import { ReactComponent as Logo } from '../../assets/logo.svg';

import Menu from '../../components/Menu/Menu';
import { ReactComponent as FinancialIcon } from '../../assets/financial.svg';
import { ReactComponent as ChatIcon } from '../../assets/chat.svg';
import { ReactComponent as MembersIcon } from '../../assets/members.svg';
import { ReactComponent as CalendarIcon } from '../../assets/calendar.svg';
import { ReactComponent as RecordBookIcon } from '../../assets/book.svg';
import { ReactComponent as VotingIcon } from '../../assets/votes.svg';
import { ReactComponent as PresencesIcon } from '../../assets/presences.svg';
import { ReactComponent as PermissionsIcon } from '../../assets/permissions.svg';

import PageButton from '../../components/Buttons/PageButton';

import HomeStyles from './HomeStyles';
import { useSelector } from 'react-redux';

const pagesButtons = [
  { text: 'Chat', page: '/chat', icon: <ChatIcon />, disabled: false },
  {
    text: 'Membros',
    page: '/membros',
    icon: <MembersIcon />,
    disabled: false,
  },
  {
    text: 'Calendário',
    page: '/eventos',
    icon: <CalendarIcon />,
    disabled: false,
  },
  {
    text: 'Livro de Atas',
    page: '/livro-de-atas',
    icon: <RecordBookIcon />,
    disabled: false,
  },
  {
    text: 'Votação de Pautas',
    page: '/votacao',
    icon: <VotingIcon />,
    disabled: false,
  },
  {
    text: 'Presenças',
    page: '/presencas',
    icon: <PresencesIcon />,
    disabled: false,
  },
];

const Home = () => {
  const user = useSelector((state) => state.auth.user);

  return (
    <section>
      <div className={HomeStyles.logoContainer}>
        <Logo />
      </div>
      <PageTitle>Home - Areópago</PageTitle>
      <div className={HomeStyles.buttonsGrid}>
        <PageButton
          page="/financeiro"
          icon={<FinancialIcon />}
          disabled={false}
        >
          Financeiro
        </PageButton>
        {pagesButtons.map((button, index) => (
          <PageButton
            key={index}
            page={button.page}
            icon={button.icon}
            disabled={button.disabled}
          >
            {button.text}
          </PageButton>
        ))}
        {user.cargo === 'Venerável' && (
          <PageButton
            page="/permissoes"
            icon={<PermissionsIcon />}
            disabled={false}
          >
            Permissões
          </PageButton>
        )}
      </div>
      <Menu />
    </section>
  );
};

export default Home;
