import { Route } from 'react-router-dom';

import RecordsBook from '../views/RecordsBook/RecordsBook';
import RecordCreate from '../views/RecordsBook/RecordCreate';
import RecordSearch from '../views/RecordsBook/RecordSearch';
import RecordSingle from '../views/RecordsBook/RecordSingle';
import VoteRecord from '../views/RecordsBook/RecordVote';
import RecordStatus from '../views/RecordsBook/RecordStatus';
import RecordVotesDetails from '../views/RecordsBook/RecordVotesDetails';
import RecordEdit from '../views/RecordsBook/RecordEdit';

const RecordsBookRoutes = () => {
  return [
    <Route
      key="livro-de-atas"
      path="/livro-de-atas"
      element={<RecordsBook />}
    />,
    <Route
      key="criar-ata"
      path="/livro-de-atas/criar-ata"
      element={<RecordCreate />}
    />,
    <Route
      key="consultar-atas"
      path="/livro-de-atas/consultar-atas"
      element={<RecordSearch />}
    />,
    <Route
      key="ata"
      path="/livro-de-atas/consultar-ata/:recordId"
      element={<RecordSingle />}
    />,
    <Route
      key="votar"
      path="/livro-de-atas/votar/:recordId"
      element={<VoteRecord />}
    />,
    <Route
      key="ata-status"
      path="/livro-de-atas/status/:recordId"
      element={<RecordStatus />}
    />,
    <Route
      key="detalhes-votacao"
      path="/livro-de-atas/status/votacao/:recordId"
      element={<RecordVotesDetails />}
    />,
    <Route
      key="editar-ata"
      path="/livro-de-atas/editar/:recordId"
      element={<RecordEdit />}
    />,
  ];
};

export default RecordsBookRoutes;
