import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useAxios from '../../hooks/useAxios';

import { GET_FAMILY_MEMBERS } from '../../Api';

import FamilyCard from '../../components/Cards/FamilyCard';
import PageTitle from '../../utils/PageTitle/PageTitle';
import Button from '../../components/Buttons/Button';
import BackLink from '../../utils/BackLink/BackLink';
import Menu from '../../components/Menu/Menu';
import Loader from '../../utils/Loader/Loader';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';

import GlobalStyles from '../../GlobalStyles';
import WarningNotification from '../../utils/Notification/WarningNotification';

const MemberFamily = () => {
  const { token } = useSelector((state) => state.auth.token);
  const { data, error, loading, execute } = useAxios(GET_FAMILY_MEMBERS);
  const { cim } = useParams();
  useEffect(() => {
    execute(token, cim);
  }, [cim, execute, token]);

  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page={`/membros/${cim}`} />
      <PageTitle>Familiares</PageTitle>
      <div className="grid grid-cols-1 gap-3">
        {data &&
          data.map((familiar) => (
            <FamilyCard
              key={familiar._id}
              nome={familiar.nome}
              parentesco={familiar.parentesco}
              email={familiar.email}
              telefone={familiar.telefone}
              edit={`/familiares/editar/${cim}/${familiar._id}`}
            />
          ))}
        {data && !data.length > 0 && (
          <WarningNotification>Nenhum Familiar Encontrado</WarningNotification>
        )}
      </div>

      {user.cargo === 'Venerável' && (
        <Button onClick={() => navigate(`/familiares/criar/${cim}`)}>
          + Familiar
        </Button>
      )}
      {error && <ErrorNotification>{error}</ErrorNotification>}
      {loading && <Loader />}
      <Menu />
    </section>
  );
};

export default MemberFamily;
