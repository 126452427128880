import useAxios from '../../hooks/useAxios';
import { useNavigate } from 'react-router-dom';
import { Suspense, lazy, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useForm from '../../hooks/useForm';

import { GET_ROLES } from '../../Api';

import GlobalStyles from '../../GlobalStyles';
import BackLink from '../../utils/BackLink/BackLink';
import Loader from '../../utils/Loader/Loader';
import PageTitle from '../../utils/PageTitle/PageTitle';
import Menu from '../../components/Menu/Menu';
import Pagination from '../../utils/Pagination/Pagination';
import SearchInput from '../../components/Forms/SearchInput';
const PermissionCard = lazy(() =>
  import('../../components/Cards/PermissionCard'),
);

const RoleSearch = () => {
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const { data, error, loading, execute } = useAxios(GET_ROLES);
  const searchTerm = useForm();

  useEffect(() => {
    execute(token, ``);
  }, [token]);

  useEffect(() => {
    execute(token, `cargo=${searchTerm.value}`);
    setCurrentPage(1);
  }, [searchTerm.value, execute, token]);

  const [currentPage, setCurrentPage] = useState(1);

  const rolesPerPage = 5;

  const indexOfLastRole = currentPage * rolesPerPage;
  const indexOfFirstRole = indexOfLastRole - rolesPerPage;

  const currentRoles = data
    ? data.slice(indexOfFirstRole, indexOfLastRole)
    : [];

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page="/permissoes" />
      <PageTitle>Consultar Cargos</PageTitle>
      <SearchInput placeholder="buscar cargos" {...searchTerm} />
      <Suspense fallback={<Loader />}>
        {!error &&
          currentRoles?.map((data, index) => (
            <PermissionCard
              key={index}
              text={data.cargo}
              onClick={() => navigate(`/cargo/${data._id}`)}
            />
          ))}
      </Suspense>

      <Pagination
        itemsPerPage={rolesPerPage}
        totalItems={data?.length}
        paginate={paginate}
        currentPage={currentPage}
      />
      <Menu />
    </section>
  );
};

export default RoleSearch;
