import { useState, useCallback } from 'react';
import axios from 'axios';

const useAxios = (apiFunc) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const execute = useCallback(
    async (...params) => {
      try {
        setLoading(true);
        const response = await apiFunc(...params);
        setData(response.data);
        setError(null);
        return response;
      } catch (err) {
        if (axios.isCancel(err)) {
          setError('Requisição Cancelada');
        } else if (!err.response) {
          setError('Falha na Conexão');
        } else {
          setError(err.response.data.message || 'Erro Desconhecido');
        }
      } finally {
        setLoading(false);
      }
    },
    [apiFunc],
  );

  return { data, setData, error, setError, loading, setLoading, execute };
};

export default useAxios;
