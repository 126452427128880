import FrequencyCardStyles from './FrequencyCardStyles';

const FrequencyCard = ({ titulo, data, presente }) => {
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }
  return (
    <div className={FrequencyCardStyles.wrapper}>
      <h2>
        {titulo} - {formatDate(data)}
      </h2>
      {presente ? (
        <p className={FrequencyCardStyles.presentIn}>Presente</p>
      ) : (
        <p className={FrequencyCardStyles.absentIn}>Ausente</p>
      )}
    </div>
  );
};

export default FrequencyCard;
