import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/auth/authSlice';

import useAxios from '../../hooks/useAxios';
import useForm from '../../hooks/useForm';

import { GUEST_LOGIN } from '../../Api';

import Input from '../../components/Forms/Input';
import FormButton from '../../components/Buttons/FormButton';
import PageTitle from '../../utils/PageTitle/PageTitle';
import SelectInput from '../../components/Forms/SelectInput';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';

import { ReactComponent as Logo } from '../../assets/logo.svg';
import { ReactComponent as TriangleDarkIcon } from '../../assets/triangle-dark.svg';

import LoginStyles from './LoginStyles';

const LoginAsGuest = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data, error, loading, execute } = useAxios(GUEST_LOGIN);

  const [showCustomPower, setShowCustomPower] = useState(false);
  const customPower = useForm();

  const cim = useForm('number');
  const name = useForm();
  const email = useForm('email');
  const telephone = useForm('telephone');
  const potencia = useForm('');
  const lodge = useForm();

  const formFields = [
    {
      label: 'CIM',
      type: 'text',
      placeholder: 'insira seu cim',
      name: 'cim',
      required: true,
      hook: cim,
    },
    {
      label: 'Nome',
      type: 'text',
      placeholder: 'insira seu nome',
      name: 'nome',
      required: true,
      hook: name,
    },
    {
      label: 'Email',
      type: 'email',
      placeholder: 'insira seu email',
      name: 'email',
      required: true,
      hook: email,
    },
    {
      label: 'Telefone',
      type: 'text',
      placeholder: 'insira seu telefone',
      name: 'telefone',
      hook: telephone,
    },
    {
      label: 'Loja Maçônica',
      type: 'text',
      placeholder: 'insira o nome da sua loja maçônica',
      name: 'lojaMaconica',
      hook: lodge,
    },
  ];

  const handleSubmit = async (event) => {
    event.preventDefault();
    await execute({
      cim: cim.value,
      nome: name.value,
      email: email.value,
      telefone: telephone.value,
      lojaMaconica: lodge.value,
      potencia: showCustomPower ? customPower.value : potencia.value,
    });

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (data) {
      dispatch(login(data.token));
      navigate('/');
    }
  }, [data]);

  useEffect(() => {
    setShowCustomPower(potencia.value === 'outra');
  }, [potencia.value]);

  const options = ['GOB', 'CMSB', 'COMAD', 'outra'];

  return (
    <section>
      <div className={LoginStyles.logoContainer}>
        <Logo />
      </div>
      <PageTitle>Entrar como Visitante</PageTitle>
      <form onSubmit={handleSubmit}>
        {error && <ErrorNotification>{error}</ErrorNotification>}

        {formFields.map((field, index) => (
          <Input
            key={index}
            label={field.label}
            type={field.type}
            placeholder={field.placeholder}
            name={field.name}
            required={field.required}
            {...field.hook}
          />
        ))}
        <SelectInput
          label="Potência"
          name="potencia"
          required={true}
          options={options}
          {...potencia}
        />
        {showCustomPower && (
          <Input
            label="Especifique sua potência"
            type="text"
            placeholder="Digite sua potência"
            {...customPower}
          />
        )}
        <FormButton disabled={loading}>
          {loading ? 'Carregando...' : 'Visitar'}
        </FormButton>
      </form>
      <Link to="/login" className={LoginStyles.enter}>
        <TriangleDarkIcon />
        Entrar como Membro
      </Link>
    </section>
  );
};

export default LoginAsGuest;
