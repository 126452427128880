import { Link, useParams } from 'react-router-dom';

import useAxios from '../../hooks/useAxios';
import useForm from '../../hooks/useForm';

import { NEW_PASSWORD } from '../../Api';

import Input from '../../components/Forms/Input';
import FormButton from '../../components/Buttons/FormButton';
import PageTitle from '../../utils/PageTitle/PageTitle';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';
import SuccessNotification from '../../utils/SuccessNotification/SuccessNotification';

import { ReactComponent as Logo } from '../../assets/logo.svg';
import { ReactComponent as TriangleDarkIcon } from '../../assets/triangle-dark.svg';

import LoginStyles from './LoginStyles';

const NewPassword = () => {
  const { newPasswordToken } = useParams();

  const { data, error, setError, loading, execute } = useAxios(NEW_PASSWORD);

  const password = useForm('password');
  const confirmPassword = useForm('password');

  const handleSubmit = (event) => {
    event.preventDefault();
    if (password.value !== confirmPassword.value) {
      setError('As Senhas Devem Ser Iguais');
    } else {
      execute(newPasswordToken, { senha: password.value });
    }
  };

  return (
    <section>
      <div className={LoginStyles.logoContainer}>
        <Logo />
      </div>
      <PageTitle>Criar Nova Senha</PageTitle>
      <form onSubmit={handleSubmit}>
        <Input
          label="Nova Senha"
          type="password"
          placeholder="insira sua nova senha"
          name="senha"
          required
          {...password}
        />
        <Input
          label="Confirmar Senha"
          type="password"
          placeholder="confirme sua senha"
          name="confirmarSenha"
          required
          {...confirmPassword}
        />
        <FormButton disabled={loading}>
          {loading ? 'Carregando' : 'Enviar'}
        </FormButton>
      </form>
      <Link to="/login" className={LoginStyles.enter}>
        <TriangleDarkIcon />
        Entrar como Membro
      </Link>
      {data && <SuccessNotification>{data.message}</SuccessNotification>}
      {error && <ErrorNotification>{error}</ErrorNotification>}
    </section>
  );
};

export default NewPassword;
