const SearchInputStyles = {
  wrapper: 'mb-4 relative',
  label: 'text-s text-slate-950 capitalize',
  input:
    'bg-gray-100 rounded w-72 h-12 py-3 px-3 block text-base text-slate-950 placeholder:text-gray-600 focus:outline-2 outline-blue-500 cursor-text',
  error: 'text-xs text-red-500 font-bold w-72',
  icon: 'absolute right-2 top-4',
};

export default SearchInputStyles;
