import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useAxios from '../../hooks/useAxios';
import { GET_FREQUENCY } from '../../Api';

import FrequencyCard from '../../components/Cards/FrequencyCard';
import DateInput from '../../components/Forms/DateInput';
import PageTitle from '../../utils/PageTitle/PageTitle';
import BackLink from '../../utils/BackLink/BackLink';
import Pagination from '../../utils/Pagination/Pagination';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';
import Loader from '../../utils/Loader/Loader';
import Menu from '../../components/Menu/Menu';

import GlobalStyles from '../../GlobalStyles';
import PresencesStyles from './PresencesStyles';
import WarningNotification from '../../utils/Notification/WarningNotification';

const Frequency = () => {
  const token = useSelector((state) => state.auth.token);
  const { cim } = useParams();
  const { data, error, loading, execute } = useAxios(GET_FREQUENCY);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const eventsPerPage = 4;
  const [combinedEvents, setCombinedEvents] = useState([]);

  useEffect(() => {
    execute(token, cim, `de=${startDate}&ate=${endDate}`);
  }, [token, cim, startDate, endDate]);

  useEffect(() => {
    if (data && data.presentIn && data.absentIn) {
      const presentEvents = data.presentIn.map((event) => ({
        ...event,
        presente: true,
      }));
      const absentEvents = data.absentIn.map((event) => ({
        ...event,
        presente: false,
      }));
      setCombinedEvents([...presentEvents, ...absentEvents]);
    }
  }, [data]);

  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = combinedEvents.slice(
    indexOfFirstEvent,
    indexOfLastEvent,
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink />
      <PageTitle>Frequência</PageTitle>
      <DateInput
        label="Filtrar por Data"
        name="startDate"
        value={startDate}
        onChange={handleStartDateChange}
      />
      {startDate && (
        <DateInput
          label="Data Final"
          name="endDate"
          value={endDate}
          onChange={handleEndDateChange}
        />
      )}
      {loading && <Loader />}
      {error && (
        <WarningNotification>Nenhum Evento Encontrado</WarningNotification>
      )}
      {!error && data?.presentIn && data?.absentIn && (
        <>
          <h2 className={PresencesStyles.cardTitle}>Reuniões No Período:</h2>
          <p className={PresencesStyles.infoText}>
            {data.presentIn.length + data.absentIn.length} Reuniões
          </p>
          <h2 className={PresencesStyles.cardTitle}>Reuniões Presentes:</h2>
          <p className={PresencesStyles.presenceCount}>
            {data.presentIn.length} Presenças
          </p>
          <h2 className={PresencesStyles.cardTitle}>Reuniões Ausentes:</h2>
          <p className={PresencesStyles.absenceCount}>
            {data.absentIn.length} Ausências
          </p>
          {currentEvents.map((evento) => (
            <FrequencyCard
              titulo={evento.titulo}
              data={evento.data}
              presente={evento.presente}
              key={evento._id}
            />
          ))}
          <Pagination
            itemsPerPage={eventsPerPage}
            totalItems={combinedEvents.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        </>
      )}
      <Menu />
    </section>
  );
};

export default Frequency;
