const ChatsStyles = {
  membersGrid: 'grid grid-cols-1 gap-3 pb-10',
  buttonContainerForm: 'flex flex-row justify-between gap-5',
  buttonContainer:
    'flex flex-row justify-between gap-5 items-center mt-[-16px]',
  memberMainInfo: 'flex flex-row items-center gap-5 mb-5',
  groupMainInfo: 'flex flex-row items-center gap-5 mb-5 cursor-pointer',
  memberImage: 'w-20 h-20 block rounded-full',
  memberInfo: 'text-xl font-medium text-slate-950',
};

export default ChatsStyles;
