import useForm from '../../hooks/useForm';
import useAxios from '../../hooks/useAxios';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Suspense, lazy, useEffect, useState } from 'react';

import { GET_RECORDS } from '../../Api';

import BackLink from '../../utils/BackLink/BackLink';
import PageTitle from '../../utils/PageTitle/PageTitle';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';
import MultipleSelectInput from '../../components/Forms/MultipleSelectInput';
import TagsInput from '../../components/Forms/TagsInput';
import Loader from '../../utils/Loader/Loader';
import Menu from '../../components/Menu/Menu';
import Pagination from '../../utils/Pagination/Pagination';

import RecordsBookStyles from './RecordsBookStyles';
import GlobalStyles from '../../GlobalStyles';
import WarningNotification from '../../utils/Notification/WarningNotification';

const RecordCard = lazy(() => import('../../components/Cards/RecordCard'));

const RecordSearch = () => {
  const token = useSelector((state) => state.auth.token);
  const { data, error, loading, execute } = useAxios(GET_RECORDS);
  const navigate = useNavigate();

  const masonicDegree = useForm('array');
  const tags = useForm('array');

  useEffect(() => {
    execute(token);
  }, [token, execute]);

  useEffect(() => {
    execute(token, `grauMaconico=${masonicDegree.value}&tags=${tags.value}`);
  }, [masonicDegree.value, tags.value, execute, token]);

  const options = ['Aprendiz', 'Companheiro', 'Mestre'];

  const [currentPage, setCurrentPage] = useState(1);

  const recordsPerPage = 4;

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  const currentRecords = data
    ? data.slice(indexOfFirstRecord, indexOfLastRecord)
    : [];

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page="/livro-de-atas" />
      <PageTitle>Consultar Atas</PageTitle>
      <MultipleSelectInput
        label="Grau Maçônico"
        name="grauMaconico"
        options={options}
        {...masonicDegree}
      />
      <TagsInput
        label="Tags"
        name="tags"
        placeholder="tags separadas por vírgula"
        {...tags}
      />
      {data && <h2 className={RecordsBookStyles.subTitle}>Atas</h2>}
      <Suspense fallback={<Loader />}>
        {!error &&
          currentRecords?.map((record, index) => (
            <RecordCard
              key={index}
              title={record.titulo}
              onClick={() =>
                navigate(`/livro-de-atas/consultar-ata/${record._id}`)
              }
            />
          ))}
      </Suspense>
      {loading && <Loader />}
      {error && (
        <WarningNotification>Nenhuma ata encontrada</WarningNotification>
      )}
      {!error && (
        <Pagination
          itemsPerPage={recordsPerPage}
          totalItems={data?.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      )}
      <Menu />
    </section>
  );
};

export default RecordSearch;
