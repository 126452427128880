import { Link } from 'react-router-dom';

import { ReactComponent as BackIcon } from '../../assets/back.svg';

import BackLinkStyles from './BackLinkStyles';

const BackLink = ({ page = -1 }) => {
  return (
    <Link className={BackLinkStyles.backLink} to={page}>
      <BackIcon className={BackLinkStyles.icon} />
    </Link>
  );
};

export default BackLink;
