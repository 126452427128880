import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import TextAreaStyles from './TextAreaStyles';

const RichTextArea = ({ label, name, value, onChange }) => {
  return (
    <div className={TextAreaStyles.container}>
      <label htmlFor={name} className={TextAreaStyles.label}>
        {label}
      </label>
      <ReactQuill theme="snow" value={value} onChange={onChange} />
    </div>
  );
};

export default RichTextArea;
