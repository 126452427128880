import PageTitle from '../../utils/PageTitle/PageTitle';

import { ReactComponent as Logo } from '../../assets/logo.svg';

import { ReactComponent as FinancialIcon } from '../../assets/financial.svg';

import { ReactComponent as PresencesIcon } from '../../assets/presences.svg';

import PageButton from '../../components/Buttons/PageButton';

import HomeStyles from './HomeStyles';
import GuestMenu from '../../components/Menu/GuestMenu';

const pagesButtons = [
  {
    text: 'Fazer uma Doação',
    page: '/financeiro/tronco',
    icon: <FinancialIcon />,
    disabled: false,
  },
  {
    text: 'Informar Presença',
    page: '/presencas/informar-presenca',
    icon: <PresencesIcon />,
    disabled: false,
  },
];

const HomeGuest = () => {
  return (
    <section>
      <div className={HomeStyles.logoContainer}>
        <Logo />
      </div>
      <PageTitle>Home - Areópago</PageTitle>
      <div className={HomeStyles.buttonsGrid}>
        <h2 className={HomeStyles.guest}>Visitante</h2>
        {pagesButtons.map((button, index) => (
          <PageButton
            key={index}
            page={button.page}
            icon={button.icon}
            disabled={button.disabled}
          >
            {button.text}
          </PageButton>
        ))}
      </div>
      <GuestMenu />
    </section>
  );
};

export default HomeGuest;
