import { ReactComponent as Logo } from '../../assets/logo.svg';
import { Link } from 'react-router-dom';

import NotFoundStyles from './NotFoundStyles';

const NotFound = () => {
  return (
    <section className={NotFoundStyles.container}>
      <div className="text-center">
        <div className={NotFoundStyles.logoContainer}>
          <Logo />
        </div>
        <p className={NotFoundStyles.statusCode}>404</p>
        <h1 className={NotFoundStyles.title}>Página Não Encontrada</h1>
        <p className={NotFoundStyles.message}>
          Não conseguimos encontrar a página que você estava buscando.
        </p>
        <div className={NotFoundStyles.linkContainer}>
          <Link to="/" className={NotFoundStyles.link}>
            Voltar para a página Inicial
          </Link>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
