import useAxios from '../../hooks/useAxios';
import useForm from '../../hooks/useForm';
import { useSelector } from 'react-redux';

import { CREATE_EVENT } from '../../Api';

import PageTitle from '../../utils/PageTitle/PageTitle';
import BackLink from '../../utils/BackLink/BackLink';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';
import SuccessNotification from '../../utils/SuccessNotification/SuccessNotification';
import FormButton from '../../components/Buttons/FormButton';
import Input from '../../components/Forms/Input';
import DateInput from '../../components/Forms/DateInput';
import TextArea from '../../components/Forms/TextArea';
import MultipleSelectInput from '../../components/Forms/MultipleSelectInput';
import TagsInput from '../../components/Forms/TagsInput';
import Menu from '../../components/Menu/Menu';

import GlobalStyles from '../../GlobalStyles';
import { useEffect } from 'react';

const EventCreate = () => {
  const token = useSelector((state) => state.auth.token);
  const { data, error, loading, execute, setData } = useAxios(CREATE_EVENT);

  const title = useForm();
  const description = useForm();
  const date = useForm();
  const local = useForm();
  const masonicDegree = useForm('array');
  const tags = useForm('array');

  const options = ['Aprendiz', 'Companheiro', 'Mestre'];

  useEffect(() => {
    local.setValue('Loja Maçônica Areópago de Brasília, n° 3001');
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    await execute(token, {
      titulo: title.value,
      data: date.value,
      descricao: description.value,
      local: local.value,
      grauMaconico: masonicDegree.value,
      tags: tags.value,
    });

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page="/eventos" />
      <PageTitle>Criar Evento</PageTitle>
      {data && <SuccessNotification>{data.message}</SuccessNotification>}
      {error && <ErrorNotification>{error}</ErrorNotification>}
      <form onSubmit={handleSubmit}>
        <Input
          type="text"
          name="titulo"
          label="Título do Evento"
          required={true}
          placeholder="insira o título do evento"
          {...title}
        />
        <DateInput
          type="datetime-local"
          label="Data do Evento"
          name="data"
          required={true}
          {...date}
        />
        <Input
          type="text"
          name="local"
          label="Local do Evento"
          required={true}
          placeholder="Insira o local do evento"
          {...local}
        />
        <TextArea
          label="Descrição do Evento"
          name="descricao"
          placeholder="insira a descrição"
          maxLength={300}
          {...description}
        />

        <MultipleSelectInput
          name="grauMaconico"
          label="Grau Maçônico"
          required={true}
          options={options}
          {...masonicDegree}
        />

        <TagsInput
          label="Tags"
          name="tags"
          placeholder="tags separadas por vírgula"
          {...tags}
        />

        <FormButton disabled={loading}>
          {loading ? 'Criando...' : 'Criar Evento'}
        </FormButton>
      </form>
      <Menu />
    </section>
  );
};

export default EventCreate;
