import FileInputStyles from './FileInputStyles';

const FileInput = ({ label, name, onChange, accept }) => {
  return (
    <div className={FileInputStyles.wrapper}>
      <label className={FileInputStyles.label} htmlFor={name}>
        {label}
      </label>
      <input
        className={FileInputStyles.input}
        name={name}
        onChange={onChange}
        type="file"
        accept={accept}
      ></input>
    </div>
  );
};

export default FileInput;
