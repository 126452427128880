import { ReactComponent as SearchIcon } from '../../assets/search.svg';

import SearchInputStyles from './SearchInputStyles';

const SearchInput = ({ value, onChange, placeholder }) => {
  return (
    <div className={SearchInputStyles.wrapper}>
      <input
        type="text"
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        className={SearchInputStyles.input}
      />
      <div className={SearchInputStyles.icon}>
        <SearchIcon />
      </div>
    </div>
  );
};

export default SearchInput;
