const NotFoundStyles = {
  container:
    'grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8',
  textCenter: 'text-center',
  logoContainer: 'flex items-center justify-center mb-8',
  statusCode: 'text-base font-semibold text-blue-600',
  title: 'mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl',
  message: 'mt-6 text-base leading-7 text-gray-600',
  linkContainer: 'mt-10 flex items-center justify-center gap-x-6',
  link: 'rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600',
};

export default NotFoundStyles;
