import { useNavigate, useParams } from 'react-router-dom';

import QRCode from 'react-qr-code';

import BackLink from '../../utils/BackLink/BackLink';
import Menu from '../../components/Menu/Menu';
import PageTitle from '../../utils/PageTitle/PageTitle';

import GlobalStyles from '../../GlobalStyles';
import CalendarStyles from './CalendarStyles';

const EventQRCode = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page={`/evento/${eventId}`} />
      <PageTitle>Evento - QR Code</PageTitle>
      <QRCode
        value={`https://areopago.na.tec.br/presencas/confirmar/${eventId}`}
        className={CalendarStyles.qrCode}
        onClick={() => navigate(`/presencas/confirmar/${eventId}`)}
      />
      <h2 className={CalendarStyles.qrCodeTitle}>
        No Dia da Reunião, Leia o QR Code Acima Para Confirmar Presença
      </h2>
      <Menu />
    </section>
  );
};

export default EventQRCode;
