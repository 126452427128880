import { Route } from 'react-router-dom';

import Finances from '../views/Finances/Finances';
import FinancesDonation from '../views/Finances/FinancesDonation';
import Invoices from '../views/Finances/Invoices';
import InvoiceRecurrent from '../views/Finances/InvoiceRecurent';
import MyInvoices from '../views/Finances/MyInvoices';
import InvoiceSingle from '../views/Finances/InvoiceSingle';
import AllInvoices from '../views/Finances/AllInvoices';
import MemberInvoice from '../views/Finances/MemberInvoice';

const HomeRoutes = () => {
  return [
    <Route key="financeiro" path="/financeiro" element={<Finances />} />,
    <Route
      key="tronco"
      path="/financeiro/tronco"
      element={<FinancesDonation />}
    />,
    <Route key="faturas" path="/financeiro/faturas" element={<Invoices />} />,
    <Route
      key="faturas-recorrente"
      path="/financeiro/faturas/cadastrar-recorrente"
      element={<InvoiceRecurrent />}
    />,
    <Route
      key="minhas-faturas"
      path="/financeiro/faturas/minhas-faturas"
      element={<MyInvoices />}
    />,
    <Route
      key="faturas-unicas"
      path="/financeiro/faturas/cadastrar-unica"
      element={<InvoiceSingle />}
    />,
    <Route
      key="todas-faturas"
      path="/financeiro/faturas/todas"
      element={<AllInvoices />}
    />,
    <Route
      key="membro-faturas"
      path="/faturas/:cim"
      element={<MemberInvoice />}
    />,
  ];
};

export default HomeRoutes;
