import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useAxios from '../../hooks/useAxios';
import useForm from '../../hooks/useForm';

import {
  GET_FAMILY_MEMBERS,
  EDIT_FAMILY_MEMBER,
  DELETE_FAMILY_MEMBER,
} from '../../Api';

import Input from '../../components/Forms/Input';
import WarningModal from '../../utils/WarningModal/WarningModal';
import SuccessAlert from '../../utils/SuccessNotification/SuccessNotification';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';
import PageTitle from '../../utils/PageTitle/PageTitle';
import Menu from '../../components/Menu/Menu';
import BackLink from '../../utils/BackLink/BackLink';
import SelectInput from '../../components/Forms/SelectInput';

import FamilyStyles from './FamilyStyles';
import GlobalStyles from '../../GlobalStyles';
import FormButton from '../../components/Buttons/FormButton';
import RedButton from '../../components/Buttons/RedButton';
import Loader from '../../utils/Loader/Loader';

const FamilyEdit = () => {
  const token = useSelector((state) => state.auth.token);
  const { cim, familyId } = useParams();
  const navigate = useNavigate();

  const {
    data: dataGet,
    error: errorGet,
    loading: loadingGet,
    execute: executeGet,
  } = useAxios(GET_FAMILY_MEMBERS);

  const {
    data: dataEdit,
    error: errorEdit,
    loading: loadingEdit,
    execute: executeEdit,
  } = useAxios(EDIT_FAMILY_MEMBER);

  const {
    error: errorDelete,
    loading: loadingDelete,
    execute: executeDelete,
  } = useAxios(DELETE_FAMILY_MEMBER);

  useEffect(() => {
    executeGet(token, cim, `_id=${familyId}`);
  }, [cim, familyId]);

  const nome = useForm();
  const email = useForm('email');
  const telefone = useForm('telephone');
  const parentesco = useForm();

  useEffect(() => {
    if (dataGet) {
      nome.setValue(dataGet[0].nome || '');
      email.setValue(dataGet[0].email || '');
      telefone.setValue(dataGet[0].telefone || '');
      parentesco.setValue(dataGet[0].parentesco || '');
    }
  }, [dataGet]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const isNomeValid = nome.validate();
    const isEmailValid = email.validate();
    const isTelefoneValid = telefone.validate();
    const isParentescoValid = parentesco.validate();

    if (isNomeValid && isEmailValid && isTelefoneValid && isParentescoValid) {
      await executeEdit(token, cim, familyId, {
        nome: nome.value,
        email: email.value,
        telefone: telefone.value,
        parentesco: parentesco.value,
      });
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleDelete = () => {
    executeDelete(token, cim, familyId);
    setModalIsOpen(false);
    navigate(`/familiares/${cim}`);
  };

  const formFields = [
    {
      label: 'Nome',
      type: 'text',
      placeholder: 'insira o nome do membro',
      name: 'nome',
      required: true,
      hook: nome,
    },
    {
      label: 'Email',
      type: 'email',
      placeholder: 'insira o email do familiar',
      name: 'email',
      required: false,
      hook: email,
    },
    {
      label: 'Telefone',
      type: 'text',
      placeholder: 'insira o telefone do familiar',
      name: 'telefone',
      required: false,
      hook: telefone,
    },
  ];

  const options = [
    'Pai',
    'Mãe',
    'Irmão',
    'Irmã',
    'Filho',
    'Filha',
    'Tio',
    'Tia',
    'Primo',
    'Prima',
    'Avô',
    'Avó',
  ];

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page={`/familiares/${cim}`} />
      <PageTitle>Editar Familiar</PageTitle>
      {errorGet && <ErrorNotification>{errorGet}</ErrorNotification>}
      {errorEdit && <ErrorNotification>{errorEdit}</ErrorNotification>}
      {errorDelete && <ErrorNotification>{errorDelete}</ErrorNotification>}
      {dataEdit && <SuccessAlert>{dataEdit.message}</SuccessAlert>}
      {loadingGet ? (
        <Loader />
      ) : (
        dataGet && (
          <>
            <form onSubmit={handleSubmit}>
              {formFields.map((field, index) => (
                <Input
                  key={index}
                  label={field.label}
                  type={field.type}
                  placeholder={field.placeholder}
                  name={field.name}
                  required={field.required}
                  {...field.hook}
                />
              ))}
              <SelectInput
                label="Parentesco"
                required={true}
                options={options}
                {...parentesco}
              />
              <div className={FamilyStyles.buttonContainer}>
                <FormButton disabled={loadingEdit}>
                  {loadingEdit ? 'Editando...' : 'Editar'}
                </FormButton>
                <RedButton onClick={handleOpenModal}>
                  {loadingDelete ? 'Deletando...' : 'Deletar'}
                </RedButton>
              </div>
            </form>
          </>
        )
      )}
      {modalIsOpen && (
        <WarningModal
          title="Deletar Familiar"
          text="Deseja deletar permanentemente esse familiar?"
          actionName="Deletar"
          modalAction={handleDelete}
          closeModal={handleCloseModal}
        />
      )}
      <Menu />
    </section>
  );
};

export default FamilyEdit;
