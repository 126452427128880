import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useAxios from '../../hooks/useAxios';
import useForm from '../../hooks/useForm';

import { CREATE_FAMILY_MEMBER } from '../../Api';

import PageTitle from '../../utils/PageTitle/PageTitle';
import BackLink from '../../utils/BackLink/BackLink';
import FormButton from '../../components/Buttons/FormButton';
import Menu from '../../components/Menu/Menu';
import Input from '../../components/Forms/Input';
import SelectInput from '../../components/Forms/SelectInput';

import SuccessNotification from '../../utils/SuccessNotification/SuccessNotification';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';

import GlobalStyles from '../../GlobalStyles';

const FamilyCreate = () => {
  const { data, error, loading, execute } = useAxios(CREATE_FAMILY_MEMBER);

  const token = useSelector((state) => state.auth.token);
  const { cim } = useParams();

  const name = useForm();
  const email = useForm('email');
  const telephone = useForm('telephone');
  const family = useForm();

  const formFields = [
    {
      label: 'Nome',
      type: 'text',
      placeholder: 'insira o nome do membro',
      name: 'nome',
      required: true,
      hook: name,
    },
    {
      label: 'Email',
      type: 'email',
      placeholder: 'insira o email do familiar',
      name: 'email',
      required: false,
      hook: email,
    },
    {
      label: 'Telefone',
      type: 'text',
      placeholder: 'insira o telefone do familiar',
      name: 'telefone',
      required: false,
      hook: telephone,
    },
  ];

  const options = [
    'Pai',
    'Mãe',
    'Irmão',
    'Irmã',
    'Filho',
    'Filha',
    'Tio',
    'Tia',
    'Primo',
    'Prima',
    'Avô',
    'Avó',
  ];

  const handleSubmit = async (event) => {
    event.preventDefault();
    await execute(token, cim, {
      email: email.value,
      nome: name.value,
      parentesco: family.value,
      telefone: telephone.value,
    });

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page={`/familiares/${cim}`} />
      <PageTitle>Criar Familiar</PageTitle>
      {data && <SuccessNotification>{data.message}</SuccessNotification>}
      {error && <ErrorNotification>{error}</ErrorNotification>}
      <form onSubmit={handleSubmit}>
        {formFields.map((field, index) => (
          <Input
            key={index}
            label={field.label}
            type={field.type}
            placeholder={field.placeholder}
            name={field.name}
            required={field.required}
            {...field.hook}
          />
        ))}
        <SelectInput
          label="Parentesco"
          required={true}
          options={options}
          {...family}
        />
        <FormButton disabled={loading}>
          {loading ? 'Carregando...' : 'Adicionar'}
        </FormButton>
      </form>
      <Menu />
    </section>
  );
};

export default FamilyCreate;
