import { useNavigate } from 'react-router-dom';
import { ReactComponent as PencilIcon } from '../../assets/pencil.svg';
import FamilyCardStyles from './FamilyCardStyles';
import { useSelector } from 'react-redux';

const FamilyCard = ({ nome, parentesco, email, telefone, edit }) => {
  const navigate = useNavigate();

  const handleEditClick = () => {
    navigate(edit);
  };

  const user = useSelector((state) => state.auth.user);

  return (
    <div className={FamilyCardStyles.cardContainer}>
      <ul>
        <li>
          Nome: <span className="text-gray-600">{nome}</span>
        </li>
        <li>
          Parentesco: <span className="text-gray-600">{parentesco}</span>
        </li>
        <li>
          Email: <span className="text-gray-600">{email}</span>
        </li>
        <li>
          Telefone: <span className="text-gray-600">{telefone}</span>
        </li>
      </ul>

      <div className={FamilyCardStyles.iconContainer}>
        {user.cargo === 'Venerável' && (
          <PencilIcon
            className={FamilyCardStyles.pencilIcon}
            onClick={handleEditClick}
          />
        )}
      </div>
    </div>
  );
};

export default FamilyCard;
