import moment from 'moment';
import 'moment/locale/pt-br';

import ICALButtonStyles from './ICALButtonStyles';

moment.locale('pt-br');

const ICALButton = ({ events }) => {
  const handleDownloadIcal = () => {
    const icalData = convertJsonToIcal(events);
    const blob = new Blob([icalData], { type: 'text/calendar' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'events.ical';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function convertJsonToIcal(events) {
    let icalData =
      'BEGIN:VCALENDAR\nVERSION:2.0\nPRODID:-//Areópago DF//App//pt-BR\n';

    events.forEach((event) => {
      const start = moment(event.data)
        .add(3, 'hours')
        .format('YYYYMMDDTHHmmss');
      const end = moment(event.data).add(5, 'hours').format('YYYYMMDDTHHmmss');

      icalData += 'BEGIN:VEVENT\n';
      icalData += `SUMMARY:${event.titulo || 'Sem título'}\n`;
      icalData += `DTSTART;TZID=America/Sao_Paulo:${start}\n`;
      icalData += `DTEND;TZID=America/Sao_Paulo:${end}\n`;
      icalData += `DESCRIPTION:${event.descricao || 'Sem descrição'}\n`;
      icalData += `LOCATION:${event.local || 'Sem descrição'}\n`;
      icalData += 'END:VEVENT\n';
    });

    icalData += 'END:VCALENDAR';
    return icalData;
  }

  return (
    <button onClick={handleDownloadIcal} className={ICALButtonStyles.button}>
      Exportar
    </button>
  );
};

export default ICALButton;
