import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useAxios from '../../hooks/useAxios';
import useForm from '../../hooks/useForm';
import moment from 'moment';
import 'moment/locale/pt-br';

import { GET_EVENTS, EDIT_EVENT, DELETE_EVENT } from '../../Api';

import PageTitle from '../../utils/PageTitle/PageTitle';
import BackLink from '../../utils/BackLink/BackLink';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';
import SuccessNotification from '../../utils/SuccessNotification/SuccessNotification';
import FormButton from '../../components/Buttons/FormButton';
import RedButton from '../../components/Buttons/RedButton';
import WarningModal from '../../utils/WarningModal/WarningModal';
import Input from '../../components/Forms/Input';
import DateInput from '../../components/Forms/DateInput';
import TextArea from '../../components/Forms/TextArea';
import MultipleSelectInput from '../../components/Forms/MultipleSelectInput';
import Loader from '../../utils/Loader/Loader';
import Menu from '../../components/Menu/Menu';
import TagsInput from '../../components/Forms/TagsInput';

import CalendarStyles from './CalendarStyles';
import GlobalStyles from '../../GlobalStyles';
import SelectInput from '../../components/Forms/SelectInput';

moment.locale('pt-br');

const EventEdit = () => {
  const token = useSelector((state) => state.auth.token);
  const { eventId } = useParams();
  const navigate = useNavigate();

  const {
    data: dataGet,
    error: errorGet,
    loading: loadingGet,
    execute: executeGet,
  } = useAxios(GET_EVENTS);

  const {
    data: dataEdit,
    error: errorEdit,
    loading: loadingEdit,
    execute: executeEdit,
  } = useAxios(EDIT_EVENT);

  const {
    error: errorDelete,
    loading: loadingDelete,
    execute: executeDelete,
  } = useAxios(DELETE_EVENT);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const title = useForm();
  const description = useForm();
  const date = useForm();
  const local = useForm();
  const masonicDegree = useForm('array');
  const tags = useForm('array');
  const financeStatus = useForm();

  const options = ['Aprendiz', 'Companheiro', 'Mestre'];

  useEffect(() => {
    executeGet(token, `_id=${eventId}`);
  }, [eventId, token, executeGet]);

  const eventData = dataGet?.length > 0 ? dataGet[0] : null;

  const formatDate = (date) =>
    moment.utc(date).local('pt-br').format('YYYY-MM-DD HH:mm');

  useEffect(() => {
    if (eventData) {
      title.setValue(eventData.titulo);
      date.setValue(formatDate(eventData.data));
      description.setValue(eventData.descricao);
      local.setValue(eventData.local);
      masonicDegree.setValue(eventData.grauMaconico);
      tags.setValue(eventData.tags);
    }
  }, [eventData]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const isFinanceOpen =
      financeStatus.value === 'Abrir Doações' ? true : false;
    await executeEdit(token, eventId, {
      titulo: title.value,
      data: date.value,
      descricao: description.value,
      local: local.value,
      grauMaconico: masonicDegree.value,
      tags: tags.value,
      financeStatus: isFinanceOpen,
    });

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleOpenModal = () => setModalIsOpen(true);
  const handleCloseModal = () => setModalIsOpen(false);

  const handleDelete = () => {
    executeDelete(token, eventId);
    setModalIsOpen(false);
    navigate(`/eventos`);
  };

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page={`/evento/${eventId}`} />
      <PageTitle>Editar Evento</PageTitle>

      {errorGet && <ErrorNotification>{errorGet}</ErrorNotification>}
      {errorEdit && <ErrorNotification>{errorEdit}</ErrorNotification>}
      {errorDelete && <ErrorNotification>{errorDelete}</ErrorNotification>}

      {dataEdit && (
        <SuccessNotification>{dataEdit.message}</SuccessNotification>
      )}
      {loadingGet && <Loader />}

      {!loadingGet && eventData && (
        <form onSubmit={handleSubmit}>
          <Input
            type="text"
            name="titulo"
            label="Título do Evento"
            required={true}
            placeholder="insira o título do evento"
            {...title}
          />
          <DateInput
            type="datetime-local"
            label="Data do Evento"
            name="data"
            required={true}
            {...date}
          />
          <Input
            type="text"
            name="local"
            label="Local do Evento"
            required={true}
            placeholder="Insira o local do evento"
            {...local}
          />

          <TextArea
            label="Descrição do Evento"
            name="descricao"
            placeholder="insira a descrição"
            maxLength={300}
            {...description}
          />
          <MultipleSelectInput
            name="grauMaconico"
            label="Grau Maçônico"
            required={true}
            options={options}
            {...masonicDegree}
          />
          <TagsInput
            label="Tags"
            name="tags"
            placeholder="tags separadas por vírgula"
            {...tags}
          />
          <SelectInput
            name="Doações"
            label="Definir Status das Doações"
            options={['Abrir Doações', 'Fechar Doações']}
            {...financeStatus}
          />
          <div className={CalendarStyles.buttonContainer}>
            <FormButton disabled={loadingEdit}>
              {loadingEdit ? 'Editando...' : 'Editar'}
            </FormButton>
            <RedButton onClick={handleOpenModal} disabled={loadingDelete}>
              {loadingDelete ? 'Deletando...' : 'Deletar'}
            </RedButton>
          </div>
        </form>
      )}

      {modalIsOpen && (
        <WarningModal
          title="Deletar membro"
          text="Deseja deletar permanentemente esse evento?"
          actionName="Deletar"
          modalAction={handleDelete}
          closeModal={handleCloseModal}
        />
      )}
      <Menu />
    </section>
  );
};

export default EventEdit;
