import PageTitle from '../../utils/PageTitle/PageTitle';
import BackLink from '../../utils/BackLink/BackLink';
import Menu from '../../components/Menu/Menu';

import GlobalStyles from '../../GlobalStyles';
import useAxios from '../../hooks/useAxios';
import { GET_MY_INVOICES } from '../../Api';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import Loader from '../../utils/Loader/Loader';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';
import InvoiceCard from '../../components/Cards/InvoiceCard';
import WarningNotification from '../../utils/Notification/WarningNotification';

function formatDate(dateString) {
  const [year, month, day] = dateString.split('-');
  return `${day}/${month}/${year.slice(-2)}`;
}

const MyInvoices = () => {
  const { data, error, loading, execute } = useAxios(GET_MY_INVOICES);
  const token = useSelector((state) => state.auth.token);
  const { financeId } = useSelector((state) => state.auth.user);

  useEffect(() => {
    execute(token, financeId);
  }, [token, financeId, execute]);

  const sortedData = data
    ? [...data].sort((a, b) => {
        if (a.status === 'PENDING' && b.status !== 'PENDING') return -1;
        if (a.status !== 'PENDING' && b.status === 'PENDING') return 1;
        return 0;
      })
    : [];

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page="/financeiro" />
      <PageTitle>Minhas Faturas</PageTitle>
      {loading && <Loader />}
      {sortedData.length === 0 && (
        <WarningNotification>Nenhuma fatura encontrada</WarningNotification>
      )}
      {!loading &&
        !error &&
        sortedData.length > 0 &&
        sortedData.map((invoice, index) => (
          <InvoiceCard
            key={index}
            title={invoice.description}
            value={invoice.value}
            status={invoice.status}
            link={invoice.invoiceUrl}
            date={formatDate(invoice.dueDate)}
          />
        ))}
      <Menu />
    </section>
  );
};

export default MyInvoices;
