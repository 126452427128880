import DefaultProfileIcon from '../../assets/user-profile.svg';
import VoteCardStyles from './VoteCardStyles';

const VoteCard = ({ photo, type, nome }) => {
  const voteText = () => {
    switch (type) {
      case 'sim':
        return 'Votou Sim';
      case 'nao':
        return 'Votou Não';
      case 'abst':
        return 'Absteu-se';
      default:
        return '';
    }
  };

  const voteTextStyle = () => {
    switch (type) {
      case 'sim':
        return `${VoteCardStyles.voteText} ${VoteCardStyles.voteYes}`;
      case 'nao':
        return `${VoteCardStyles.voteText} ${VoteCardStyles.voteNo}`;
      case 'abst':
        return `${VoteCardStyles.voteText} ${VoteCardStyles.voteAbst}`;
      default:
        return VoteCardStyles.voteText;
    }
  };

  return (
    <div className={VoteCardStyles.card}>
      <img
        src={photo || DefaultProfileIcon}
        alt={`Foto de um membro votante`}
        className={VoteCardStyles.voteIcon}
      />
      <p>{nome}</p>
      <p className={voteTextStyle()}>{voteText()}</p>
    </div>
  );
};

export default VoteCard;
