import InvoiceCardStyle from './InvoiceCardStyles';

const InvoiceCard = ({ title, value, status, link, date }) => {
  let displayStatus;
  switch (status) {
    case 'PENDING':
      displayStatus = 'Pendente';
      break;
    case 'CONFIRMED':
      displayStatus = 'Confirmado';
      break;
    case 'RECEIVED':
      displayStatus = 'Recebido';
      break;
    default:
      displayStatus = status;
  }

  return (
    <a href={link} target="_blank">
      <div className={InvoiceCardStyle.container}>
        <div className={InvoiceCardStyle.wrapper}>
          <h2>{title}</h2>
          <p>R$ {value}</p>
        </div>

        <div className={InvoiceCardStyle.wrapper}>
          <h2>{displayStatus}</h2>
          {status === 'PENDING' ? <p>{date}</p> : ''}
        </div>
      </div>
    </a>
  );
};

export default InvoiceCard;
