import { BrowserRouter } from 'react-router-dom';

import AppRoutes from './AppRoutes';

import GlobalStyles from './GlobalStyles';

function App() {
  return (
    <div className={GlobalStyles.appContainer}>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </div>
  );
}

export default App;
