const LoaderStyles = {
  centeredContainer: 'flex items-center justify-center',
  base: 'inline-block h-12 w-12 rounded-full align-[-0.125em] motion-reduce',
  spinner:
    'animate-spin border-4 border-solid border-current border-r-transparent',
  grow: 'animate-[spinner-grow_0.75s_linear_infinite] bg-current opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]',
  text: '!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]',
};

export default LoaderStyles;
