import { jsPDF } from 'jspdf';

import PDFButtonStyles from './PDFButtonStyles';

const PDFButton = ({ richText }) => {
  const generatePDF = () => {
    const doc = new jsPDF({
      format: 'a4',
    });

    const htmlElement = document.createElement('div');
    htmlElement.innerHTML = richText;
    htmlElement.style.width = '100%';

    htmlElement.style.fontFamily = 'Arial, sans-serif';
    htmlElement.style.color = '#000000';
    htmlElement.style.fontSize = '16px';

    const applyStylesToTags = (tagName, fontSize, lineHeight) => {
      const elements = htmlElement.getElementsByTagName(tagName);
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.fontSize = fontSize;
        elements[i].style.lineHeight = lineHeight;
      }
    };

    applyStylesToTags('h1', '28px', '1.2');
    applyStylesToTags('h2', '24px', '1.2');
    applyStylesToTags('h3', '20px', '1.2');
    applyStylesToTags('p', '16px', '1.5');

    doc.html(htmlElement, {
      callback: function (doc) {
        doc.save('converted.pdf');
      },
      x: 15,
      y: 15,
      width: 180,
      windowWidth: 1000,
    });
  };

  return (
    <button onClick={generatePDF} className={PDFButtonStyles.button}>
      Baixar
    </button>
  );
};

export default PDFButton;
