import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { login } from '../../redux/auth/authSlice';

import useAxios from '../../hooks/useAxios';
import useForm from '../../hooks/useForm';

import { MEMBER_LOGIN } from '../../Api';

import Input from '../../components/Forms/Input';
import FormButton from '../../components/Buttons/FormButton';
import PageTitle from '../../utils/PageTitle/PageTitle';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';

import { ReactComponent as Logo } from '../../assets/logo.svg';
import { ReactComponent as TriangleDarkIcon } from '../../assets/triangle-dark.svg';

import LoginStyles from './LoginStyles';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data, error, loading, execute } = useAxios(MEMBER_LOGIN);

  const cim = useForm('number');
  const password = useForm();

  const handleSubmit = (event) => {
    event.preventDefault();
    execute({ cim: cim.value, senha: password.value });
  };

  useEffect(() => {
    if (data) {
      dispatch(login(data.token));
      navigate('/');
    }
  }, [data]);

  return (
    <section>
      <div className={LoginStyles.logoContainer}>
        <Logo />
      </div>
      <PageTitle>Entrar</PageTitle>
      <form onSubmit={handleSubmit}>
        <Input
          label="CIM (Cadastro)"
          type="text"
          placeholder="insira seu cim"
          name="cim"
          required
          {...cim}
        />
        <Input
          label="Senha"
          type="password"
          placeholder="insira sua senha"
          name="senha"
          required
          {...password}
        />
        <FormButton disabled={loading}>
          {loading ? 'Carregando' : 'Login'}
        </FormButton>
      </form>
      <Link to="/redefinir-senha" className={LoginStyles.forgotPassword}>
        esqueceu sua senha?
      </Link>
      <Link to="/login-visitante" className={LoginStyles.enter}>
        <TriangleDarkIcon />
        Entrar como Visitante
      </Link>
      {error && <ErrorNotification>{error}</ErrorNotification>}
    </section>
  );
};

export default Login;
