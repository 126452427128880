const PresencesStyles = {
  cardTitle: 'font-bold text-lg',
  infoText: 'text-blue-500 font-bold',
  buttonsGrid: 'grid grid-cols-1 gap-5',
  membersGrid: 'grid grid-cols-1 gap-3 pb-10',
  presenceCount: 'text-green-500 font-bold',
  absenceCount: 'text-red-500 font-bold mb-5',
  detailsContainer: 'w-72',
  title: 'text-xl font-bold mb-3',
  titleNormal: 'font-normal text-gray-600',
  descriptionTitle: 'text-lg font-bold',
  description: 'mb-5 text-gray-600',
  buttonContainer: 'flex flex-row justify-between gap-5 items-center',
  listItem: 'mb-4',
  listTitle: 'flex flex-row gap-1 items-center text-xl font-bold',
  listIcon: 'w-5 h-5',
  listText: 'text-lg text-gray-600',
  tagContainer: 'w-72 flex flex-row gap-2 flex-wrap mt-3',
  tag: 'flex flex-row items-center  justify-center px-2 py-1 bg-blue-500 text-white rounded-full',
};

export default PresencesStyles;
