import { useState } from 'react';
import VoteCard from '../Cards/VoteCard';
import Pagination from '../../utils/Pagination/Pagination';

const VotingList = ({ dataVote }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const votesPerPage = 4;

  const combinedVotes = Object.entries(dataVote).reduce(
    (acc, [type, votes]) =>
      acc.concat(votes.map((vote) => ({ ...vote, type }))),
    [],
  );

  const indexOfLastVote = currentPage * votesPerPage;
  const indexOfFirstVote = indexOfLastVote - votesPerPage;
  const currentVotes = combinedVotes.slice(indexOfFirstVote, indexOfLastVote);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      {currentVotes.map((vote, index) => (
        <VoteCard
          key={index}
          photo={vote.foto}
          type={vote.type}
          nome={vote.nome}
        />
      ))}

      <Pagination
        itemsPerPage={votesPerPage}
        totalItems={combinedVotes.length}
        paginate={paginate}
        currentPage={currentPage}
      />
    </div>
  );
};

export default VotingList;
