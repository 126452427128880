import { Route } from 'react-router-dom';
import Login from '../views/Login/Login';
import LoginAsGuest from '../views/Login/LoginAsGuest';
import ResetPassword from '../views/Login/ResetPassword';
import NewPassword from '../views/Login/NewPassword';

const AuthRoutes = () => {
  return [
    <Route key="login" path="/login" element={<Login />} />,
    <Route
      key="login-visitante"
      path="/login-visitante"
      element={<LoginAsGuest />}
    />,
    <Route
      key="redefinir-senha"
      path="/redefinir-senha"
      element={<ResetPassword />}
    />,
    <Route
      key="nova-senha"
      path="/nova-senha/:newPasswordToken"
      element={<NewPassword />}
    />,
  ];
};

export default AuthRoutes;
