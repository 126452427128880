import { useSelector } from 'react-redux';
import useAxios from '../../hooks/useAxios';
import useForm from '../../hooks/useForm';

import { CREATE_MEMBER } from '../../Api';

import PageTitle from '../../utils/PageTitle/PageTitle';
import BackLink from '../../utils/BackLink/BackLink';
import FormButton from '../../components/Buttons/FormButton';
import Input from '../../components/Forms/Input';
import DateInput from '../../components/Forms/DateInput';
import Menu from '../../components/Menu/Menu';
import SelectInput from '../../components/Forms/SelectInput';

import SuccessNotification from '../../utils/SuccessNotification/SuccessNotification';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';

import GlobalStyles from '../../GlobalStyles';

const Members = () => {
  const { data, error, loading, execute } = useAxios(CREATE_MEMBER);

  const token = useSelector((state) => state.auth.token);

  const cim = useForm('number');
  const name = useForm();
  const email = useForm('email');
  const birthday = useForm();
  const cpf = useForm('cpf');
  const masonicDegree = useForm('');

  const formFields = [
    {
      label: 'CIM (Cadastro)',
      type: 'text',
      placeholder: 'insira o cim do membro',
      name: 'cim',
      required: true,
      hook: cim,
    },
    {
      name: 'cpf',
      type: 'text',
      label: 'CPF',
      placeholder: 'insira o cpf',
      hook: cpf,
    },
    {
      label: 'Nome',
      type: 'text',
      placeholder: 'insira o nome do membro',
      name: 'nome',
      required: true,
      hook: name,
    },
    {
      label: 'Email',
      type: 'email',
      placeholder: 'insira o email do membro',
      name: 'email',
      required: true,
      hook: email,
    },
  ];

  const options = ['Aprendiz', 'Companheiro', 'Mestre'];

  const handleSubmit = async (event) => {
    event.preventDefault();
    await execute(token, {
      cim: cim.value,
      cpf: cpf.value,
      nome: name.value,
      email: email.value,
      grauMaconico: masonicDegree.value,
      dataNascimento: birthday.value,
    });

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page="/membros" />
      <PageTitle>Criar Membro</PageTitle>
      {data && <SuccessNotification>{data.message}</SuccessNotification>}
      {error && <ErrorNotification>{error}</ErrorNotification>}
      <form onSubmit={handleSubmit}>
        {formFields.map((field, index) => (
          <Input
            key={index}
            label={field.label}
            type={field.type}
            placeholder={field.placeholder}
            name={field.name}
            required={field.required}
            {...field.hook}
          />
        ))}
        <SelectInput
          label="Grau Maçônico"
          required={true}
          options={options}
          {...masonicDegree}
        />
        <DateInput
          label="Data de Nascimento"
          name="dataNascimento"
          required={true}
          {...birthday}
        />
        <FormButton disabled={loading}>
          {loading ? 'Carregando...' : 'Adicionar'}
        </FormButton>
      </form>
      <Menu />
    </section>
  );
};

export default Members;
