import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useForm from '../../hooks/useForm';
import { useEffect, lazy, Suspense, useState } from 'react';
import useAxios from '../../hooks/useAxios';

import { GET_MEMBERS } from '../../Api';

import BackLink from '../../utils/BackLink/BackLink';
import PageTitle from '../../utils/PageTitle/PageTitle';
import Pagination from '../../utils/Pagination/Pagination';
import Loader from '../../utils/Loader/Loader';
import SearchInput from '../../components/Forms/SearchInput';
import Menu from '../../components/Menu/Menu';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';

import GlobalStyles from '../../GlobalStyles';
import PresencesStyles from './PresencesStyles';

const MemberCard = lazy(() => import('../../components/Cards/MemberCard'));

const FrequencySearch = () => {
  const { data, error, execute } = useAxios(GET_MEMBERS);

  const token = useSelector((state) => state.auth.token);

  const searchTerm = useForm();

  useEffect(() => {
    execute(token);
  }, [token, execute]);

  useEffect(() => {
    execute(token, `nome=${searchTerm.value}`);
    setCurrentPage(1);
  }, [searchTerm.value, execute, token]);

  const [currentPage, setCurrentPage] = useState(1);

  const membersPerPage = 5;

  const indexOfLastMember = currentPage * membersPerPage;
  const indexOfFirstMember = indexOfLastMember - membersPerPage;

  const currentMembers = data
    ? data.slice(indexOfFirstMember, indexOfLastMember)
    : [];

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const navigate = useNavigate();

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page="/presencas" />
      <PageTitle>Consultar Frequência</PageTitle>
      <SearchInput placeholder="buscar membros" {...searchTerm} />
      <div className={PresencesStyles.membersGrid}>
        <Suspense fallback={<Loader />}>
          {!error &&
            currentMembers?.map((membro) => (
              <MemberCard
                key={membro._id}
                name={membro.nome}
                photo={membro.foto}
                page={`/frequencia/membro/${membro.cim}`}
              >
                {'Frequência >'}
              </MemberCard>
            ))}
        </Suspense>
        {error && <ErrorNotification>{error}</ErrorNotification>}
      </div>

      <Pagination
        itemsPerPage={membersPerPage}
        totalItems={data?.length}
        paginate={paginate}
        currentPage={currentPage}
      />
      <Menu />
    </section>
  );
};

export default FrequencySearch;
