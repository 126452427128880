import { useNavigate, useParams } from 'react-router-dom';

import QRCode from 'react-qr-code';

import BackLink from '../../utils/BackLink/BackLink';
import Menu from '../../components/Menu/Menu';
import PageTitle from '../../utils/PageTitle/PageTitle';

import GlobalStyles from '../../GlobalStyles';
import CalendarStyles from './CalendarStyles';
import Loader from '../../utils/Loader/Loader';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';
import { GET_EVENTS } from '../../Api';
import useAxios from '../../hooks/useAxios';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import SuccessNotification from '../../utils/SuccessNotification/SuccessNotification';

const DonationsEvents = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [successPayload, setSuccessPayload] = useState(null);
  const [errorPayload, setErrorPayload] = useState(null);
  const token = useSelector((state) => state.auth.token);

  const { data, error, loading, execute } = useAxios(GET_EVENTS);

  const eventData = data?.length > 0 ? data[0] : null;

  useEffect(() => {
    execute(token, `_id=${eventId}`);
  }, [token, eventId, execute]);

  const copyPixPayloadToClipboard = () => {
    navigator.clipboard
      .writeText(eventData?.qrCodePayload)
      .then(() => {
        setErrorPayload(null);
        setSuccessPayload('Código PIX copiado para a área de transferência!');
      })
      .catch((err) => {
        setSuccessPayload(null);
        setErrorPayload(`Falha ao copiar: ${err}`);
      });
  };

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page={`/evento/${eventId}`} />
      <PageTitle>Evento - Tronco</PageTitle>
      {loading && <Loader />}
      {error && <ErrorNotification>{error}</ErrorNotification>}
      {eventData && eventData.financeStatus && (
        <div>
          <div>
            <img src={`data:image/png;base64, ${eventData?.qrCodeImage}`} />
          </div>
          <h2 className={CalendarStyles.qrCodeTitle}>
            Leia o QR-Code Acima para realizar uma doação
          </h2>
          <p
            onClick={copyPixPayloadToClipboard}
            className={CalendarStyles.copyPixLink}
          >
            Clique aqui para copiar o código PIX
          </p>
          <h2 className={CalendarStyles.qrCodeTitle}>
            Total Arrecadado: {`R$ ${eventData?.totalRaised ?? '00,00'}`}
          </h2>
        </div>
      )}
      {eventData?.financeStatus === false && (
        <div>
          <h2 className={CalendarStyles.qrCodeTitle}>
            As doações para este evento estão fechadas.
          </h2>
          <h2 className={CalendarStyles.qrCodeTitle}>
            Total Arrecadado: {`R$ ${eventData?.totalRaised ?? '00,00'}`}
          </h2>
        </div>
      )}
      {successPayload && (
        <SuccessNotification>{successPayload}</SuccessNotification>
      )}
      {errorPayload && <ErrorNotification>{errorPayload}</ErrorNotification>}
      <Menu />
    </section>
  );
};

export default DonationsEvents;
