import { Route } from 'react-router-dom';

import Presences from '../views/Presences/Presences';
import ConfirmPresence from '../views/Presences/ConfirmPresence';
import Frequency from '../views/Presences/Frequency';
import InformPresence from '../views/Presences/InformPresence';
import FrequencySearch from '../views/Presences/FrequencySearch';
import PresencesEventSearch from '../views/Presences/PresencesEventsSearch';
import EventStatus from '../views/Presences/EventStatus';

const PresencesRoutes = () => {
  return [
    <Route key="presencas" path="/presencas" element={<Presences />} />,
    <Route
      key="confirmar-presenca"
      path="/presencas/confirmar/:eventId"
      element={<ConfirmPresence />}
    />,
    <Route
      key="frequencia"
      path="/frequencia/membro/:cim"
      element={<Frequency />}
    />,
    <Route
      key="informar-presenca"
      path="/presencas/informar-presenca"
      element={<InformPresence />}
    />,
    <Route
      key="consultar-frequencia"
      path="/frequencia/consultar"
      element={<FrequencySearch />}
    />,
    <Route
      key="consultar-eventos"
      path="/presencas/buscar-eventos"
      element={<PresencesEventSearch />}
    />,
    <Route
      key="eventos-status"
      path="/evento/status/:eventId"
      element={<EventStatus />}
    />,
  ];
};

export default PresencesRoutes;
