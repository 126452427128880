import BackLink from '../../utils/BackLink/BackLink';
import PageTitle from '../../utils/PageTitle/PageTitle';
import PageButton from '../../components/Buttons/PageButton';
import Menu from '../../components/Menu/Menu';

import VotingStyles from './VotingStyles';
import GlobalStyles from '../../GlobalStyles';
import { useSelector } from 'react-redux';

const pagesButtons = [
  {
    text: 'Consultar Votações',
    page: '/votacao/consultar-votacao',
    disabled: false,
  },
];

const Voting = () => {
  const user = useSelector((state) => state.auth.user);

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page="/" />
      <PageTitle>Votação de Pautas</PageTitle>
      <div className={VotingStyles.buttonsGrid}>
        {(user.cargo === 'Tesoureiro' || user.cargo === 'Venerável') && (
          <PageButton page="/votacao/criar-votacao" disabled={false}>
            Cadastrar Votação
          </PageButton>
        )}
        {pagesButtons.map((button, index) => (
          <PageButton key={index} page={button.page} disabled={button.disabled}>
            {button.text}
          </PageButton>
        ))}
      </div>
      <Menu />
    </section>
  );
};

export default Voting;
