import React, { Suspense, lazy, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import io from 'socket.io-client';

import useAxios from '../../hooks/useAxios';
import { SERVER_URL, GET_GROUP_MESSAGES, GET_GROUPS } from '../../Api';

import BackLink from '../../utils/BackLink/BackLink';
import PageTitle from '../../utils/PageTitle/PageTitle';
import ChatInput from '../../components/Forms/ChatInput';
import Loader from '../../utils/Loader/Loader';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';

import defaultGroupPic from '../../assets/group.svg';

import GlobalStyles from '../../GlobalStyles';
import ChatStyles from './ChatStyles';

const GroupChatBubble = lazy(() =>
  import('../../components/Chat/GroupChatBubble'),
);

const GroupChat = () => {
  const token = useSelector((state) => state.auth.token);
  const { cim, nome } = useSelector((state) => state.auth.user);
  const { chatId } = useParams();

  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [socket, setSocket] = useState(null);
  const navigate = useNavigate();

  const { data: groupData, error, loading, execute } = useAxios(GET_GROUPS);
  const { execute: fetchGroupMessages } = useAxios(GET_GROUP_MESSAGES);

  useEffect(() => {
    execute(token, `memberCim=${cim}&_id=${chatId}`);
    fetchGroupMessages(token, chatId).then((response) => {
      if (response.data) {
        setMessages(response.data);
      }
    });
  }, [token, chatId, cim, execute, fetchGroupMessages]);

  useEffect(() => {
    const newSocket = io(SERVER_URL, { auth: { token } });
    setSocket(newSocket);

    newSocket.on('connect', () => {
      newSocket.emit('joinGroup', { userId: cim, chatId });
    });

    newSocket.on('groupMessage', (incomingMessage) => {
      setMessages((prevMessages) => [...prevMessages, incomingMessage]);
    });

    return () => {
      newSocket.off('connect');
      newSocket.off('groupMessage');
      newSocket.disconnect();
    };
  }, [token, chatId, cim]);

  const handleSendMessage = () => {
    if (message.trim() && socket) {
      socket.emit('sendGroupMessage', { chatId, message, userId: nome });
      setMessage('');
    }
  };

  const sendMessage = (e) => {
    e.preventDefault();
    handleSendMessage();
  };

  const messagesEndRef = useRef(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const groupDetails = groupData?.length > 0 ? groupData[0] : null;

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page="/chat/grupos" />
      {loading && <Loader />}
      {error && <ErrorNotification>{error}</ErrorNotification>}
      {groupDetails && (
        <div
          className={ChatStyles.groupMainInfo}
          onClick={() => navigate(`/chat/grupo/editar/${chatId}`)}
        >
          <img
            className={ChatStyles.memberImage}
            src={groupDetails.photo || defaultGroupPic}
            alt={`Foto do Grupo ${groupDetails.title || 'Grupo'}`}
          />
          <h2 className="text-3xl mb-4 font-bold font-sans text-slate-950">
            {groupDetails.title || 'Grupo'}
          </h2>
        </div>
      )}

      <div className="max-h-[100vh] h-[70vh] overflow-y-auto pr-1">
        <Suspense fallback={<Loader />}>
          {messages.map((msg) => (
            <GroupChatBubble
              key={msg.timeStamp}
              msg={msg}
              isOwnMessage={msg.sender === nome}
            />
          ))}
        </Suspense>
        {error && <ErrorNotification>{error}</ErrorNotification>}
        <div ref={messagesEndRef} />
      </div>

      <form
        onSubmit={sendMessage}
        className="flex justify-between items-center p-2 bg-white border-t border-gray-200"
      >
        <ChatInput
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Digite uma mensagem..."
          onIconClick={handleSendMessage}
          required
        />
      </form>
    </section>
  );
};

export default GroupChat;
