import { Route } from 'react-router-dom';

import Voting from '../views/Voting/Voting';
import VotingCreate from '../views/Voting/VotingCreate';
import VotingSearch from '../views/Voting/VotingSearch';
import VotingSingle from '../views/Voting/VotingSingle';
import Vote from '../views/Voting/Vote';
import VotingResults from '../views/Voting/VotingResults';
import VotingEdit from '../views/Voting/VotingEdit';

const VotingRouters = () => {
  return [
    <Route key="votacao" path="/votacao" element={<Voting />} />,
    <Route
      key="criar-votacao"
      path="/votacao/criar-votacao"
      element={<VotingCreate />}
    />,
    <Route
      key="consultar-votacao"
      path="/votacao/consultar-votacao"
      element={<VotingSearch />}
    />,
    <Route
      key="consultar-votacao"
      path="/votacao/consultar-votacao/:votingId"
      element={<VotingSingle />}
    />,
    <Route key="votar" path="/votacao/votar/:votingId" element={<Vote />} />,
    <Route
      key="resultados-votacao"
      path="/votacao/resultados/:votingId"
      element={<VotingResults />}
    />,
    <Route
      key="editar-votacao"
      path="/votacao/editar/:votingId"
      element={<VotingEdit />}
    />,
  ];
};

export default VotingRouters;
