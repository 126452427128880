import useForm from '../../hooks/useForm';
import useAxios from '../../hooks/useAxios';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Suspense, lazy, useEffect, useState } from 'react';

import { GET_VOTINGS } from '../../Api';

import BackLink from '../../utils/BackLink/BackLink';
import PageTitle from '../../utils/PageTitle/PageTitle';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';
import MultipleSelectInput from '../../components/Forms/MultipleSelectInput';
import TagsInput from '../../components/Forms/TagsInput';
import Loader from '../../utils/Loader/Loader';
import Pagination from '../../utils/Pagination/Pagination';
import Menu from '../../components/Menu/Menu';

import VotingStyles from './VotingStyles';
import GlobalStyles from '../../GlobalStyles';
import WarningNotification from '../../utils/Notification/WarningNotification';

const VotingCard = lazy(() => import('../../components/Cards/VotingCard'));

const VotingSearch = () => {
  const token = useSelector((state) => state.auth.token);
  const { data, error, loading, execute } = useAxios(GET_VOTINGS);
  const navigate = useNavigate();

  const masonicDegree = useForm('array');
  const tags = useForm('array');

  useEffect(() => {
    execute(token);
  }, [token, execute]);

  useEffect(() => {
    execute(token, `grauMaconico=${masonicDegree.value}&tags=${tags.value}`);
  }, [masonicDegree.value, tags.value, execute, token]);

  const options = ['Aprendiz', 'Companheiro', 'Mestre'];

  const [currentPage, setCurrentPage] = useState(1);

  const votingsPerPage = 4;

  const indexOfLastVoting = currentPage * votingsPerPage;
  const indexOfFirstVoting = indexOfLastVoting - votingsPerPage;

  const currentVotings = data
    ? data.slice(indexOfFirstVoting, indexOfLastVoting)
    : [];

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page="/votacao" />
      <PageTitle>Consultar Votações</PageTitle>
      <MultipleSelectInput
        label="Grau Maçônico"
        name="grauMaconico"
        options={options}
        {...masonicDegree}
      />
      <TagsInput
        label="Tags"
        name="tags"
        placeholder="tags separadas por vírgula"
        {...tags}
      />
      {data && <h2 className={VotingStyles.subTitle}>Votações</h2>}
      <Suspense fallback={<Loader />}>
        {!error &&
          currentVotings?.map((voting, index) => (
            <VotingCard
              key={index}
              title={voting.titulo}
              type={voting.tipoVotacao}
              onClick={() =>
                navigate(`/votacao/consultar-votacao/${voting._id}`)
              }
            />
          ))}
      </Suspense>
      {loading && <Loader />}
      {error && (
        <WarningNotification>Nenhuma Votação Encontrada</WarningNotification>
      )}
      {!error && (
        <Pagination
          itemsPerPage={votingsPerPage}
          totalItems={data?.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      )}
      <Menu />
    </section>
  );
};

export default VotingSearch;
