import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useAxios from '../../hooks/useAxios';
import { useEffect } from 'react';
import moment from 'moment';

import { GET_EVENTS, UPDATE_EVENT_STATUS } from '../../Api';

import BackLink from '../../utils/BackLink/BackLink';
import PageTitle from '../../utils/PageTitle/PageTitle';
import Loader from '../../utils/Loader/Loader';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';
import SuccessNotification from '../../utils/SuccessNotification/SuccessNotification';
import FormButton from '../../components/Buttons/FormButton';
import SelectInput from '../../components/Forms/SelectInput';
import Menu from '../../components/Menu/Menu';

import GlobalStyles from '../../GlobalStyles';
import PresencesStyles from './PresencesStyles';

import { ReactComponent as CalendarDarkIcon } from '../../assets/calendar-dark.svg';
import { ReactComponent as AddressIcon } from '../../assets/address.svg';
import useForm from '../../hooks/useForm';

const EventStatus = () => {
  const { eventId } = useParams();
  const token = useSelector((state) => state.auth.token);

  const { data, error, loading, execute } = useAxios(GET_EVENTS);
  const {
    data: dataEdit,
    error: errorEdit,
    loading: loadingEdit,
    execute: executeEdit,
  } = useAxios(UPDATE_EVENT_STATUS);
  const status = useForm();

  const eventData = data?.length > 0 ? data[0] : null;

  useEffect(() => {
    execute(token, `_id=${eventId}`);
  }, [token, eventId, execute]);

  const formatDate = (date) =>
    moment.utc(date).local('pt-br').format('DD/MM/YYYY - HH:mm');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const isOpen = status.value === 'Abrir Presenças' ? true : false;
    await executeEdit(token, eventId, { aberto: isOpen });

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page="/presencas/buscar-eventos" />
      <PageTitle>Evento</PageTitle>
      {loading && <Loader />}
      {error && <ErrorNotification>{error}</ErrorNotification>}{' '}
      {dataEdit && (
        <SuccessNotification>{dataEdit.message}</SuccessNotification>
      )}
      {errorEdit && <ErrorNotification>{errorEdit}</ErrorNotification>}
      {eventData && (
        <>
          <div className={PresencesStyles.detailsContainer}>
            <h2 className={PresencesStyles.title}>
              Título:{' '}
              <span className={PresencesStyles.titleNormal}>
                {eventData.titulo}
              </span>
            </h2>
            <h2 className={PresencesStyles.descriptionTitle}>
              Descrição do Evento:
            </h2>
            <p className={PresencesStyles.description}>{eventData.descricao}</p>

            <ul>
              <li className={PresencesStyles.listItem}>
                <h2 className={PresencesStyles.listTitle}>
                  <CalendarDarkIcon className={PresencesStyles.listIcon} /> Data
                  do Evento
                </h2>
                <p className={PresencesStyles.listText}>
                  {formatDate(eventData.data)}
                </p>
              </li>
              <li className={PresencesStyles.listItem}>
                <h2 className={PresencesStyles.listTitle}>
                  <AddressIcon className={PresencesStyles.listIcon} /> Local do
                  Evento
                </h2>
                <p className={PresencesStyles.listText}>{eventData.local}</p>
              </li>
            </ul>
            <h2 className={PresencesStyles.descriptionTitle}>Tags:</h2>
            <div className={PresencesStyles.tagContainer}>
              {eventData.tags.map((tag, index) => (
                <span key={index} className={PresencesStyles.tag}>
                  {tag}
                </span>
              ))}
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <SelectInput
              name="status"
              label="Definir Status da Reunião"
              options={['Abrir Presenças', 'Fechar Presenças']}
              {...status}
            />
            <FormButton disabled={loadingEdit}>
              {loadingEdit ? 'Carregando...' : 'Definir'}
            </FormButton>
          </form>
        </>
      )}
      <Menu />
    </section>
  );
};

export default EventStatus;
