import useAxios from '../../hooks/useAxios';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import moment from 'moment';

import { GET_RECORDS } from '../../Api';

import BackLink from '../../utils/BackLink/BackLink';
import PageTitle from '../../utils/PageTitle/PageTitle';
import Loader from '../../utils/Loader/Loader';
import Button from '../../components/Buttons/Button';
import Menu from '../../components/Menu/Menu';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';
import { ReactComponent as CalendarDarkIcon } from '../../assets/calendar-dark.svg';
import { ReactComponent as PermissionDarkIcon } from '../../assets/permissions-dark.svg';
import { ReactComponent as PencilIcon } from '../../assets/pencil.svg';

import GlobalStyles from '../../GlobalStyles';
import RecordsBookStyles from './RecordsBookStyles';
import PDFButton from '../../components/Buttons/PDFButton';

const RecordSingle = () => {
  const { data, error, loading, execute } = useAxios(GET_RECORDS);
  const token = useSelector((state) => state.auth.token);
  const { recordId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    execute(token, `_id=${recordId}`);
  }, [token, execute, recordId]);

  const recordData = data?.length > 0 ? data[0] : null;

  const formatDate = (date) =>
    moment.utc(date).local('pt-br').format('DD/MM/YYYY');

  const getVotingStatus = () => {
    if (!recordData) return '';

    const now = moment();
    const startDate = moment(recordData.dataInicio);
    const endDate = moment(recordData.dataFinal);

    if (now.isBefore(startDate)) return 'Aguardando Início';
    if (now.isAfter(endDate)) return 'Encerrada';
    return 'Em Andamento';
  };

  const votingStatus = getVotingStatus();

  const user = useSelector((state) => state.auth.user);

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page="/livro-de-atas/consultar-atas" />
      <PageTitle>Ata</PageTitle>

      {loading && <Loader />}
      {error && <ErrorNotification>{error}</ErrorNotification>}

      {recordData && (
        <div className={RecordsBookStyles.detailsContainer}>
          <div className={RecordsBookStyles.headerContainer}>
            <h2 className={RecordsBookStyles.headerTitle}>Informações</h2>
            {(user.cargo === 'Escrivão' || user.cargo === 'Venerável') && (
              <PencilIcon
                className={RecordsBookStyles.editIcon}
                onClick={() => navigate(`/livro-de-atas/editar/${recordId}`)}
              />
            )}
          </div>
          <h2 className={RecordsBookStyles.title}>
            Título:{' '}
            <span className={RecordsBookStyles.titleNormal}>
              {recordData.titulo}
            </span>
          </h2>
          <h2 className={RecordsBookStyles.descriptionTitle}>
            Download da Ata:
          </h2>
          <PDFButton richText={recordData.descricao} />

          <ul>
            <li className={RecordsBookStyles.listItem}>
              <h2 className={RecordsBookStyles.listTitle}>
                <CalendarDarkIcon className={RecordsBookStyles.listIcon} />{' '}
                Início da Votação:
              </h2>
              <p className={RecordsBookStyles.listText}>
                {formatDate(recordData.dataInicio)}
              </p>
            </li>
            <li className={RecordsBookStyles.listItem}>
              <h2 className={RecordsBookStyles.listTitle}>
                <CalendarDarkIcon className={RecordsBookStyles.listIcon} />{' '}
                Encerramento da Votação:
              </h2>
              <p className={RecordsBookStyles.listText}>
                {formatDate(recordData.dataFinal)}
              </p>
            </li>
            <li className={RecordsBookStyles.listItem}>
              <h2 className={RecordsBookStyles.listTitle}>
                <PermissionDarkIcon className={RecordsBookStyles.listIcon} />{' '}
                Status da Votação:
              </h2>
              <p className={RecordsBookStyles.listText}>{votingStatus}</p>
            </li>
            <li className={RecordsBookStyles.listItem}>
              <h2 className={RecordsBookStyles.listTitle}>Status da Ata:</h2>
              <p className={RecordsBookStyles.listText}>{recordData.status}</p>
            </li>
          </ul>
          <h2 className={RecordsBookStyles.descriptionTitle}>Tags:</h2>

          <div className={RecordsBookStyles.tagContainer}>
            {recordData.tags.map((tag, index) => (
              <span key={index} className={RecordsBookStyles.tag}>
                {tag}
              </span>
            ))}
          </div>
          <div className={RecordsBookStyles.buttonContainer}>
            <Button
              onClick={() => navigate(`/livro-de-atas/status/${recordId}`)}
            >
              Status
            </Button>

            {getVotingStatus() === 'Em Andamento' &&
              recordData.statusVotacao !== 'fechada' && (
                <Button
                  onClick={() => navigate(`/livro-de-atas/votar/${recordId}`)}
                >
                  Votar
                </Button>
              )}
          </div>
        </div>
      )}
      <Menu />
    </section>
  );
};

export default RecordSingle;
