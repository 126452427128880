import { useSelector } from 'react-redux';

import HomeGuest from './HomeGuest';
import Home from './Home';

const HomeRedirect = () => {
  const user = useSelector((state) => state.auth.user);

  if (user.cargo === 'Visitante') {
    return <HomeGuest />;
  }

  return <Home />;
};

export default HomeRedirect;
