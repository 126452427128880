import { Route } from 'react-router-dom';
import Calendar from '../views/Calendar/Calendar';
import EventCreate from '../views/Calendar/EventCreate';
import EventSingle from '../views/Calendar/EventSingle';
import EventQRCode from '../views/Calendar/EventQRCode';
import EventEdit from '../views/Calendar/EventEdit';
import Events from '../views/Calendar/Events';
import DonationsEvents from '../views/Calendar/DonationsEvent';

const CalendarRoutes = () => {
  return [
    <Route key="eventos" path="/eventos" element={<Calendar />} />,
    <Route
      key="criar-evento"
      path="/eventos/criar-evento"
      element={<EventCreate />}
    />,
    <Route key="evento" path="/evento/:eventId" element={<EventSingle />} />,
    <Route
      key="evento-qrcode"
      path="/eventos/qrcode/:eventId"
      element={<EventQRCode />}
    />,
    <Route
      key="editar-evento"
      path="/evento/editar/:eventId"
      element={<EventEdit />}
    />,
    <Route key="eventos" path="/eventos/:date" element={<Events />} />,
    <Route
      key="evento-tronco"
      path="/eventos/tronco/:eventId"
      element={<DonationsEvents />}
    />,
  ];
};

export default CalendarRoutes;
