import PageTitle from '../../utils/PageTitle/PageTitle';
import BackLink from '../../utils/BackLink/BackLink';
import PageButton from '../../components/Buttons/PageButton';
import Menu from '../../components/Menu/Menu';

import GlobalStyles from '../../GlobalStyles';
import FinancesStyles from './FinancesStyles';
import { useSelector } from 'react-redux';

const Finances = () => {
  const pagesButtons = [
    {
      text: 'Minhas Faturas',
      page: '/financeiro/faturas/minhas-faturas',
      disabled: false,
    },
    {
      text: 'Doações',
      page: '/financeiro/tronco',
      disabled: false,
    },
  ];

  const user = useSelector((state) => state.auth.user);

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page="/" />
      <PageTitle>Financeiro</PageTitle>

      <div className={FinancesStyles.buttonsGrid}>
        {(user.cargo === 'Tesoureiro' || user.cargo === 'Venerável') && (
          <>
            <PageButton page="/financeiro/faturas/todas" disabled={false}>
              Ver Todas as Faturas
            </PageButton>
            <PageButton page="/financeiro/faturas" disabled={false}>
              Cadastrar Fatura
            </PageButton>
          </>
        )}
        {pagesButtons.map((button, index) => (
          <PageButton key={index} page={button.page} disabled={button.disabled}>
            {button.text}
          </PageButton>
        ))}
      </div>
      <Menu />
    </section>
  );
};

export default Finances;
