import useAxios from '../../hooks/useAxios';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import moment from 'moment';

import { GET_VOTINGS } from '../../Api';

import BackLink from '../../utils/BackLink/BackLink';
import PageTitle from '../../utils/PageTitle/PageTitle';
import Loader from '../../utils/Loader/Loader';
import Button from '../../components/Buttons/Button';
import Menu from '../../components/Menu/Menu';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';
import { ReactComponent as CalendarDarkIcon } from '../../assets/calendar-dark.svg';
import { ReactComponent as PermissionDarkIcon } from '../../assets/permissions-dark.svg';
import { ReactComponent as PublicIcon } from '../../assets/public.svg';
import { ReactComponent as PrivateIcon } from '../../assets/private.svg';
import { ReactComponent as PencilIcon } from '../../assets/pencil.svg';

import GlobalStyles from '../../GlobalStyles';
import VotingStyles from './VotingStyles';

const VotingSingle = () => {
  const { data, error, loading, execute } = useAxios(GET_VOTINGS);
  const token = useSelector((state) => state.auth.token);
  const { votingId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    execute(token, `_id=${votingId}`);
  }, [token, execute, votingId]);

  const votingData = data?.length > 0 ? data[0] : null;

  const user = useSelector((state) => state.auth.user);

  const formatDate = (date) =>
    moment.utc(date).local('pt-br').format('DD/MM/YYYY');

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page="/votacao/consultar-votacao" />
      <PageTitle>Votação</PageTitle>

      {loading && <Loader />}
      {error && <ErrorNotification>{error}</ErrorNotification>}

      {votingData && (
        <div className={VotingStyles.detailsContainer}>
          <div className="flex flex-row items-center text-center gap-3">
            <h2 className="text-2xl font-bold mb-2">Informações</h2>
            {(user.cargo === 'Escrivão' || user.cargo === 'Venerável') && (
              <PencilIcon
                className="cursor-pointer mb-3 display-block w-4"
                onClick={() => navigate(`/votacao/editar/${votingId}`)}
              />
            )}
          </div>
          <h2 className={VotingStyles.title}>
            Título:{' '}
            <span className={VotingStyles.titleNormal}>
              {votingData.titulo}
            </span>
          </h2>
          <h2 className={VotingStyles.descriptionTitle}>
            Descrição da Votação:
          </h2>
          <p className={VotingStyles.description}>{votingData.descricao}</p>
          <ul>
            <li className={VotingStyles.listItem}>
              <h2 className={VotingStyles.listTitle}>
                <CalendarDarkIcon className={VotingStyles.listIcon} /> Início da
                Votação:
              </h2>
              <p className={VotingStyles.listText}>
                {formatDate(votingData.dataInicio)}
              </p>
            </li>
            <li className={VotingStyles.listItem}>
              <h2 className={VotingStyles.listTitle}>
                <CalendarDarkIcon className={VotingStyles.listIcon} />{' '}
                Encerramento da Votação:
              </h2>
              <p className={VotingStyles.listText}>
                {formatDate(votingData.dataFinal)}
              </p>
            </li>
            <li className={VotingStyles.listItem}>
              <h2 className={VotingStyles.listTitle}>
                {votingData.tipoVotacao === 'Pública' ? (
                  <PublicIcon className={VotingStyles.listIcon} />
                ) : (
                  <PrivateIcon className={VotingStyles.listIcon} />
                )}
                Tipo da Votação:
              </h2>
              <p className={VotingStyles.listText}>{votingData.tipoVotacao}</p>
            </li>
            <li className={VotingStyles.listItem}>
              <h2 className={VotingStyles.listTitle}>
                <PermissionDarkIcon className={VotingStyles.listIcon} /> Status
                da Votação:
              </h2>
              <p className={VotingStyles.listText}>
                {votingData.status ? 'Aberta' : 'Fechada'}
              </p>
            </li>
          </ul>
          <h2 className={VotingStyles.descriptionTitle}>Tags:</h2>

          <div className={VotingStyles.tagContainer}>
            {votingData.tags.map((tag, index) => (
              <span key={index} className={VotingStyles.tag}>
                {tag}
              </span>
            ))}
          </div>
          <div className={VotingStyles.buttonContainer}>
            {!votingData.status && (
              <Button
                onClick={() => navigate(`/votacao/resultados/${votingId}`)}
              >
                Resultados
              </Button>
            )}
            {votingData.status && (
              <Button onClick={() => navigate(`/votacao/votar/${votingId}`)}>
                Votar
              </Button>
            )}
          </div>
        </div>
      )}

      <Menu />
    </section>
  );
};

export default VotingSingle;
