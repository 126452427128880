import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useAxios from '../../hooks/useAxios';
import useForm from '../../hooks/useForm';

import { EDIT_MEMBER, GET_MEMBERS, DELETE_MEMBER } from '../../Api';

import PageTitle from '../../utils/PageTitle/PageTitle';
import Input from '../../components/Forms/Input';
import Select from '../../components/Forms/SelectInput';
import SuccessNotification from '../../utils/SuccessNotification/SuccessNotification';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';
import BackLink from '../../utils/BackLink/BackLink';
import FileInput from '../../components/Forms/FileInput';
import DateInput from '../../components/Forms/DateInput';
import FormButton from '../../components/Buttons/FormButton';
import RedButton from '../../components/Buttons/RedButton';
import WarningModal from '../../utils/WarningModal/WarningModal';
import Menu from '../../components/Menu/Menu';
import Loader from '../../utils/Loader/Loader';

import { ReactComponent as DefaultProfile } from '../../assets/user-profile.svg';

import MembersStyles from './MembersStyles';
import GlobalStyles from '../../GlobalStyles';

const MemberEdit = () => {
  const token = useSelector((state) => state.auth.token);
  const { cim } = useParams();
  const navigate = useNavigate();

  const {
    data: dataGet,
    error: errorGet,
    loading: loadingGet,
    execute: executeGet,
  } = useAxios(GET_MEMBERS);

  const {
    data: dataEdit,
    error: errorEdit,
    loading: loadingEdit,
    execute: executeEdit,
  } = useAxios(EDIT_MEMBER);

  const {
    error: errorDelete,
    loading: loadingDelete,
    execute: executeDelete,
  } = useAxios(DELETE_MEMBER);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleDelete = () => {
    executeDelete(token, cim);
    setModalIsOpen(false);
    navigate(`/membros`);
  };

  const nome = useForm();
  const email = useForm('email');
  const telefone = useForm('telephone');
  const profissao = useForm();
  const cpf = useForm('cpf');
  const dataNascimento = useForm();
  const grauMaconico = useForm();
  const estado = useForm();
  const logradouro = useForm();
  const cep = useForm('cep');
  const [foto, setFoto] = useState('');

  useEffect(() => {
    executeGet(token, `cim=${cim}`);
  }, [cim]);

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const [year, month, day] = dateString.split('T')[0].split('-');
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    if (dataGet && dataGet.length > 0) {
      const memberData = dataGet[0];
      nome.setValue(memberData.nome || '');
      email.setValue(memberData.email || '');
      profissao.setValue(memberData.profissao || '');
      telefone.setValue(memberData.telefone || '');
      cpf.setValue(memberData.cpf || '');
      dataNascimento.setValue(formatDate(memberData.dataNascimento) || '');
      grauMaconico.setValue(memberData.grauMaconico || '');
      logradouro.setValue(memberData.endereco.logradouro || '');
      cep.setValue(memberData.endereco.cep || '');
      setFoto(memberData.foto || '');
      estado.setValue(memberData.estado || '');
    }
  }, [dataGet]);

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    setFoto(base64);
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      if (file) {
        fileReader.readAsDataURL(file);
      }

      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(`Nome: ${nome.validate()}`);
    console.log(`Email: ${email.validate()}`);
    console.log(`Telefone: ${telefone.validate()}`);
    console.log(`CPF: ${cpf.validate()}`);
    console.log(`Profissão: ${profissao.validate()}`);
    console.log(`Data de Nascimento: ${dataNascimento.validate()}`);
    console.log(`Grau Maçônico: ${grauMaconico.validate()}`);
    console.log(`Endereço: ${logradouro.validate()}`);
    console.log(`CEP: ${cep.validate()}`);
    console.log(`Estado: ${estado.validate()}`);
    if (
      nome.validate() &&
      email.validate() &&
      telefone.validate() &&
      cpf.validate() &&
      profissao.validate() &&
      dataNascimento.validate() &&
      grauMaconico.validate() &&
      logradouro.validate() &&
      cep.validate() &&
      estado.validate()
    ) {
      const updatedMember = {
        nome: nome.value,
        email: email.value,
        telefone: telefone.value,
        profissao: profissao.value,
        cpf: cpf.value,
        dataNascimento: dataNascimento.value,
        grauMaconico: grauMaconico.value,
        endereco: {
          logradouro: logradouro.value,
          cep: cep.value,
        },
        foto,
        estado: estado.value,
      };
      await executeEdit(token, cim, updatedMember);
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const options = ['Aprendiz', 'Companheiro', 'Mestre'];

  const stateOptions = [
    'AC', // Acre
    'AL', // Alagoas
    'AP', // Amapá
    'AM', // Amazonas
    'BA', // Bahia
    'CE', // Ceará
    'DF', // Distrito Federal
    'ES', // Espírito Santo
    'GO', // Goiás
    'MA', // Maranhão
    'MT', // Mato Grosso
    'MS', // Mato Grosso do Sul
    'MG', // Minas Gerais
    'PA', // Pará
    'PB', // Paraíba
    'PR', // Paraná
    'PE', // Pernambuco
    'PI', // Piauí
    'RJ', // Rio de Janeiro
    'RN', // Rio Grande do Norte
    'RS', // Rio Grande do Sul
    'RO', // Rondônia
    'RR', // Roraima
    'SC', // Santa Catarina
    'SP', // São Paulo
    'SE', // Sergipe
    'TO', // Tocantins
    'Estrangeiro',
  ];

  const formFields = [
    {
      name: 'nome',
      type: 'text',
      label: 'Nome',
      placeholder: 'insira o nome',
      hook: nome,
      required: true,
    },
    {
      name: 'email',
      type: 'email',
      label: 'Email',
      placeholder: 'insira o email',
      hook: email,
      required: true,
    },
    {
      name: 'telefone',
      type: 'text',
      label: 'Telefone',
      placeholder: 'insira o telefone',
      hook: telefone,
    },
    {
      name: 'profissao',
      type: 'text',
      label: 'Profissão',
      placeholder: 'insira a profissão',
      hook: profissao,
    },
    {
      name: 'cpf',
      type: 'text',
      label: 'CPF',
      placeholder: 'insira o cpf',
      hook: cpf,
    },
    {
      name: 'logradouro',
      type: 'text',
      label: 'Endereço',
      placeholder: 'insira o endereço',
      hook: logradouro,
    },
    {
      name: 'cep',
      type: 'text',
      label: 'CEP',
      placeholder: 'insira o cep',
      hook: cep,
    },
  ];

  return (
    <section className={`${GlobalStyles.componentContainer} }`}>
      <BackLink page={`/membros/${cim}`} />
      <PageTitle>Editar Membro</PageTitle>
      {errorGet && <ErrorNotification>{errorGet}</ErrorNotification>}
      {loadingGet && <Loader />}

      {foto ? (
        <img
          className={`${MembersStyles.memberImage}`}
          src={foto || DefaultProfile}
          alt="Foto de Perfil"
        />
      ) : (
        <DefaultProfile className={`${MembersStyles.memberImage}`} />
      )}

      {errorEdit && <ErrorNotification>{errorEdit}</ErrorNotification>}
      {errorDelete && <ErrorNotification>{errorDelete}</ErrorNotification>}
      {dataEdit && (
        <SuccessNotification>{dataEdit.message}</SuccessNotification>
      )}

      {!loadingGet && dataGet && (
        <form onSubmit={handleSubmit}>
          <FileInput
            label="Foto de Perfil"
            name="foto"
            onChange={handleFileUpload}
            accept="image/jpeg, image/png, image/jpg"
          />

          {formFields.map((field, index) => (
            <Input
              key={index}
              type={field.type}
              label={field.label}
              name={field.name}
              id={field.name}
              required={field.required}
              placeholder={field.placeholder}
              {...field.hook}
            />
          ))}

          <Select
            name="estado"
            label="Estado"
            options={stateOptions}
            required={true}
            {...estado}
          />

          <DateInput
            label="Data de Nascimento"
            name="dataNascimento"
            {...dataNascimento}
          />

          <Select
            name="grauMaconico"
            label="Grau Maçônico"
            options={options}
            {...grauMaconico}
          />
          <div className={`${MembersStyles.buttonContainer}`}>
            <FormButton disabled={loadingEdit}>
              {loadingEdit ? 'Editando...' : 'Editar'}
            </FormButton>
            <RedButton onClick={handleOpenModal}>
              {loadingDelete ? 'Deletando...' : 'Deletar'}
            </RedButton>
          </div>
        </form>
      )}

      {modalIsOpen && (
        <WarningModal
          title="Deletar membro"
          text="Deseja deletar permanentemente esse membro?"
          actionName="Deletar"
          modalAction={handleDelete}
          closeModal={handleCloseModal}
        />
      )}
      <Menu />
    </section>
  );
};

export default MemberEdit;
