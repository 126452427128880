import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAxios from '../../hooks/useAxios';

import { GET_ROLES } from '../../Api';

import BackLink from '../../utils/BackLink/BackLink';
import PageTitle from '../../utils/PageTitle/PageTitle';

import { ReactComponent as PencilIcon } from '../../assets/pencil.svg';

import GlobalStyles from '../../GlobalStyles';
import PermissionsStyles from './PermissionsStyles';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';
import Loader from '../../utils/Loader/Loader';
import WarningNotification from '../../utils/Notification/WarningNotification';

const Role = () => {
  const token = useSelector((state) => state.auth.token);
  const { roleId } = useParams();

  const { data, error, loading, execute } = useAxios(GET_ROLES);

  useEffect(() => {
    execute(token, `_id=${roleId}`);
  }, [token]);

  const roleData = data?.length > 0 ? data[0] : null;

  const createPermissions = roleData?.permissoes.filter((perm) =>
    perm.startsWith('CRIAR'),
  );

  const editPermissions = roleData?.permissoes.filter((perm) =>
    perm.startsWith('EDIT'),
  );

  const deletePermissions = roleData?.permissoes.filter((perm) =>
    perm.startsWith('EXCLUIR'),
  );

  const otherPermissions = roleData?.permissoes.filter(
    (perm) =>
      !perm.startsWith('CRIAR') &&
      !perm.startsWith('EDIT') &&
      !perm.startsWith('EXCLUIR'),
  );

  const navigate = useNavigate();

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page="/permissoes/consultar-cargos" />
      <PageTitle>Cargo: {roleData?.cargo}</PageTitle>
      <div className={PermissionsStyles.headerContainer}>
        <h2 className={PermissionsStyles.headerTitle}>Permissões</h2>
        <PencilIcon
          className={PermissionsStyles.editIcon}
          onClick={() => navigate(`/cargo/editar/${roleId}`)}
        />
      </div>
      {loading && <Loader />}
      {!loading && roleData?.permissoes && roleData.permissoes.length > 0 && (
        <ul>
          <li className="mb-3">
            <h2 className="font-bold text-lg">Criar</h2>
            <div className={PermissionsStyles.tagContainer}>
              {createPermissions.length > 0 ? (
                createPermissions.map((perm, index) => (
                  <span key={index} className={PermissionsStyles.tag}>
                    {perm}
                  </span>
                ))
              ) : (
                <p className="italic text-gray-600">
                  Nenhuma Permissão para Criar
                </p>
              )}
            </div>
          </li>
          <li className="mb-3">
            <h2 className="font-bold text-lg">Editar</h2>
            <div className={PermissionsStyles.tagContainer}>
              {editPermissions.length > 0 ? (
                editPermissions.map((perm, index) => (
                  <span key={index} className={PermissionsStyles.tag}>
                    {perm}
                  </span>
                ))
              ) : (
                <p className="italic text-gray-600">
                  Nenhuma Permissão para Editar
                </p>
              )}
            </div>
          </li>
          <li className="mb-3">
            <h2 className="font-bold text-lg">Excluir</h2>
            <div className={PermissionsStyles.tagContainer}>
              {deletePermissions.length > 0 ? (
                deletePermissions.map((perm, index) => (
                  <span key={index} className={PermissionsStyles.tag}>
                    {perm}
                  </span>
                ))
              ) : (
                <p className="italic text-gray-600">
                  Nenhuma Permissão para Excluir
                </p>
              )}
            </div>
          </li>
          <li className="mb-3">
            <h2 className="font-bold text-lg">Outras Permissões</h2>
            <div className={PermissionsStyles.tagContainer}>
              {otherPermissions.length > 0 ? (
                otherPermissions.map((perm, index) => (
                  <span key={index} className={PermissionsStyles.tag}>
                    {perm}
                  </span>
                ))
              ) : (
                <p className="italic text-gray-600">Nenhuma Outra Permissão</p>
              )}
            </div>
          </li>
        </ul>
      )}
      {error && <ErrorNotification>{error.message}</ErrorNotification>}
      {!loading && !roleData?.permissoes.length > 0 && (
        <WarningNotification>
          nenhuma permissão associada a esse cargo.
        </WarningNotification>
      )}
    </section>
  );
};

export default Role;
