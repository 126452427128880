import { useState } from 'react';

const useRichText = (validateFunction) => {
  const [richText, setRichText] = useState('');
  const [richTextError, setrichTextError] = useState(null);

  const validate = (value) => {
    if (validateFunction) {
      const validationResult = validateFunction(value);
      if (validationResult) {
        setrichTextError(null);
      } else {
        setrichTextError('Texto inválido.');
      }
      return validationResult;
    }
    return true;
  };

  const handleRichTextChange = (content) => {
    if (richTextError) validate(content);
    setRichText(content);
  };

  return {
    richText,
    setRichText,
    handleRichTextChange,
    richTextError,
    validate: () => validate(richText),
    onBlur: () => validate(richText),
  };
};

export default useRichText;
