import { useState } from 'react';
import { QrCodePix } from 'qrcode-pix';
import useForm from '../../hooks/useForm';

import BackLink from '../../utils/BackLink/BackLink';
import PageTitle from '../../utils/PageTitle/PageTitle';
import Input from '../../components/Forms/Input';
import SuccessNotification from '../../utils/SuccessNotification/SuccessNotification';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';
import Button from '../../components/Buttons/Button';
import Menu from '../../components/Menu/Menu';

import GlobalStyles from '../../GlobalStyles';
import FinancesStyles from './FinancesStyles';
import { useSelector } from 'react-redux';
import GuestMenu from '../../components/Menu/GuestMenu';

const FinancesDonation = () => {
  const value = useForm();
  const [image, setImage] = useState('');
  const [pixPayload, setPixPayload] = useState('');
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const handleDonate = async () => {
    const qrCodePix = QrCodePix({
      version: '01',
      key: '2a1cc5e0-1954-4883-bcc6-5e099a6a4e87',
      name: 'Areópago',
      city: 'BRASILIA',
      message: 'Doação',
      value: parseFloat(value.value),
    });

    const imageBase64 = await qrCodePix.base64();
    setImage(imageBase64);
    const payload = qrCodePix.payload();
    setPixPayload(payload);
  };

  const copyPixPayloadToClipboard = () => {
    navigator.clipboard
      .writeText(pixPayload)
      .then(() => {
        setError(null);
        setSuccess('Código PIX copiado para a área de transferência!');
      })
      .catch((err) => {
        setSuccess(null);
        setError(`Falha ao copiar: ${err}`);
      });
  };

  const user = useSelector((state) => state.auth.user);
  const menu = user.cargo === 'Visitante' ? <GuestMenu /> : <Menu />;

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink />
      <PageTitle>Doação</PageTitle>
      <h3 className={FinancesStyles.donateHeading}>Faça uma Doação</h3>
      {image && (
        <div className={FinancesStyles.qrCodeContainer}>
          <img
            src={image}
            alt="QR Code PIX"
            className={FinancesStyles.qrCodeImage}
          />
          <p className={FinancesStyles.qrCodeText}>
            Leia o QR Code do Pix ou Copie o Código para realizar a sua doação
          </p>
          <p
            onClick={copyPixPayloadToClipboard}
            className={FinancesStyles.copyPixLink}
          >
            Clique aqui para copiar o código PIX
          </p>
        </div>
      )}

      <Input
        type="text"
        required
        label="Valor"
        placeholder="Insira Valor da Doação"
        name="valor"
        {...value}
      />
      <Button onClick={handleDonate}>Doar</Button>
      {success && <SuccessNotification>{success}</SuccessNotification>}
      {error && <ErrorNotification>{error}</ErrorNotification>}
      {menu}
    </section>
  );
};

export default FinancesDonation;
