const PermissionsStyles = {
  buttonsGrid: 'grid grid-cols-1 gap-5',
  membersGrid: 'grid grid-cols-1 gap-3 pb-10',
  headerContainer: 'flex flex-row items-center text-center gap-3',
  buttonContainer: 'flex flex-row justify-between gap-5',
  headerTitle: 'text-2xl font-bold mb-2',
  memberMainInfo: 'flex flex-row items-center gap-5 mb-10',
  memberImage: 'w-20 h-20 display-block rounded-full',
  memberInfo: 'text-xl font-medium text-slate-950',
  editIcon: 'cursor-pointer mb-3 display-block w-4',
  tagContainer: 'w-72 flex flex-row gap-2 flex-wrap',
  tag: 'flex flex-row items-center  justify-center px-2 py-1 bg-blue-500 text-white rounded-full',
  listItem: 'mb-4',
  listItemTitle: 'flex flex-row gap-1 items-center text-xl font-bold mb-2',
  listItemContent: 'text-lg text-gray-600',
  icon: 'w-5 h5',
};

export default PermissionsStyles;
