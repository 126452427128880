const PaginationStyles = {
  wrapper: 'flex justify-center items-center pb-5',
  pageList: 'flex flex-row p-0',
  buttonPrev: 'my-0 mx-1 cursor-pointer',
  buttonNext: 'mx-1 my-0 cursor-pointer',
  pagination: 'mx-1 my-0',
  paginationContent:
    'hover:bg-gray-300 px-2 py-1 rounded border border-solid border-gray-500 cursor-pointer',
};

export default PaginationStyles;
