const VotingStyles = {
  buttonsGrid: 'grid grid-cols-1 gap-5 pb-10',
  subTitle: 'text-lg mb-5 font-bold',
  detailsContainer: 'w-72',
  title: 'text-xl font-bold mb-3',
  titleNormal: 'font-normal text-gray-600',
  descriptionTitle: 'text-lg font-bold',
  description: 'mb-5 text-gray-600',
  listItem: 'mb-4',
  listTitle: 'flex flex-row gap-1 items-center text-xl font-bold',
  listIcon: 'w-5 h-5',
  listText: 'text-lg text-gray-600',
  buttonContainer: 'flex flex-row justify-between gap-5',
  votedMessage: 'flex flex-row gap-1 items-center text-xl font-bold',
  votingIcon: 'w-5 h-5',
  voteFormTitle: 'text-xl font-bold mb-3',
  listContainer: 'flex flex-row justify-between gap-4 mb-5',
  listItemCenter: 'text-center',
  listHeader: 'font-bold mb-3',
  voteCountGreen: 'mb-5 font-bold text-green-500',
  voteCountYellow: 'mb-5 font-bold text-yellow-500',
  voteCountRed: 'mb-5 font-bold text-red-500',
  privateVoteMessage: 'w-72 flex flex-col justify-center items-center',
  privateVoteText: 'text-center font-bold text-lg',
  voteFormTitle: 'text-xl font-bold mb-3 flex flex-row gap-1 items-center',
  votingIcon: 'w-5 h-5',
  tagContainer: 'w-72 flex flex-row gap-2 flex-wrap mt-3',
  tag: 'flex flex-row items-center  justify-center px-2 py-1 bg-blue-500 text-white rounded-full',
};

export default VotingStyles;
