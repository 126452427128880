import { useState } from 'react';
import { ReactComponent as DropdownIcon } from '../../assets/dropdown.svg';
import MultipleSelectInputStyles from './MultipleSelectInputStyles';

const MultipleSelectInput = ({
  name,
  label,
  value,
  onChange,
  required,
  options,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div className={MultipleSelectInputStyles.container}>
      <label htmlFor={name} className={MultipleSelectInputStyles.label}>
        {label}
      </label>
      <div
        className={MultipleSelectInputStyles.select}
        onClick={() => setDropdownOpen(!dropdownOpen)}
        style={{ cursor: 'pointer' }}
      >
        {value.join(', ') || 'Selecionar'}
        <div className={MultipleSelectInputStyles.icon}>
          <DropdownIcon />
        </div>
      </div>
      {dropdownOpen && (
        <div className={MultipleSelectInputStyles.dropdown}>
          {options.map((option, index) => (
            <div key={index} className={MultipleSelectInputStyles.option}>
              <input
                type="checkbox"
                id={`${name}_${index}`}
                name={name}
                value={option}
                checked={value.includes(option)}
                onChange={onChange}
                required={required}
                disabled={
                  required && value.length === 1 && value.includes(option)
                }
              />
              <label
                htmlFor={`${name}_${index}`}
                className={MultipleSelectInputStyles.checkboxLabel}
              >
                {option}
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultipleSelectInput;
