import { ReactComponent as Logo } from '../../assets/logo.svg';
import { ReactComponent as LogoutIcon } from '../../assets/logoff.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/auth/authSlice';

const GuestMenu = () => {
  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <>
      <div className="pt-32"></div>
      <div className="bg-white fixed inset-x-0 bottom-0 left-1/2 transform -translate-x-1/2 px-5 shadow-xl rounded-t-md w-96">
        <nav className="rounded-t-md">
          <div className="flex justify-between items-center">
            <div className="logo">
              <Logo
                className="w-16 cursor-pointer"
                onClick={() => navigate('/')}
              />
            </div>
            <div className="flex flex-row gap-5">
              <button onClick={handleLogout} className="menu-button">
                <LogoutIcon className="w-8 h-8" />
              </button>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default GuestMenu;
