import WarningModalStyles from './WarningModalStyles';

const WarningModal = ({ title, text, actionName, modalAction, closeModal }) => {
  return (
    <div className={WarningModalStyles.overlay}>
      <div className={WarningModalStyles.modalContainer}>
        <div className={WarningModalStyles.modalContent}>
          <div className={WarningModalStyles.modalBox}>
            <div className={WarningModalStyles.contentBox}>
              <div className={WarningModalStyles.iconBox}>
                <svg
                  class="h-6 w-6 text-red-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                  />
                </svg>
              </div>
              <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <h3 className={WarningModalStyles.title} id="modal-title">
                  {title}
                </h3>
                <div className={WarningModalStyles.text}>
                  <p>{text}</p>
                </div>
              </div>
            </div>
            <div className={WarningModalStyles.buttonBox}>
              <button
                onClick={modalAction}
                type="button"
                className={WarningModalStyles.actionButton}
              >
                {actionName}
              </button>
              <button
                onClick={closeModal}
                type="button"
                className={WarningModalStyles.cancelButton}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarningModal;
