import { Route } from 'react-router-dom';

import Family from '../views/Family/Family';
import FamilyCreate from '../views/Family/FamilyCreate';
import FamilyEdit from '../views/Family/FamilyEdit';

const FamilyRoutes = () => {
  return [
    <Route key="familiares" path="/familiares/:cim" element={<Family />} />,
    <Route
      key="criar-familiar"
      path="/familiares/criar/:cim"
      element={<FamilyCreate />}
    />,
    <Route
      key="editar-familiar"
      path="/familiares/editar/:cim/:familyId"
      element={<FamilyEdit />}
    />,
  ];
};

export default FamilyRoutes;
