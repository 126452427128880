import { Route } from 'react-router-dom';

import ChatsList from '../views/Chat/ChatsList';
import Chat from '../views/Chat/Chat';
import GroupsList from '../views/Chat/GroupsList';
import GroupCreate from '../views/Chat/GroupCreate';
import GroupChat from '../views/Chat/GroupChat';
import GroupEdit from '../views/Chat/GroupEdit';

const ChatRoutes = () => {
  return [
    <Route key="chat" path="/chat" element={<ChatsList />} />,
    <Route key="chat-membro" path="/chat/:chatCim" element={<Chat />} />,
    <Route key="grupos" path="/chat/grupos" element={<GroupsList />} />,
    <Route
      key="criar-grupo"
      path="/chat/grupos/criar"
      element={<GroupCreate />}
    />,
    <Route key="grupo" path="/chat/grupo/:chatId" element={<GroupChat />} />,
    <Route
      key="editar-grupo"
      path="/chat/grupo/editar/:groupId"
      element={<GroupEdit />}
    />,
  ];
};

export default ChatRoutes;
