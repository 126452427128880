import PageTitle from '../../utils/PageTitle/PageTitle';
import BackLink from '../../utils/BackLink/BackLink';
import PageButton from '../../components/Buttons/PageButton';

import GlobalStyles from '../../GlobalStyles';
import PermissionsStyles from './PermissionsStyles';
import Menu from '../../components/Menu/Menu';

const Permissions = () => {
  const pagesButtons = [
    {
      text: 'Criar Cargos',
      page: '/permissoes/criar-cargo',
      disabled: false,
    },
    {
      text: 'Consultar Cargos',
      page: '/permissoes/consultar-cargos',
      disabled: false,
    },
    {
      text: 'Cargos dos Membros',
      page: '/permissoes/consultar-cargos/membros',
      disabled: false,
    },
  ];
  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page="/" />
      <PageTitle>Permissões</PageTitle>
      <div className={PermissionsStyles.buttonsGrid}>
        {pagesButtons.map((button, index) => (
          <PageButton key={index} page={button.page} disabled={button.disabled}>
            {button.text}
          </PageButton>
        ))}
      </div>
      <Menu />
    </section>
  );
};

export default Permissions;
