import { Suspense, lazy, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useAxios from '../../hooks/useAxios';
import moment from 'moment';
import 'moment/locale/pt-br';

import { GET_BIRTHDAYS, GET_EVENTS } from '../../Api';

import BackLink from '../../utils/BackLink/BackLink';
import Loader from '../../utils/Loader/Loader';
import Menu from '../../components/Menu/Menu';
import PageTitle from '../../utils/PageTitle/PageTitle';

import GlobalStyles from '../../GlobalStyles';
import CalendarStyles from './CalendarStyles';

const EventCard = lazy(() => import('../../components/Cards/EventCard'));
moment.locale('pt-br');
const BirthdayCard = lazy(() => import('../../components/Cards/BirthdayCard'));

const Events = () => {
  const { date } = useParams();
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();

  const { data, error, execute } = useAxios(GET_EVENTS);
  const {
    data: dataBirthdays,
    error: errorBirthday,
    execute: executeBirthdays,
  } = useAxios(GET_BIRTHDAYS);

  useEffect(() => {
    const startDate = moment(date);
    const endDate = startDate.clone().add(1, 'days').format('YYYY-MM-DD');

    const monthAndDay = moment(date).format('MM-DD');

    execute(token, `de=${date}&ate=${endDate}`);
    executeBirthdays(token, `dataNascimento=${monthAndDay}`);
  }, [token, date, execute]);

  const formatDate = (date) =>
    moment.utc(date).local('pt-br').format('DD/MM/YYYY');
  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page="/eventos" />
      <PageTitle>Eventos: {formatDate(date)} </PageTitle>
      <div>
        <Suspense fallback={<Loader />}>
          {!error &&
            data?.map((data, index) => (
              <EventCard
                key={index}
                data={data.data}
                onClick={() => navigate(`/evento/${data._id}`)}
              />
            ))}
        </Suspense>
      </div>
      {dataBirthdays && (
        <div>
          <h2 className={CalendarStyles.title}>Aniversariantes:</h2>
          <Suspense fallback={<Loader />}>
            {!errorBirthday &&
              dataBirthdays?.map((data, index) => (
                <BirthdayCard key={index} name={data.nome} />
              ))}
          </Suspense>
        </div>
      )}
      <Menu />
    </section>
  );
};

export default Events;
