import { useNavigate } from 'react-router-dom';
import { ReactComponent as TriangleIcon } from '../../assets/triangle.svg';
import PageButtonStyles from './PageButtonStyles';

const PageButton = ({ children, icon, page, disabled }) => {
  const navigate = useNavigate();

  function handleClick() {
    navigate(page);
  }

  return (
    <button
      className={PageButtonStyles.button}
      onClick={handleClick}
      disabled={disabled}
    >
      {children}
      {icon ? icon : <TriangleIcon />}
    </button>
  );
};

export default PageButton;
