import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useForm from '../../hooks/useForm';
import useAxios from '../../hooks/useAxios';
import moment from 'moment';

import { GET_RECORDS, EDIT_RECORD } from '../../Api';

import BackLink from '../../utils/BackLink/BackLink';
import PageTitle from '../../utils/PageTitle/PageTitle';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';
import SuccessNotification from '../../utils/SuccessNotification/SuccessNotification';
import Menu from '../../components/Menu/Menu';
import Loader from '../../utils/Loader/Loader';
import SelectInput from '../../components/Forms/SelectInput';
import FormButton from '../../components/Buttons/FormButton';

import { ReactComponent as CalendarDarkIcon } from '../../assets/calendar-dark.svg';
import { ReactComponent as TriangleDarkIcon } from '../../assets/triangle-dark.svg';

import RecordsBookStyles from './RecordsBookStyles';
import GlobalStyles from '../../GlobalStyles';

const RecordStatus = () => {
  const { recordId } = useParams();
  const token = useSelector((state) => state.auth.token);
  const status = useForm();

  const {
    data: dataGet,
    error: errorGet,
    loading: loadingGet,
    execute: executeGet,
  } = useAxios(GET_RECORDS);

  const {
    data: dataEdit,
    error: errorEdit,
    loading: loadingEdit,
    execute: executeEdit,
  } = useAxios(EDIT_RECORD);

  useEffect(() => {
    executeGet(token, `_id=${recordId}`);
  }, [token, recordId, executeGet]);

  const recordData = dataGet?.length > 0 ? dataGet[0] : null;

  const [contagemVotos, setContagemVotos] = useState({ sim: 0, não: 0 });

  const countVotes = (votes) => {
    let count = { sim: 0, não: 0 };
    votes.forEach((voto) => {
      if (voto.tipo === 'Sim') {
        count.sim += 1;
      } else if (voto.tipo === 'Não') {
        count.não += 1;
      }
    });
    return count;
  };

  useEffect(() => {
    if (recordData?.votos) {
      setContagemVotos(countVotes(recordData.votos));
    }
  }, [recordData]);

  const formatDate = (date) =>
    moment.utc(date).local('pt-br').format('DD/MM/YYYY');

  const handleSubmit = async (event) => {
    event.preventDefault();
    await executeEdit(token, recordId, { status: status.value });

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const user = useSelector((state) => state.auth.user);

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page={`/livro-de-atas/consultar-ata/${recordId}`} />
      <PageTitle>Status da Ata</PageTitle>
      {errorGet && <ErrorNotification>{errorGet}</ErrorNotification>}
      {loadingGet && <Loader />}
      {dataEdit && (
        <SuccessNotification>{dataEdit.message}</SuccessNotification>
      )}
      {errorEdit && <ErrorNotification>{errorEdit}</ErrorNotification>}
      {recordData && (
        <>
          <div className={RecordsBookStyles.detailsContainer}>
            <h2 className={RecordsBookStyles.title}>
              Título:{' '}
              <span className={RecordsBookStyles.titleNormal}>
                {recordData.titulo}
              </span>
            </h2>
            <h2 className={RecordsBookStyles.title}>
              Status:{' '}
              <span className={RecordsBookStyles.titleNormal}>
                {recordData.status}
              </span>
            </h2>
            <ul>
              <li className={RecordsBookStyles.listItem}>
                <h2 className={RecordsBookStyles.listTitle}>
                  <CalendarDarkIcon className={RecordsBookStyles.listIcon} />{' '}
                  Início da Votação:
                </h2>
                <p className={RecordsBookStyles.listText}>
                  {formatDate(recordData.dataInicio)}
                </p>
              </li>
              <li className={RecordsBookStyles.listItem}>
                <h2 className={RecordsBookStyles.listTitle}>
                  <CalendarDarkIcon className={RecordsBookStyles.listIcon} />{' '}
                  Encerramento da Votação:
                </h2>
                <p className={RecordsBookStyles.listText}>
                  {formatDate(recordData.dataFinal)}
                </p>
              </li>
            </ul>
            <h2 className={RecordsBookStyles.voteFormTitle}>
              Resumo da Votação
            </h2>
            <ul className={RecordsBookStyles.listContainer}>
              <li className={RecordsBookStyles.listItemCenter}>
                <h2 className={RecordsBookStyles.listHeader}>Sim</h2>
                <p className={RecordsBookStyles.voteCountGreen}>
                  {contagemVotos.sim || 0}
                </p>
              </li>
              <li className={RecordsBookStyles.listItemCenter}>
                <h2 className={RecordsBookStyles.listHeader}>Não</h2>
                <p className={RecordsBookStyles.voteCountRed}>
                  {contagemVotos.não || 0}
                </p>
              </li>
            </ul>
            <Link
              to={`/livro-de-atas/status/votacao/${recordId}`}
              className={RecordsBookStyles.status}
            >
              <TriangleDarkIcon />
              Detalhes da Votação
            </Link>
            {(user.cargo === 'Escrivão' || user.cargo === 'Venerável') && (
              <form onSubmit={handleSubmit}>
                <SelectInput
                  name="status"
                  label="Definir Status da Ata"
                  options={['Aprovada', 'Rejeitada']}
                  {...status}
                />
                <FormButton disabled={loadingEdit}>
                  {loadingEdit ? 'Carregando...' : 'Definir'}
                </FormButton>
              </form>
            )}
          </div>
        </>
      )}
      <Menu />
    </section>
  );
};

export default RecordStatus;
