import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Suspense, useEffect, useState, lazy } from 'react';
import useAxios from '../../hooks/useAxios';

import moment from 'moment';

import { GET_EVENTS, GET_BIRTHDAYS } from '../../Api';

import BackLink from '../../utils/BackLink/BackLink';
import Loader from '../../utils/Loader/Loader';
import Button from '../../components/Buttons/Button';
import ICALButton from '../../components/Buttons/ICALButton';
import PageTitle from '../../utils/PageTitle/PageTitle';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';
import Pagination from '../../utils/Pagination/Pagination';
import Menu from '../../components/Menu/Menu';

import GlobalStyles from '../../GlobalStyles';
import CalendarStyles from './CalendarStyles';
import WarningNotification from '../../utils/Notification/WarningNotification';

const CalendarGrid = lazy(() =>
  import('../../components/Calendar/CalendarGrid'),
);

const EventCard = lazy(() => import('../../components/Cards/EventCard'));

const Calendar = () => {
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();

  const {
    data: dataEvents,
    error: errorEvents,
    execute: executeEvents,
  } = useAxios(GET_EVENTS);

  const {
    data: dataBirthdays,
    error: errorBirthdays,
    execute: executeBirthday,
  } = useAxios(GET_BIRTHDAYS);

  const [events, setEvents] = useState();
  const [birthdays, setBirthdays] = useState();
  const [nextEvents, setNextEvents] = useState();
  const currentDate = moment().format('YYYY-MM-DD');
  const thirtyDaysLater = moment().add(30, 'days');

  useEffect(() => {
    executeEvents(token);
    executeBirthday(token);
  }, [token, executeEvents, executeBirthday]);

  useEffect(() => {
    if (dataEvents) {
      const updatedEvents = dataEvents.map((event) => event.data);
      setEvents(updatedEvents);

      const nextEvents = dataEvents
        .filter(
          (event) =>
            moment(event.data).isAfter(currentDate) &&
            moment(event.data).isBefore(thirtyDaysLater),
        )
        .sort((a, b) => moment(a).diff(moment(b)));

      setNextEvents(nextEvents);
    }

    if (dataBirthdays) {
      const updatedBirthdays = dataBirthdays.map(
        (event) => event.dataNascimento,
      );
      setBirthdays(updatedBirthdays);
    }
  }, [dataEvents, dataBirthdays]);

  const [currentPage, setCurrentPage] = useState(1);

  const eventsPerPage = 4;

  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;

  const currentEvents = nextEvents
    ? nextEvents.slice(indexOfFirstEvent, indexOfLastEvent)
    : [];

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const user = useSelector((state) => state.auth.user);

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page="/" />
      <PageTitle>Eventos</PageTitle>
      <Suspense fallback={<Loader />}>
        <CalendarGrid events={events} birthdays={birthdays} />
      </Suspense>
      <div className={CalendarStyles.buttonContainer}>
        {(user.cargo === 'Escrivão' || user.cargo === 'Venerável') && (
          <Button onClick={() => navigate('/eventos/criar-evento')}>
            Novo Evento
          </Button>
        )}
        <ICALButton events={dataEvents} />
      </div>
      <h2 className={CalendarStyles.nextEvents}>Eventos Próximos</h2>
      <Suspense fallback={<Loader />}>
        {!errorEvents &&
          currentEvents?.map((data, index) => (
            <EventCard
              key={index}
              data={data.data}
              onClick={() => navigate(`/evento/${data._id}`)}
            />
          ))}
      </Suspense>
      <Pagination
        itemsPerPage={eventsPerPage}
        totalItems={nextEvents?.length}
        paginate={paginate}
        currentPage={currentPage}
      />
      {errorEvents && (
        <WarningNotification>Nenhum Evento Encontrado</WarningNotification>
      )}
      <Menu />
    </section>
  );
};

export default Calendar;
