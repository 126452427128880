import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useAxios from '../../hooks/useAxios';
import useForm from '../../hooks/useForm';
import moment from 'moment';
import 'moment/locale/pt-br';

import { GET_RECORDS, EDIT_RECORD, DELETE_RECORD } from '../../Api';

import BackLink from '../../utils/BackLink/BackLink';
import PageTitle from '../../utils/PageTitle/PageTitle';
import Loader from '../../utils/Loader/Loader';
import DateInput from '../../components/Forms/DateInput';
import FormButton from '../../components/Buttons/FormButton';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';
import SuccessNotification from '../../utils/SuccessNotification/SuccessNotification';
import Menu from '../../components/Menu/Menu';
import WarningModal from '../../utils/WarningModal/WarningModal';

import GlobalStyles from '../../GlobalStyles';
import RecordsBookStyles from './RecordsBookStyles';

import useRichText from '../../hooks/useRichText';
import RichTextArea from '../../components/Forms/RichTextArea';
import RedButton from '../../components/Buttons/RedButton';
import SelectInput from '../../components/Forms/SelectInput';

moment.locale('pt-br');

const RecordEdit = () => {
  const token = useSelector((state) => state.auth.token);
  const { recordId } = useParams();
  const navigate = useNavigate();

  const {
    data: dataGet,
    error: errorGet,
    loading: loadingGet,
    execute: executeGet,
  } = useAxios(GET_RECORDS);

  const {
    data: dataEdit,
    error: errorEdit,
    loading: loadingEdit,
    execute: executeEdit,
  } = useAxios(EDIT_RECORD);

  const {
    error: errorDelete,
    loading: loadingDelete,
    execute: executeDelete,
  } = useAxios(DELETE_RECORD);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const startDate = useForm();
  const finalDate = useForm();
  const { richText, handleRichTextChange } = useRichText();
  const statusVoting = useForm('');

  useEffect(() => {
    executeGet(token, `_id=${recordId}`);
  }, [recordId, token]);

  const recordData = dataGet?.length > 0 ? dataGet[0] : null;

  const formatDate = (date) =>
    moment.utc(date).local('pt-br').format('YYYY-MM-DD');

  useEffect(() => {
    if (recordData) {
      startDate.setValue(formatDate(recordData.dataInicio));
      finalDate.setValue(formatDate(recordData.dataFinal));
      handleRichTextChange(recordData.descricao);
      statusVoting.setValue(recordData.statusVotacao);
    }
  }, [recordData]);

  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleDelete = () => {
    executeDelete(token, recordId);
    navigate('/livro-de-atas/consultar-atas');
  };

  const options = ['aberta', 'fechada'];

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (startDate.validate() && finalDate.validate()) {
      const updatedRecord = {
        descricao: richText,
        dataInicio: startDate.value,
        dataFinal: finalDate.value,
        statusVotacao: statusVoting.value,
      };
      await executeEdit(token, recordId, updatedRecord);

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page="/livro-de-atas/consultar-atas" />
      <PageTitle>Editar Ata</PageTitle>
      {errorGet && <ErrorNotification>{errorGet}</ErrorNotification>}
      {loadingGet && <Loader />}
      {errorEdit && <ErrorNotification>{errorEdit}</ErrorNotification>}
      {errorDelete && <ErrorNotification>{errorDelete}</ErrorNotification>}
      {dataEdit && (
        <SuccessNotification>{dataEdit.message}</SuccessNotification>
      )}
      {!loadingGet && recordData && (
        <form onSubmit={handleSubmit}>
          <RichTextArea
            label="Descrição"
            name="descricao"
            value={richText}
            onChange={handleRichTextChange}
          />
          <DateInput
            label="Data de Início"
            name="dataInicio"
            required
            {...startDate}
          />
          <DateInput
            label="Data de Encerramento"
            name="dataFinal"
            required
            {...finalDate}
          />
          <SelectInput
            label="Votação da Ata"
            required={true}
            options={options}
            {...statusVoting}
          />
          <div className={RecordsBookStyles.buttonContainer}>
            <FormButton disabled={loadingEdit}>
              {loadingEdit ? 'Editando...' : 'Editar'}
            </FormButton>
            <RedButton onClick={handleOpenModal}>
              {loadingDelete ? 'Deletando...' : 'Deletar'}
            </RedButton>
          </div>
        </form>
      )}

      {modalIsOpen && (
        <WarningModal
          title="Deletar Ata"
          text="Deseja deletar permanentemente esta ata?"
          actionName="Deletar"
          modalAction={handleDelete}
          closeModal={handleCloseModal}
        />
      )}
      <Menu />
    </section>
  );
};

export default RecordEdit;
