import { Suspense, lazy, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useAxios from '../../hooks/useAxios';
import moment from 'moment';
import 'moment/locale/pt-br';

import { GET_EVENTS } from '../../Api';

import BackLink from '../../utils/BackLink/BackLink';
import Loader from '../../utils/Loader/Loader';
import PageTitle from '../../utils/PageTitle/PageTitle';
import Menu from '../../components/Menu/Menu';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';

import GlobalStyles from '../../GlobalStyles';
import WarningNotification from '../../utils/Notification/WarningNotification';

const EventCard = lazy(() => import('../../components/Cards/EventCard'));
moment.locale('pt-br');

const PresencesEventSearch = () => {
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();

  const { data, error, setError, execute } = useAxios(GET_EVENTS);

  const today = moment().utcOffset('-03:00');

  useEffect(() => {
    const endDate = today.clone().add(1, 'days').format('YYYY-MM-DD');
    const formattedToday = today.format('YYYY-MM-DD');
    execute(token, `de=${formattedToday}&ate=${endDate}`);
  }, [token, execute]);

  const formatDate = (date) => moment(date).format('DD/MM/YYYY');

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page="/presencas" />
      <PageTitle>Eventos de Hoje: {formatDate(today)} </PageTitle>
      <div>
        <Suspense fallback={<Loader />}>
          {!error &&
            data?.map((data, index) => (
              <EventCard
                key={index}
                data={data.data}
                onClick={() => navigate(`/evento/status/${data._id}`)}
              />
            ))}
        </Suspense>
        {error && (
          <WarningNotification>Nenhum Evento Encontrado</WarningNotification>
        )}
      </div>
      <Menu />
    </section>
  );
};

export default PresencesEventSearch;
