import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useAxios from '../../hooks/useAxios';
import { useSelector } from 'react-redux';

import { CONFIRM_PRESENCE } from '../../Api';

import BackLink from '../../utils/BackLink/BackLink';
import PageTitle from '../../utils/PageTitle/PageTitle';
import Loader from '../../utils/Loader/Loader';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';
import SuccessNotification from '../../utils/SuccessNotification/SuccessNotification';
import Menu from '../../components/Menu/Menu';

import GlobalStyles from '../../GlobalStyles';

const ConfirmPresence = () => {
  const { data, error, loading, execute, setError } =
    useAxios(CONFIRM_PRESENCE);
  const token = useSelector((state) => state.auth.token);
  const { cim } = useSelector((state) => state.auth.user);
  const { eventId } = useParams();

  const [userLocation, setUserLocation] = useState(null);

  useEffect(() => {
    const getLocation = async () => {
      try {
        if ('geolocation' in navigator) {
          const position = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
          });

          const { latitude, longitude } = position.coords;
          setUserLocation({ lat: latitude, lng: longitude });
        } else {
          setError('Geolocalização não suportada neste navegador.');
        }
      } catch (error) {
        setError(`Erro ao obter a localização: ${error.message}`);
      }
    };

    getLocation();
  }, [setError]);

  useEffect(() => {
    if (userLocation) {
      execute(token, eventId, { cim: cim, local: userLocation });
      console.log(userLocation);
    }
  }, [execute, token, eventId, cim, userLocation]);

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page="/" />
      <PageTitle>Confirmar Presença</PageTitle>
      {loading && <Loader />}
      {error && <ErrorNotification>{error}</ErrorNotification>}
      {data && <SuccessNotification>{data.message}</SuccessNotification>}
      <p>
        Aceite compartilhar sua localização para confirmar presença no evento.
      </p>
      <Menu />
    </section>
  );
};

export default ConfirmPresence;
