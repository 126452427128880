const FamilyStyles = {
  inputField: 'mb-4',
  selectField: 'mb-4',
  formButton:
    'cursor-pointer flex items-center justify-center rounded w-32 h-10 p-3 text-center bg-gradient-to-t to-blue-500 from-blue-700 text-white text-s font-semibold tracking-wide',

  buttonContainer: 'flex flex-row justify-between mt-2',
  errorMessage: 'text-red-500 text-sm mt-2',
  successMessage: 'text-green-500 text-sm mt-2',
  pageTitle: 'text-2xl font-bold mb-4',
  backLink: 'text-blue-500 underline mb-4',
};

export default FamilyStyles;
