import WarningNotificationStyles from './WarningNotificationStyles';

const WarningNotification = ({ children }) => {
  return (
    <div className={WarningNotificationStyles.wrapper}>
      <p className={WarningNotificationStyles.text}>
        <span className={WarningNotificationStyles.icon}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 2L1 21h22L12 2zm0 4.83L18.34 20H5.66L12 6.83zM11 10v4h2v-4h-2zm0 6v2h2v-2h-2z"
              fill="#FFF"
            />
          </svg>
        </span>
        {children}
      </p>
    </div>
  );
};

export default WarningNotification;
