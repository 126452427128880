import TextAreaStyles from './TextAreaStyles';

const TextArea = ({ label, name, placeholder, maxLength, value, onChange }) => {
  return (
    <div className={TextAreaStyles.container}>
      <label htmlFor={name} className={TextAreaStyles.label}>
        {label}
      </label>
      <textarea
        name={name}
        value={value}
        onChange={onChange}
        maxLength={maxLength}
        placeholder={placeholder}
        className={TextAreaStyles.textarea}
      />
      {maxLength && (
        <p className={TextAreaStyles.characterCount}>
          {value.length} / {maxLength} caracteres
        </p>
      )}
    </div>
  );
};

export default TextArea;
