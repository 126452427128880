import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useAxios from '../../hooks/useAxios';
import useForm from '../../hooks/useForm';

import { GET_RECORDS, VOTE_IN_RECORD } from '../../Api';

import BackLink from '../../utils/BackLink/BackLink';
import PageTitle from '../../utils/PageTitle/PageTitle';
import Loader from '../../utils/Loader/Loader';
import SelectInput from '../../components/Forms/SelectInput';
import TextArea from '../../components/Forms/TextArea';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';
import FormButton from '../../components/Buttons/FormButton';
import SuccessNotification from '../../utils/SuccessNotification/SuccessNotification';

import { ReactComponent as VotingIcon } from '../../assets/votes-dark.svg';

import GlobalStyles from '../../GlobalStyles';
import RecordsBookStyles from './RecordsBookStyles';
import PDFButton from '../../components/Buttons/PDFButton';

const VoteRecord = () => {
  const { recordId } = useParams();
  const token = useSelector((state) => state.auth.token);
  const { cim } = useSelector((state) => state.auth.user);
  const vote = useForm();
  const comment = useForm();

  const {
    data: dataGet,
    error: errorGet,
    loading: loadingGet,
    execute: executeGet,
  } = useAxios(GET_RECORDS);

  const {
    data: dataVote,
    error: errorVote,
    loading: loadingVote,
    execute: executeVote,
  } = useAxios(VOTE_IN_RECORD);

  useEffect(() => {
    executeGet(token, `_id=${recordId}`);
  }, [token, recordId, executeGet]);

  const handleSubmitVote = async (event) => {
    event.preventDefault();
    await executeVote(token, recordId, {
      memberCim: cim,
      voteData: { tipo: vote.value, comentario: comment.value },
    });

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const recordData = dataGet?.length > 0 ? dataGet[0] : null;
  const hasVoted = recordData?.votos.some((voto) => voto.membro === cim);

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page={`/livro-de-atas/consultar-ata/${recordId}`} />
      <PageTitle>Votar na Ata</PageTitle>
      {loadingGet && <Loader />}
      {errorGet && <ErrorNotification>{errorGet}</ErrorNotification>}
      {errorVote && <ErrorNotification>{errorVote}</ErrorNotification>}
      {dataVote && (
        <SuccessNotification>{dataVote.message}</SuccessNotification>
      )}
      {recordData && (
        <>
          <div className={RecordsBookStyles.detailsContainer}>
            <h2 className={RecordsBookStyles.title}>
              Título:{' '}
              <span className={RecordsBookStyles.titleNormal}>
                {recordData.titulo}
              </span>
            </h2>
            <h2 className={RecordsBookStyles.descriptionTitle}>
              Download da Ata:
            </h2>
            <PDFButton richText={recordData.descricao} />
          </div>
          {hasVoted ? (
            <h2 className={RecordsBookStyles.votedMessage}>
              <VotingIcon className={RecordsBookStyles.votingIcon} /> Você Já
              Votou Nessa Ata
            </h2>
          ) : (
            <form onSubmit={handleSubmitVote}>
              <h2 className={RecordsBookStyles.voteFormTitle}>
                Essa Ata Deve Ser Aprovada?:
              </h2>
              <SelectInput
                name="voto"
                label="Voto"
                options={['Sim', 'Não']}
                required
                {...vote}
              />
              <TextArea
                label="Comentário"
                name="comentario"
                placeholder="algum comentário sobre a ata?"
                maxLength={128}
                {...comment}
              />
              <FormButton disabled={loadingVote}>
                {loadingVote ? 'Votando...' : 'Votar'}
              </FormButton>
            </form>
          )}
        </>
      )}
    </section>
  );
};

export default VoteRecord;
