import { Link } from 'react-router-dom';

import useAxios from '../../hooks/useAxios';
import useForm from '../../hooks/useForm';

import { RESET_PASSWORD } from '../../Api';

import Input from '../../components/Forms/Input';
import FormButton from '../../components/Buttons/FormButton';
import PageTitle from '../../utils/PageTitle/PageTitle';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';
import SuccessNotification from '../../utils/SuccessNotification/SuccessNotification';

import { ReactComponent as Logo } from '../../assets/logo.svg';
import { ReactComponent as TriangleDarkIcon } from '../../assets/triangle-dark.svg';

import LoginStyles from './LoginStyles';

const ResetPassword = () => {
  const { data, error, loading, execute } = useAxios(RESET_PASSWORD);

  const cim = useForm('number');
  const email = useForm('email');

  const handleSubmit = (event) => {
    event.preventDefault();
    execute({ cim: cim.value, email: email.value });
  };

  return (
    <section>
      <div className={LoginStyles.logoContainer}>
        <Logo />
      </div>
      <PageTitle>Redefinir Senha</PageTitle>
      <form onSubmit={handleSubmit}>
        <Input
          label="CIM (Cadastro)"
          type="text"
          placeholder="insira seu cim"
          name="cim"
          required
          {...cim}
        />
        <Input
          label="Email"
          type="email"
          placeholder="insira seu email"
          name="email"
          required
          {...email}
        />
        <FormButton disabled={loading}>
          {loading ? 'Carregando' : 'Enviar'}
        </FormButton>
      </form>
      <Link to="/login" className={LoginStyles.enter}>
        <TriangleDarkIcon />
        Entrar como Membro
      </Link>
      {data && <SuccessNotification>{data.message}</SuccessNotification>}
      {error && <ErrorNotification>{error}</ErrorNotification>}
    </section>
  );
};

export default ResetPassword;
