import { useState } from 'react';

const types = {
  email: {
    regex:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: 'Preencha um email válido',
  },
  password: {
    regex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/,
    message:
      'A senha precisa ter pelo menos 1 caractere maiúsculo, 1 minúsculo e 1 número. Com no mínimo 8 caracteres.',
  },
  number: {
    regex: /^\d+$/,
    message: 'Utilize apenas números.',
  },
  cpf: {
    regex: /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/,
    message: 'Preencha um CPF válido 000.000.000-00.',
  },
  cep: {
    regex: /^\d{5}-\d{3}$/,
    message: 'Preencha um CEP válido no formato 00000-000.',
  },
  telephone: {
    regex: /^\(\d{2}\) \d{4,5}-\d{4}$/,
    message:
      'Preencha um telefone válido no formato (00) 0000-0000 ou (00) 00000-0000',
  },
};

const formatCPF = (value) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})$/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

const formatTelephone = (value) => {
  const cleaned = value.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{2})(\d{4,5})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return value;
};

const formatCEP = (value) => {
  return value
    .replace(/\D/g, '') // Remove non-digits
    .replace(/^(\d{5})(\d)/, '$1-$2'); // Insert dash after fifth digit
};

const useForm = (type) => {
  const [value, setValue] = useState(type === 'array' ? [] : '');
  const [error, setError] = useState(null);

  const validate = (valueToValidate) => {
    if (type === false) return true;

    if (type === 'array') {
      if (!valueToValidate || valueToValidate.length === 0) {
        setError('Selecione pelo menos uma opção.');
        return false;
      } else {
        setError(null);
        return true;
      }
    }

    if (valueToValidate.length === 0) {
      setError('Preencha um valor.');
      return false;
    } else if (types[type] && !types[type].regex.test(valueToValidate)) {
      setError(types[type].message);
      return false;
    } else {
      setError(null);
      return true;
    }
  };

  const onChange = (event) => {
    let newValue = event.target.value;
    if (type === 'array') {
      let updatedValue;
      if (value.includes(newValue)) {
        updatedValue = value.filter((item) => item !== newValue);
      } else {
        updatedValue = [...value, newValue];
      }
      setValue(updatedValue);
      validate(updatedValue);
    } else {
      if (type === 'cpf') {
        newValue = formatCPF(newValue);
      } else if (type === 'telephone') {
        newValue = formatTelephone(newValue);
      } else if (type === 'cep') {
        newValue = formatCEP(newValue);
      }
      if (error) validate(newValue);
      setValue(newValue);
    }
  };

  return {
    value,
    setValue,
    onChange,
    error,
    validate: () => validate(value),
    onBlur: () => validate(value),
  };
};

export default useForm;
