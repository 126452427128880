import { useState } from 'react';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { ReactComponent as FinancialIcon } from '../../assets/financial-menu.svg';
import { ReactComponent as ChatIcon } from '../../assets/chat-menu.svg';
import { ReactComponent as MembersIcon } from '../../assets/members-menu.svg';
import { ReactComponent as CalendarIcon } from '../../assets/calendar-dark.svg';
import { ReactComponent as RecordBookIcon } from '../../assets/book-menu.svg';
import { ReactComponent as VotingIcon } from '../../assets/votes-dark.svg';
import { ReactComponent as PresencesIcon } from '../../assets/presences-dark.svg';
import { ReactComponent as PermissionsIcon } from '../../assets/permissions-dark.svg';
import { ReactComponent as MenuIcon } from '../../assets/menu.svg';
import { ReactComponent as LogoutIcon } from '../../assets/logoff.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/auth/authSlice';

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const user = useSelector((state) => state.auth.user);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <>
      <div className="pt-32"></div>
      <div className="bg-white fixed inset-x-0 bottom-0 left-1/2 transform -translate-x-1/2 px-5 shadow-xl rounded-t-md w-96">
        {isOpen && (
          <div className="p-4 rounded-t-md">
            <ul className="grid grid-cols-4 gap-4 place-items-center">
              <li
                onClick={() => navigate('/financeiro')}
                className="my-2 cursor-pointer"
              >
                <FinancialIcon className="w-6 h-6" />
              </li>
              <li
                onClick={() => navigate('/chat')}
                className="my-2 cursor-pointer"
              >
                <ChatIcon className="w-6 h-6" />
              </li>
              <li
                onClick={() => navigate('/membros')}
                className="my-2 cursor-pointer"
              >
                <MembersIcon className="w-6 h-6" />
              </li>
              <li
                onClick={() => navigate('/eventos')}
                className="my-2 cursor-pointer"
              >
                <CalendarIcon className="w-6 h-6" />
              </li>
              <li
                onClick={() => navigate('/livro-de-atas')}
                className="my-2 cursor-pointer"
              >
                <RecordBookIcon className="w-6 h-6" />
              </li>
              <li
                onClick={() => navigate('/votacao')}
                className="my-2 cursor-pointer"
              >
                <VotingIcon className="w-6 h-6" />
              </li>
              <li
                onClick={() => navigate('/presencas')}
                className="my-2 cursor-pointer"
              >
                <PresencesIcon className="w-6 h-6" />
              </li>
              <li
                onClick={() => navigate('/permissoes')}
                className="my-2 cursor-pointer"
              >
                <PermissionsIcon className="w-6 h-6" />
              </li>
            </ul>
            <p
              onClick={() => navigate(`/membros/${user.cim}`)}
              className="my-2 text-blue-500 underline font-bold cursor-pointer"
            >
              Meu Perfil
            </p>
          </div>
        )}
        <nav className="rounded-t-md">
          <div className="flex justify-between items-center">
            <div className="logo">
              <Logo
                className="w-16 cursor-pointer"
                onClick={() => navigate('/')}
              />
            </div>
            <div className="flex flex-row gap-5">
              <button onClick={toggleMenu} className="menu-button">
                <MenuIcon className="w-8 h-8" />
              </button>
              <button onClick={handleLogout} className="menu-button">
                <LogoutIcon className="w-8 h-8" />
              </button>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Menu;
