import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useForm from '../../hooks/useForm';
import useAxios from '../../hooks/useAxios';

import { GET_ROLES, EDIT_ROLE, DELETE_ROLE } from '../../Api';

import BackLink from '../../utils/BackLink/BackLink';
import PageTitle from '../../utils/PageTitle/PageTitle';
import Menu from '../../components/Menu/Menu';
import Input from '../../components/Forms/Input';
import MultipleSelectInput from '../../components/Forms/MultipleSelectInput';
import FormButton from '../../components/Buttons/FormButton';
import SuccessNotification from '../../utils/SuccessNotification/SuccessNotification';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';
import Loader from '../../utils/Loader/Loader';
import WarningModal from '../../utils/WarningModal/WarningModal';
import RedButton from '../../components/Buttons/RedButton';

import GlobalStyles from '../../GlobalStyles';
import PermissionsStyles from './PermissionsStyles';

const RoleEdit = () => {
  const token = useSelector((state) => state.auth.token);
  const { roleId } = useParams();

  const title = useForm();
  const memberPermissions = useForm('array');
  const eventPermissions = useForm('array');
  const bookPermissions = useForm('array');
  const votingPermissions = useForm('array');
  const chatPermissions = useForm('array');
  const financePermissions = useForm('array');
  const otherPermissions = useForm('array');

  const {
    data: dataGet,
    error: errorGet,
    loading: loadingGet,
    execute: executeGet,
  } = useAxios(GET_ROLES);

  const {
    data: dataEdit,
    error: errorEdit,
    loading: loadingEdit,
    execute: executeEdit,
  } = useAxios(EDIT_ROLE);

  useEffect(() => {
    executeGet(token, `_id=${roleId}`);
  }, [token, roleId, executeGet]);

  const roleData = dataGet?.length > 0 ? dataGet[0] : null;

  useEffect(() => {
    if (dataGet && dataGet.length > 0) {
      title.setValue(roleData.cargo);

      memberPermissions.setValue(
        roleData.permissoes.filter((p) => p.includes('MEMBRO')),
      );

      eventPermissions.setValue(
        roleData.permissoes.filter((p) => p.includes('EVENTO')),
      );

      bookPermissions.setValue(
        roleData.permissoes.filter((p) => p.includes('ATA')),
      );

      votingPermissions.setValue(
        roleData.permissoes.filter((p) => p.includes('VOTAÇÃO')),
      );

      chatPermissions.setValue(
        roleData.permissoes.filter((p) => p.includes('GRUPO')),
      );
    }
  }, [dataGet]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const permissions = [
      ...memberPermissions.value,
      ...eventPermissions.value,
      ...bookPermissions.value,
      ...votingPermissions.value,
      ...chatPermissions.value,
      ...financePermissions.value,
    ];
    await executeEdit(token, roleId, {
      cargo: title.value,
      permissoes: permissions,
    });

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const {
    error: errorDelete,
    loading: loadingDelete,
    execute: executeDelete,
  } = useAxios(DELETE_ROLE);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleDelete = () => {
    executeDelete(token, roleId);
    setModalIsOpen(false);
    navigate(`/permissoes/consultar-cargos`);
  };

  const navigate = useNavigate();

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page={`/cargo/${roleId}`} />
      <PageTitle>Editar Cargo</PageTitle>
      {loadingGet && <Loader />}
      {errorGet && <ErrorNotification>{errorGet}</ErrorNotification>}

      {dataEdit && (
        <SuccessNotification>{dataEdit.message}</SuccessNotification>
      )}
      {errorEdit && <ErrorNotification>{errorEdit}</ErrorNotification>}
      {errorDelete && <ErrorNotification>{errorDelete}</ErrorNotification>}

      <form onSubmit={handleSubmit}>
        <Input
          label="Título do Cargo"
          placeholder="insira o título"
          type="text"
          name="titulo"
          required={true}
          {...title}
        />
        <h2 className="font-bold text-lg mb-3">
          Edite as permissões do cargo:{' '}
        </h2>
        <MultipleSelectInput
          name="membros-permissoes"
          label="Membros"
          options={['CRIAR MEMBRO', 'EDITAR MEMBRO', 'EXCLUIR MEMBRO']}
          {...memberPermissions}
        />
        <MultipleSelectInput
          name="chat-permissoes"
          label="Chat"
          options={['CRIAR GRUPO', 'EDITAR GRUPO', 'EXCLUIR GRUPO']}
          {...chatPermissions}
        />
        <MultipleSelectInput
          name="eventos-permissoes"
          label="Eventos"
          options={[
            'CRIAR EVENTO',
            'EDITAR EVENTO',
            'EXCLUIR EVENTO',
            'ABRIR/FECHAR EVENTO',
          ]}
          {...eventPermissions}
        />
        <MultipleSelectInput
          name="atas-permissoes"
          label="Livro de Atas"
          options={['CRIAR ATA', 'EDITAR ATA', 'EXCLUIR ATA']}
          {...bookPermissions}
        />
        <MultipleSelectInput
          name="votacao-permissoes"
          label="Votações"
          options={['CRIAR VOTAÇÃO', 'EDITAR VOTAÇÃO', 'EXCLUIR VOTAÇÃO']}
          {...votingPermissions}
        />
        <MultipleSelectInput
          name="financeiro-permissoes"
          label="Financeiro"
          options={['CRIAR FATURA']}
          {...financePermissions}
        />
        <div className={`${PermissionsStyles.buttonContainer}`}>
          <FormButton disabled={loadingEdit}>
            {loadingEdit ? 'Editando...' : 'Editar'}
          </FormButton>
          <RedButton onClick={handleOpenModal}>
            {loadingDelete ? 'Deletando...' : 'Deletar'}
          </RedButton>
        </div>
      </form>

      {modalIsOpen && (
        <WarningModal
          title="Deletar cargo"
          text="Deseja deletar permanentemente esse cargo?"
          actionName="Deletar"
          modalAction={handleDelete}
          closeModal={handleCloseModal}
        />
      )}
      <Menu />
    </section>
  );
};

export default RoleEdit;
