import { useParams } from 'react-router-dom/dist';
import useAxios from '../../hooks/useAxios';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { GET_VOTINGS, GET_MEMBERS_VOTES } from '../../Api';

import BackLink from '../../utils/BackLink/BackLink';
import PageTitle from '../../utils/PageTitle/PageTitle';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';
import Loader from '../../utils/Loader/Loader';
import VotingList from '../../components/Lists/VotingList';
import Menu from '../../components/Menu/Menu';

import { ReactComponent as VotingIcon } from '../../assets/votes-dark.svg';
import { ReactComponent as LockIcon } from '../../assets/lock.svg';

import GlobalStyles from '../../GlobalStyles';
import VotingStyles from './VotingStyles';

const VotingResults = () => {
  const { votingId } = useParams();
  const token = useSelector((state) => state.auth.token);

  const {
    data: dataGet,
    error: errorGet,
    loading: loadingGet,
    execute: executeGet,
  } = useAxios(GET_VOTINGS);

  const {
    data: dataVote,
    error: errorVote,
    loading: loadingVote,
    execute: executeVote,
  } = useAxios(GET_MEMBERS_VOTES);

  useEffect(() => {
    executeGet(token, `_id=${votingId}`);
    executeVote(token, votingId);

    console.log(dataVote);
  }, [token, executeGet, votingId, executeVote]);

  const votingData = dataGet?.length > 0 ? dataGet[0] : null;

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page={`/votacao/consultar-votacao/${votingId}`} />
      <PageTitle>Resultados</PageTitle>

      {loadingGet && <Loader />}
      {errorGet && <ErrorNotification>{errorGet}</ErrorNotification>}

      {votingData && dataVote && (
        <div className={VotingStyles.detailsContainer}>
          <h2 className={VotingStyles.title}>
            Título:{' '}
            <span className={VotingStyles.titleNormal}>
              {votingData.titulo}
            </span>
          </h2>
          <h2 className={VotingStyles.descriptionTitle}>
            Descrição da Votação:
          </h2>
          <p className={VotingStyles.description}>{votingData.descricao}</p>

          <h2 className={VotingStyles.voteFormTitle}>
            <VotingIcon className={VotingStyles.votingIcon} /> Votos
          </h2>
          <ul className={VotingStyles.listContainer}>
            <li className={VotingStyles.listItemCenter}>
              <h2 className={VotingStyles.listHeader}>Sim</h2>
              <p className={VotingStyles.voteCountGreen}>
                {dataVote.sim.length}
              </p>
            </li>
            <li className={VotingStyles.listItemCenter}>
              <h2 className={VotingStyles.listHeader}>Abstenções</h2>
              <p className={VotingStyles.voteCountYellow}>
                {dataVote.abst.length}
              </p>
            </li>
            <li className={VotingStyles.listItemCenter}>
              <h2 className={VotingStyles.listHeader}>Não</h2>
              <p className={VotingStyles.voteCountRed}>{dataVote.nao.length}</p>
            </li>
          </ul>
        </div>
      )}

      {dataVote && votingData?.tipoVotacao === 'Pública' && (
        <VotingList dataVote={dataVote} />
      )}

      {votingData?.tipoVotacao === 'Privada' && (
        <div className={VotingStyles.privateVoteMessage}>
          <LockIcon />
          <p className={VotingStyles.privateVoteText}>
            Essa é uma votação privada. Não é possível ver quem votou.
          </p>
        </div>
      )}

      {errorVote && <ErrorNotification>{errorVote}</ErrorNotification>}
      <Menu />
    </section>
  );
};

export default VotingResults;
