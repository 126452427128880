const CalendarStyles = {
  nextEvents: 'capitalize text-2xl font-medium mt-5 mb-4',
  detailsContainer: 'w-72',
  title: 'text-xl font-bold mb-3',
  titleNormal: 'font-normal text-gray-600',
  descriptionTitle: 'text-lg font-bold',
  description: 'mb-5 text-gray-600',
  buttonContainer: 'flex flex-row justify-between gap-5 items-center',
  listItem: 'mb-4',
  listTitle: 'flex flex-row gap-1 items-center text-xl font-bold',
  listIcon: 'w-5 h-5',
  listText: 'text-lg text-gray-600',
  tagContainer: 'w-72 flex flex-row gap-2 flex-wrap mt-3',
  tag: 'flex flex-row items-center  justify-center px-2 py-1 bg-blue-500 text-white rounded-full',
  qrCodeTitle: 'text-lg font-bold mt-6 text-gray-600',
  qrCode: 'cursor-pointer',
  copyPixLink: 'cursor-pointer text-blue-500 underline',
  headerContainer: 'flex flex-row items-center text-center gap-3',
  headerTitle: 'text-2xl font-bold mb-2',
  editIcon: 'cursor-pointer mb-3 display-block w-4',
};

export default CalendarStyles;
