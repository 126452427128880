import React from 'react';
import BackLink from '../../utils/BackLink/BackLink';
import PageTitle from '../../utils/PageTitle/PageTitle';
import Menu from '../../components/Menu/Menu';
import { ReactComponent as CameraIcon } from '../../assets/camera.svg';
import GlobalStyles from '../../GlobalStyles';
import Button from '../../components/Buttons/Button';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import GuestMenu from '../../components/Menu/GuestMenu';

const InformPresence = () => {
  const getFormattedDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);

  function handleClick() {
    navigate(`/eventos/${getFormattedDate()}`);
  }

  const menu = user.cargo === 'Visitante' ? <GuestMenu /> : <Menu />;

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink />
      <PageTitle>Informar Presença</PageTitle>
      <div className="flex flex-col justify-center items-center text-center mt-40">
        <CameraIcon />
        <h2 className="text-gray-600">
          Para informar presença, abra sua câmera e aponte para o QR-Code da
          Reunião
        </h2>
      </div>
      {user.cargo !== 'Visitante' && user.cargo !== 'visitante' && (
        <Button onClick={handleClick}>Eventos</Button>
      )}
      {menu}
    </section>
  );
};

export default InformPresence;
