import PageTitle from '../../utils/PageTitle/PageTitle';
import BackLink from '../../utils/BackLink/BackLink';
import Menu from '../../components/Menu/Menu';
import PageButton from '../../components/Buttons/PageButton';

import GlobalStyles from '../../GlobalStyles';
import PresencesStyles from './PresencesStyles';
import { useSelector } from 'react-redux';

const Presences = () => {
  const pagesButtons = [
    {
      text: 'Informar Presença',
      page: '/presencas/informar-presenca',
      disabled: false,
    },
    {
      text: 'Consultar Frequência',
      page: '/frequencia/consultar',
      disabled: false,
    },
  ];

  const user = useSelector((state) => state.auth.user);

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page="/" />
      <PageTitle>Presenças</PageTitle>
      <div className={PresencesStyles.buttonsGrid}>
        {pagesButtons.map((button, index) => (
          <PageButton key={index} page={button.page} disabled={button.disabled}>
            {button.text}
          </PageButton>
        ))}
        {(user.cargo === 'Escrivão' || user.cargo === 'Venerável') && (
          <PageButton page="/presencas/buscar-eventos" disabled={false}>
            Abrir / Fechar Reunião
          </PageButton>
        )}
      </div>
      <Menu />
    </section>
  );
};

export default Presences;
