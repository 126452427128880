import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useAxios from '../../hooks/useAxios';
import { useEffect } from 'react';
import moment from 'moment';

import { GET_EVENTS } from '../../Api';

import BackLink from '../../utils/BackLink/BackLink';
import PageTitle from '../../utils/PageTitle/PageTitle';
import Loader from '../../utils/Loader/Loader';
import Button from '../../components/Buttons/Button';
import Menu from '../../components/Menu/Menu';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';

import GlobalStyles from '../../GlobalStyles';
import CalendarStyles from './CalendarStyles';

import { ReactComponent as CalendarDarkIcon } from '../../assets/calendar-dark.svg';
import { ReactComponent as AddressIcon } from '../../assets/address.svg';
import { ReactComponent as PencilIcon } from '../../assets/pencil.svg';

const EventSingle = () => {
  const { eventId } = useParams();
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();

  const { data, error, loading, execute } = useAxios(GET_EVENTS);

  const user = useSelector((state) => state.auth.user);

  const eventData = data?.length > 0 ? data[0] : null;

  useEffect(() => {
    execute(token, `_id=${eventId}`);
  }, [token, eventId, execute]);

  const formatDate = (date) =>
    moment.utc(date).local('pt-br').format('DD/MM/YYYY - HH:mm');

  const message = `
    LOJA AREÓPAGO DE BRASÍLIA

    *Evento:* ${eventData?.titulo}
    *Local:* ${eventData?.local}
    *Data e Hora:* ${formatDate(eventData?.data)}

    https://areopago.na.tec.br/evento/${eventId}
`;

  const encodedMessage = encodeURIComponent(message.trim());

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page="/eventos" />
      <PageTitle>Evento</PageTitle>
      {loading && <Loader />}
      {error && <ErrorNotification>{error}</ErrorNotification>}
      {eventData && (
        <div className={CalendarStyles.detailsContainer}>
          <div className={CalendarStyles.headerContainer}>
            <h2 className={CalendarStyles.headerTitle}>Informações</h2>

            {user.cargo !== 'Membro' && (
              <PencilIcon
                className={CalendarStyles.editIcon}
                onClick={() => navigate(`/evento/editar/${eventId}`)}
              />
            )}
          </div>
          <h2 className={CalendarStyles.title}>
            Título:{' '}
            <span className={CalendarStyles.titleNormal}>
              {eventData.titulo}
            </span>
          </h2>
          <h2 className={CalendarStyles.descriptionTitle}>
            Descrição do Evento:
          </h2>
          <p className={CalendarStyles.description}>{eventData.descricao}</p>

          <ul>
            <li className={CalendarStyles.listItem}>
              <h2 className={CalendarStyles.listTitle}>
                <CalendarDarkIcon className={CalendarStyles.listIcon} /> Data do
                Evento
              </h2>
              <p className={CalendarStyles.listText}>
                {formatDate(eventData.data)}
              </p>
            </li>
            <li className={CalendarStyles.listItem}>
              <h2 className={CalendarStyles.listTitle}>
                <AddressIcon className={CalendarStyles.listIcon} /> Local do
                Evento
              </h2>
              <p className={CalendarStyles.listText}>{eventData.local}</p>
            </li>
            <li className={CalendarStyles.listItem}>
              <a
                href={`whatsapp://send?text=${encodedMessage}`}
                className="text-blue-500 underline font-bold text-lg"
              >
                Compartilhar Evento {'>'}
              </a>
            </li>
          </ul>
          <h2 className={CalendarStyles.descriptionTitle}>Tags:</h2>
          <div className={CalendarStyles.tagContainer}>
            {eventData.tags.map((tag, index) => (
              <span key={index} className={CalendarStyles.tag}>
                {tag}
              </span>
            ))}
          </div>
        </div>
      )}
      <div className={CalendarStyles.buttonContainer}>
        <Button onClick={() => navigate(`/eventos/qrcode/${eventId}`)}>
          QR-Code
        </Button>
        {(user.cargo === 'Tesoureiro' || user.cargo === 'Venerável') && (
          <Button onClick={() => navigate(`/eventos/tronco/${eventId}`)}>
            Tronco
          </Button>
        )}
      </div>
      <Menu />
    </section>
  );
};

export default EventSingle;
