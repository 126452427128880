import { ReactComponent as CloseIconWhite } from '../../assets/close-white.svg';

import TagsInputStyles from './TagsInputStyles';

const TagsInput = ({
  label,
  name,
  placeholder,
  onBlur,
  required,
  value,
  setValue,
  error,
}) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const newTags = event.target.value
        .split(',')
        .map((tag) => tag.trim())
        .filter((tag) => tag);
      const updatedTags = [
        ...value,
        ...newTags.filter((tag) => !value.includes(tag)),
      ];
      setValue(updatedTags);
      event.target.value = '';
    }
  };

  const removeTag = (tagToRemove) => {
    setValue(value.filter((tag) => tag !== tagToRemove));
  };

  return (
    <div className={TagsInputStyles.wrapper}>
      <label htmlFor={name} className={TagsInputStyles.label}>
        {label}
      </label>
      <input
        id={name}
        name={name}
        placeholder={placeholder}
        type="text"
        onBlur={onBlur}
        className={TagsInputStyles.input}
        onKeyDown={handleKeyDown}
        required={required}
      />
      {error && <p className={TagsInputStyles.error}>{error}</p>}
      <div className={TagsInputStyles.tagContainer}>
        {value.map((tag, index) => (
          <span key={index} className={TagsInputStyles.tag}>
            <strong
              onClick={() => removeTag(tag)}
              className={TagsInputStyles.tagRemove}
            >
              <CloseIconWhite className={TagsInputStyles.closeIcon} />
            </strong>
            {tag}
          </span>
        ))}
      </div>
    </div>
  );
};

export default TagsInput;
