import PageTitle from '../../utils/PageTitle/PageTitle';
import BackLink from '../../utils/BackLink/BackLink';
import Menu from '../../components/Menu/Menu';

import Input from '../../components/Forms/Input';
import FormButton from '../../components/Buttons/FormButton';
import GlobalStyles from '../../GlobalStyles';
import useForm from '../../hooks/useForm';
import useAxios from '../../hooks/useAxios';
import { CREATE_SINGLE_INVOICE, GET_MEMBERS } from '../../Api';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import Loader from '../../utils/Loader/Loader';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';
import SuccessNotification from '../../utils/SuccessNotification/SuccessNotification';
import MemberSelect from '../../components/Forms/MemberSelect';

const InvoiceSingle = () => {
  const token = useSelector((state) => state.auth.token);
  const title = useForm();
  const members = useForm('array');
  const invoiceValue = useForm('number');

  const {
    data: membersData,
    error: membersError,
    loading: membersLoading,
    execute: fetchMembers,
  } = useAxios(GET_MEMBERS);

  const { data, error, loading, execute } = useAxios(CREATE_SINGLE_INVOICE);

  useEffect(() => {
    fetchMembers(token, ``);
  }, [token, fetchMembers]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    await execute(token, {
      titulo: title.value,
      membro: members.value[0],
      valor: invoiceValue.value,
    });

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page="/financeiro/faturas" />
      <PageTitle>Cadastrar Fatura</PageTitle>
      {membersLoading && <Loader />}
      {membersError && <ErrorNotification>{membersError}</ErrorNotification>}
      {data && <SuccessNotification>{data.message}</SuccessNotification>}
      {error && <ErrorNotification>{error}</ErrorNotification>}
      <form onSubmit={handleSubmit}>
        <Input
          type="text"
          label="Título da fatura"
          name="titulo"
          placeholder="insira o título da fatura"
          {...title}
        />
        <Input
          type="text"
          label="R$ Valor da fatura"
          name="valor"
          placeholder="insira o valor da fatura"
          {...invoiceValue}
        />
        <MemberSelect
          label="Membro Atribuído"
          name="membros"
          options={membersData || []}
          value={members.value}
          onChange={(newValue) => members.setValue(newValue)}
          required={true}
          oneMember={true}
        />
        <FormButton disabled={loading}>
          {loading ? 'Criando...' : 'Criar Fatura'}
        </FormButton>
      </form>

      <Menu />
    </section>
  );
};

export default InvoiceSingle;
