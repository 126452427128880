import { useEffect, useState } from 'react';
import useAxios from '../../hooks/useAxios';
import useForm from '../../hooks/useForm';
import { useSelector } from 'react-redux';

import { GET_MEMBERS, CREATE_GROUP } from '../../Api';

import BackLink from '../../utils/BackLink/BackLink';
import PageTitle from '../../utils/PageTitle/PageTitle';
import Input from '../../components/Forms/Input';
import TextArea from '../../components/Forms/TextArea';
import FileInput from '../../components/Forms/FileInput';
import FormButton from '../../components/Buttons/FormButton';
import Loader from '../../utils/Loader/Loader';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';
import SuccessNotification from '../../utils/SuccessNotification/SuccessNotification';

import { ReactComponent as DefaultProfile } from '../../assets/group.svg';

import GlobalStyles from '../../GlobalStyles';
import ChatStyles from './ChatStyles';
import Menu from '../../components/Menu/Menu';
import MemberSelect from '../../components/Forms/MemberSelect';

const GroupCreate = () => {
  const token = useSelector((state) => state.auth.token);
  const { cim } = useSelector((state) => state.auth.user);
  const title = useForm();
  const description = useForm();
  const members = useForm('array');
  const [photo, setPhoto] = useState('');

  const { data, error, loading, execute } = useAxios(CREATE_GROUP);

  const {
    data: membersData,
    error: membersError,
    loading: membersLoading,
    execute: fetchMembers,
  } = useAxios(GET_MEMBERS);

  useEffect(() => {
    fetchMembers(token, ``);
    if (members.value.length === 0) {
      members.setValue([cim]);
    }
  }, [token, fetchMembers]);

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    setPhoto(base64);
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      if (file) {
        fileReader.readAsDataURL(file);
      }

      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await execute(token, {
      title: title.value,
      description: description.value,
      members: members.value,
      photo: photo,
    });

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page="/chat/grupos" />
      <PageTitle>Criar Grupo</PageTitle>
      {membersLoading && <Loader />}
      {membersError && <ErrorNotification>{membersError}</ErrorNotification>}
      {data && (
        <SuccessNotification>Grupo Criado com Sucesso</SuccessNotification>
      )}
      {error && <ErrorNotification>{error}</ErrorNotification>}
      <form onSubmit={handleSubmit}>
        {photo ? (
          <img
            className={`${ChatStyles.memberImage}`}
            src={photo || DefaultProfile}
            alt="Foto do Grupo"
          />
        ) : (
          <DefaultProfile className={`${ChatStyles.memberImage}`} />
        )}
        <FileInput
          label="Foto de Perfil"
          name="foto"
          onChange={handleFileUpload}
          accept="image/jpeg, image/png, image/jpg"
        />
        <Input
          type="text"
          label="Título do Grupo"
          name="titulo"
          placeholder="insira o título do grupo"
          {...title}
        />
        <TextArea
          label="Descrição do Grupo"
          name="descricao"
          placeholder="insira a descrição do grupo"
          maxLength={160}
          {...description}
        />
        <MemberSelect
          label="Membros"
          name="membros"
          options={membersData || []}
          value={members.value}
          onChange={(newValue) => members.setValue(newValue)}
          required={true}
        />
        <FormButton disabled={loading}>
          {loading ? 'Criando...' : 'Criar'}
        </FormButton>
      </form>
      <Menu />
    </section>
  );
};

export default GroupCreate;
