import RedButtonStyles from './RedButtonStyles';
const RedButton = ({ children, onClick, disabled }) => {
  return (
    <span
      onClick={onClick}
      className={RedButtonStyles.redButton}
      disabled={disabled}
    >
      {children}
    </span>
  );
};

export default RedButton;
