import SelectInputStyles from './SelectInputStyles';

const SelectInput = ({ name, label, value, onChange, required, options }) => {
  return (
    <div className={SelectInputStyles.container}>
      <label htmlFor={name} className={SelectInputStyles.label}>
        {label}
      </label>
      <select
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        className={SelectInputStyles.select}
      >
        <option value="" disabled>
          Selecionar
        </option>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectInput;
