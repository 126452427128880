const FinancesStyles = {
  buttonsGrid: 'grid grid-cols-1 gap-5',
  donateHeading: 'text-lg font-bold mb-8',
  qrCodeContainer: 'flex flex-col align-center justify-center mb-8',
  qrCodeImage: 'w-72',
  qrCodeText: 'font-bold max-w-xs text-gray-600',
  copyPixLink: 'cursor-pointer text-blue-500 underline',
  donateButton:
    'cursor-pointer flex items-center justify-center rounded w-32 h-10 p-3 text-center bg-gradient-to-t to-blue-500 from-blue-700 text-white text-s font-semibold tracking-wide',
  membersGrid: 'grid grid-cols-1 gap-3 pb-10',
};

export default FinancesStyles;
