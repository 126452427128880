import { Suspense, lazy, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import io from 'socket.io-client';
import useAxios from '../../hooks/useAxios';

import { SERVER_URL, OPEN_CHAT, GET_MESSAGES, GET_MEMBERS } from '../../Api';

import BackLink from '../../utils/BackLink/BackLink';
import ChatInput from '../../components/Forms/ChatInput';
import Loader from '../../utils/Loader/Loader';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';

import defaultProfilePic from '../../assets/user-profile.svg';

import GlobalStyles from '../../GlobalStyles';
import ChatStyles from './ChatStyles';

const ChatBubble = lazy(() => import('../../components/Chat/ChatBubble'));

const Chat = () => {
  const { token } = useSelector((state) => state.auth).token;
  const { cim, nome } = useSelector((state) => state.auth).user;
  const { chatCim } = useParams();

  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [chatId, setChatId] = useState(null);
  const [socket, setSocket] = useState(null);

  const { execute: openChat } = useAxios(OPEN_CHAT);
  const { error: errorMessages, execute: fetchMessages } =
    useAxios(GET_MESSAGES);

  const [hasFetchedMessages, setHasFetchedMessages] = useState(false);

  useEffect(() => {
    const newSocket = io(SERVER_URL, { auth: { token } });
    setSocket(newSocket);

    const requestBody = {
      memberId1: cim,
      memberId2: chatCim,
    };

    newSocket.on('connect', () => {
      openChat(token, requestBody)
        .then((response) => {
          const obtainedChatId = response.data;
          setChatId(obtainedChatId);
          newSocket.emit('joinRoom', { userId: cim, chatId: obtainedChatId });

          if (!hasFetchedMessages) {
            fetchMessages(token, obtainedChatId)
              .then((response) => {
                setMessages(response.data);
                setHasFetchedMessages(true);
              })
              .catch(console.error);
          }
        })
        .catch(console.error);
    });

    newSocket.on('message', (incomingMessage) => {
      setMessages((prevMessages) => [...prevMessages, incomingMessage]);
    });

    return () => {
      newSocket.off('connect');
      newSocket.off('message');
      newSocket.disconnect();
    };
  }, [token, cim, chatCim, openChat, fetchMessages]);

  const handleSendMessage = () => {
    if (message.trim() && socket && chatId) {
      socket.emit('sendMessage', { chatId, message, userId: nome });
      setMessage('');
    }
  };

  const sendMessage = (e) => {
    e.preventDefault();
    handleSendMessage();
  };

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const { data, error, loading, execute } = useAxios(GET_MEMBERS);

  useEffect(() => {
    execute(token, `cim=${chatCim}`);
  }, [token, execute, chatCim]);

  const memberData = data?.length > 0 ? data[0] : null;

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page="/chat" />
      {loading && <Loader />}
      {error && <ErrorNotification>{error}</ErrorNotification>}
      {memberData && (
        <div className={ChatStyles.memberMainInfo}>
          <img
            className={ChatStyles.memberImage}
            src={memberData.foto ? memberData.foto : defaultProfilePic}
            alt={`Foto de ${memberData.nome || 'membro'}`}
          />
          <div>
            <h2 className="text-3xl mb-4 font-bold font-sans text-slate-950">
              {memberData.nome}
            </h2>
          </div>
        </div>
      )}

      <div className="max-h-[100vh] h-[70vh] overflow-y-auto overflow-x-hidden pr-1">
        <Suspense fallback={<Loader />}>
          {!errorMessages &&
            messages.map((msg) => (
              <ChatBubble
                key={msg.timeStamp}
                msg={msg}
                isOwnMessage={msg.sender === nome}
                nome={nome}
              />
            ))}
        </Suspense>
        <div ref={messagesEndRef} />
        {errorMessages && (
          <ErrorNotification>Erro ao carregar mensagens</ErrorNotification>
        )}
      </div>
      <form
        onSubmit={sendMessage}
        className="flex justify-between items-center p-2 bg-white border-t border-gray-200"
      >
        <ChatInput
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Digite uma mensagem..."
          onIconClick={handleSendMessage}
          required
        />
      </form>
    </section>
  );
};

export default Chat;
