import { ReactComponent as SendIcon } from '../../assets/send.svg';

import ChatInputStyles from './ChatInputStyles';

const ChatInput = ({ value, onChange, placeholder, onIconClick }) => {
  const handleIconClick = () => {
    if (value.trim()) {
      onIconClick();
    }
  };

  return (
    <div className={ChatInputStyles.wrapper}>
      <input
        type="text"
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        className={ChatInputStyles.input}
        onKeyDown={(e) => e.key === 'Enter' && handleIconClick()}
      />
      <div className={ChatInputStyles.icon} onClick={handleIconClick}>
        <SendIcon />
      </div>
    </div>
  );
};

export default ChatInput;
