import InputStyles from './InputStyles';

const Input = ({
  label,
  type,
  name,
  placeholder,
  value,
  onChange,
  error,
  onBlur,
  required,
}) => {
  return (
    <div className={InputStyles.wrapper}>
      <label htmlFor={name} className={InputStyles.label}>
        {label}
      </label>
      <input
        id={name}
        name={name}
        placeholder={placeholder}
        type={type}
        className={InputStyles.input}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        required={required}
      />
      {error && <p className={InputStyles.error}>{error}</p>}
    </div>
  );
};

export default Input;
