import { useSelector } from 'react-redux';
import useForm from '../../hooks/useForm';
import useAxios from '../../hooks/useAxios';

import { CREATE_ROLE } from '../../Api';

import BackLink from '../../utils/BackLink/BackLink';
import PageTitle from '../../utils/PageTitle/PageTitle';
import Menu from '../../components/Menu/Menu';
import Input from '../../components/Forms/Input';
import MultipleSelectInput from '../../components/Forms/MultipleSelectInput';
import FormButton from '../../components/Buttons/FormButton';
import SuccessNotification from '../../utils/SuccessNotification/SuccessNotification';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';

import GlobalStyles from '../../GlobalStyles';

const CreateRole = () => {
  const token = useSelector((state) => state.auth.token);

  const title = useForm();
  const memberPermissions = useForm('array');
  const eventPermissions = useForm('array');
  const bookPermissions = useForm('array');
  const votingPermissions = useForm('array');
  const chatPermissions = useForm('array');
  const financePermissions = useForm('array');

  const { data, error, loading, execute } = useAxios(CREATE_ROLE);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const permissions = [
      ...memberPermissions.value,
      ...eventPermissions.value,
      ...bookPermissions.value,
      ...votingPermissions.value,
      ...chatPermissions.value,
      ...financePermissions.value,
    ];
    await execute(token, { cargo: title.value, permissoes: permissions });

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page="/permissoes" />
      <PageTitle>Criar Cargo</PageTitle>
      {data && <SuccessNotification>{data.message}</SuccessNotification>}
      {error && <ErrorNotification>{error}</ErrorNotification>}
      <form onSubmit={handleSubmit}>
        <Input
          label="Título do Cargo"
          placeholder="insira o título"
          type="text"
          name="titulo"
          required={true}
          {...title}
        />
        <h2 className="font-bold text-lg mb-3">
          Selecione as permissões do cargo:{' '}
        </h2>
        <MultipleSelectInput
          name="membros-permissoes"
          label="Membros"
          options={['CRIAR MEMBRO', 'EDITAR MEMBRO', 'EXCLUIR MEMBRO']}
          {...memberPermissions}
        />
        <MultipleSelectInput
          name="chat-permissoes"
          label="Chat"
          options={['CRIAR GRUPO', 'EDITAR GRUPO', 'EXCLUIR GRUPO']}
          {...chatPermissions}
        />
        <MultipleSelectInput
          name="eventos-permissoes"
          label="Eventos"
          options={[
            'CRIAR EVENTO',
            'EDITAR EVENTO',
            'EXCLUIR EVENTO',
            'ABRIR/FECHAR EVENTO',
          ]}
          {...eventPermissions}
        />
        <MultipleSelectInput
          name="atas-permissoes"
          label="Livro de Atas"
          options={['CRIAR ATA', 'EDITAR ATA', 'EXCLUIR ATA']}
          {...bookPermissions}
        />
        <MultipleSelectInput
          name="votacao-permissoes"
          label="Votações"
          options={['CRIAR VOTAÇÃO', 'EDITAR VOTAÇÃO', 'EXCLUIR VOTAÇÃO']}
          {...votingPermissions}
        />
        <MultipleSelectInput
          name="financeiro-permissoes"
          label="Financeiro"
          options={['CRIAR FATURA']}
          {...financePermissions}
        />
        <FormButton disabled={loading}>
          {loading ? 'Criando...' : 'Criar'}
        </FormButton>
      </form>
      <Menu />
    </section>
  );
};

export default CreateRole;
