import DateInputStyles from './DateInputStyles';

const DateInput = ({
  label,
  name,
  required,
  value,
  onChange,
  type = 'date',
}) => {
  return (
    <div className={DateInputStyles.container}>
      <label htmlFor={name} className={DateInputStyles.label}>
        {label}
      </label>
      <input
        type={type}
        onChange={onChange}
        name={name}
        value={value}
        required={required}
        className={DateInputStyles.input}
      />
    </div>
  );
};

export default DateInput;
