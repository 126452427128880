const TagsInputStyles = {
  wrapper: 'mb-4',
  label: 'text-s text-slate-950 capitalize',
  input:
    'bg-gray-100 rounded w-72 h-12 py-3 px-3 block text-base text-slate-950 placeholder:text-gray-600 focus:outline-2 outline-blue-500 cursor-text',
  error: 'text-xs text-red-500 font-bold w-72',
  tagContainer: 'w-72 flex flex-row gap-2 flex-wrap mt-3',
  tag: 'flex flex-row items-center  justify-center px-2 py-1 bg-blue-500 text-white rounded-full',
  tagRemove: 'inline-block mr-1 cursor-pointer font-xm',
  closeIcon: 'w-3 h-3 mb-[-3px]',
};

export default TagsInputStyles;
