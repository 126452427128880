const WarningModalStyles = {
  overlay: 'fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity',
  modalContainer: 'fixed inset-0 z-[100] w-screen overflow-y-auto',
  modalContent:
    'flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0',
  modalBox:
    'relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg',
  iconBox:
    'mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10',
  icon: 'h-6 w-6 text-red-600',
  contentBox: 'bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4',
  title: 'text-base font-semibold leading-6 text-gray-900',
  text: 'mt-2 text-sm text-gray-500',
  buttonBox: 'bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6',
  actionButton:
    'inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto',
  cancelButton:
    'mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto',
};

export default WarningModalStyles;
