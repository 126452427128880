import PageTitle from '../../utils/PageTitle/PageTitle';
import BackLink from '../../utils/BackLink/BackLink';
import Menu from '../../components/Menu/Menu';

import GlobalStyles from '../../GlobalStyles';
import useAxios from '../../hooks/useAxios';
import { GET_MEMBERS, GET_MY_INVOICES } from '../../Api';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import Loader from '../../utils/Loader/Loader';
import ErrorNotification from '../../utils/ErrorNotification/ErrorNotification';
import InvoiceCard from '../../components/Cards/InvoiceCard';
import { useParams } from 'react-router-dom';
import WarningNotification from '../../utils/Notification/WarningNotification';

function formatDate(dateString) {
  const [year, month, day] = dateString.split('-');
  return `${day}/${month}/${year.slice(-2)}`;
}

const MemberInvoice = () => {
  const token = useSelector((state) => state.auth.token);
  const { data, error, loading, execute } = useAxios(GET_MEMBERS);
  const {
    data: financeData,
    error: financeError,
    loading: financeLoading,
    execute: financeExecute,
    setError,
  } = useAxios(GET_MY_INVOICES);
  const { cim } = useParams();

  useEffect(() => {
    execute(token, `cim=${cim}`);
  }, [token, execute, cim]);

  const memberData = data?.length > 0 ? data[0] : null;
  const financeId = memberData?.finance_id;

  useEffect(() => {
    if (financeId) {
      // Garantir que execute somente se financeId estiver disponível
      financeExecute(token, financeId);
    }
  }, [token, financeId, financeExecute]);

  const sortedData = financeData
    ? [...financeData].sort((a, b) => {
        if (a.status === 'PENDING' && b.status !== 'PENDING') return -1;
        if (a.status !== 'PENDING' && b.status === 'PENDING') return 1;
        return 0;
      })
    : [];

  if (
    financeError &&
    financeError === 'Erro ao Buscar Fatura: Nenhuma Fatura Encontrada.'
  ) {
    setError('Nenhuma Fatura Encontrada.');
  }

  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page="/financeiro/faturas/todas" />
      <PageTitle>Faturas</PageTitle>
      {loading || financeLoading ? (
        <Loader />
      ) : (
        <>
          {error && <ErrorNotification>{error}</ErrorNotification>}
          {financeError && (
            <WarningNotification>{financeError}</WarningNotification>
          )}
          {!error &&
            !financeError &&
            sortedData?.length > 0 &&
            sortedData?.map((invoice, index) => (
              <InvoiceCard
                key={index}
                title={invoice.description}
                value={invoice.value}
                status={invoice.status}
                link={invoice.invoiceUrl}
                date={formatDate(invoice.dueDate)}
              />
            ))}
        </>
      )}
      <Menu />
    </section>
  );
};

export default MemberInvoice;
