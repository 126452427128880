import PageTitle from '../../utils/PageTitle/PageTitle';
import BackLink from '../../utils/BackLink/BackLink';
import PageButton from '../../components/Buttons/PageButton';
import Menu from '../../components/Menu/Menu';

import GlobalStyles from '../../GlobalStyles';
import FinancesStyles from './FinancesStyles';

const Invoices = () => {
  const pagesButtons = [
    {
      text: 'Cobranças Únicas',
      page: '/financeiro/faturas/cadastrar-unica',
      disabled: false,
    },
    {
      text: 'Cobranças Recorrentes',
      page: '/financeiro/faturas/cadastrar-recorrente',
      disabled: false,
    },
  ];
  return (
    <section className={GlobalStyles.componentContainer}>
      <BackLink page="/financeiro" />
      <PageTitle>Faturas</PageTitle>
      <div className={FinancesStyles.buttonsGrid}>
        {pagesButtons.map((button, index) => (
          <PageButton key={index} page={button.page} disabled={button.disabled}>
            {button.text}
          </PageButton>
        ))}
      </div>
      <Menu />
    </section>
  );
};

export default Invoices;
